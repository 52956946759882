import { Typography } from "@material-ui/core";
import { FormikSelect, FormikTextField } from "@components/forms/FormikControls";
import { Stack } from "@components";
import { useIsProjectTechnicalWriter } from "auth/permissions";

const LabelOptions = [
  {
    value: "automatic",
    key: "Automatic",
  },
  {
    value: "separate",
    key: "Separate",
  },
  {
    value: "inline",
    key: "Inline",
  },
];

const hint = "Used during the interview process and by configured bots, on how this attribute should be answered.";

export const AttributeMetadata = () => {
  const canEdit = useIsProjectTechnicalWriter();

  return (
    <Stack gridGap={"1rem"}>
      <Typography>Default information displayed to the user. Can be overridden per interview.</Typography>
      <FormikTextField 
        name="label" 
        label="Label"
        disabled={!canEdit}
      />
      <FormikSelect 
        name="labelDisplay" 
        label="Label display" 
        options={LabelOptions}
        disabled={!canEdit}
      />
      <FormikTextField 
        helperText={hint} 
        name="explanation" 
        label="Explanation" 
        multiline={true} 
        minRows={8}
        disabled={!canEdit}
      />
    </Stack>
  );
};
