import { useDispatch } from "react-redux";
import clsx from "clsx";
import { Pane, SplitPane } from "@common/SplitPane";

import { showModal } from "@imminently/imminently_platform";
import { Action, ModalActions } from "@modals";
import { scrollable } from "@common/scrollbar";
import { Task } from "@packages/commons";
import { tasksService } from "services";
import { FormProvider, useForm } from "react-hook-form";
import { createFormTask, createNewTask, FormTask } from "./taskUtils";
import { Box, Breadcrumbs, IconButton } from "@material-ui/core";

import { CalendarIcon, CloseIcon, UsersIcon } from "@icons";
import { FlagOutlined } from "@material-ui/icons";
import { LabelIcon } from "@icons/generated";
import { DiscIcon } from '@radix-ui/react-icons';
import { CommentsPanel } from "./CommentsPanel";
import { AssigneeSelector, DateSelector, DescriptionInput, PrioritySelector, StatusSelector, TagsInput, TaskDetail, TitleField } from "./FormControls";
import styles from "./tasks.module.scss";
import { useFullScope } from "@common/hooks";
import { useNotification } from "@common/notifications";
import { ProjectWriterGuard } from "auth/ComponentGuard";
import moment, { Moment } from "moment";

export const TaskModal = ({ task, close }: { task?: Task; close: () => void; }) => {
  const { workspace, project, release } = useFullScope();
  const notify = useNotification();
  // TODO get task by id so we have latest and can sub to updates, use enabled: false if no id
  const { action, actionName, isUpdate, query } = tasksService.useMutateResource(task ?? undefined, {});

  // task ? createFormTask(task) : createNewTask("rule")
  const methods = useForm({
    defaultValues: task ? createFormTask(task) : createNewTask("rule")
    // async () => {
    //   if (task) {
    //     const data = await tasksService.getOne(task.id); // .then((res) => res.data);
    //     const formData = createFormTask(data as Task);
    //     debugger;
    //     return formData;
    //   }
    //   return createNewTask("rule");
    // },
    // resolver
  });

  const { handleSubmit } = methods;

  const paneProps = isUpdate ? {
    sizes: [60, 40],
    minSize: [300, 300],
    gutterStyle: () => ({ display: "block", width: "8px" }),
  } : {
    sizes: [100, 0],
    minSize: [300, 0],
    gutterSize: 0,
    gutterStyle: () => ({ display: "none" }),
  }

  const onSubmit = handleSubmit((data: FormTask) => {
    console.log(data);
    const payload = {
      type: isUpdate ? undefined : data.type,
      release: isUpdate ? undefined : release.id,
      title: data.title,
      description: data.description,
      status: data.status.value,
      priority: data.priority.value,
      dueDate: moment.utc(data.dueDate).format() ?? undefined,
      tags: data.tags ?? [],
      assignees: data.assigneesData?.map((a) => a.identity_id) ?? [],
    };
    action(payload)
      .then(res => {
        console.log(res);
        notify.info(`Task ${isUpdate ? "updated" : "created"}`);
        close();
      })
      .catch((e) => {
        notify.error(`Failed to ${isUpdate ? "update" : "create"} task`);
        console.error(e);
      });
  });

  const primaryAction: Action = {
    name: actionName.captilized,
    onClick: () => {
      onSubmit();
    }
  };

  // splitPane maxHeight: "calc(100% - 40px)"
  return (
    <>
      <FormProvider {...methods}>
        <Box className={clsx(styles.header, styles.borderBottom)}>
          <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrums}>
            {/* TODO this should actually be driven off the task if edit, scope if create */}
            <Box>{workspace?.name}</Box>
            <Box>{project?.name}</Box>
            <Box>Release {release?.releaseNo}</Box>
          </Breadcrumbs>
          <IconButton aria-label="close" onClick={close}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <SplitPane {...paneProps} snapOffset={0} className={clsx(styles.body)}>
          <Pane style={{ position: "relative" }}>
            <Box className={clsx(scrollable, styles.content, styles.container)}>
              <TitleField/>
              <Box className={styles.details}>
                <TaskDetail label="Status" icon={<DiscIcon width={24} height={24}/>}>
                  <StatusSelector/>
                </TaskDetail>
                <TaskDetail label="Assignee" icon={<UsersIcon/>}>
                  <AssigneeSelector/>
                </TaskDetail>
                <TaskDetail label="Due Date" icon={<CalendarIcon/>}>
                  <DateSelector label="Pick a date"/>
                </TaskDetail>
                <TaskDetail label="Priority" icon={<FlagOutlined/>}>
                  <PrioritySelector/>
                </TaskDetail>
                <TaskDetail label="Tags" icon={<LabelIcon/>}>
                  <TagsInput/>
                </TaskDetail>
              </Box>
              <DescriptionInput/>
            </Box>
            <ProjectWriterGuard>
              <ModalActions className={styles.actions} primary={primaryAction}/>
            </ProjectWriterGuard>
          </Pane>
          <Pane className={styles.flexGrow} style={{ position: "relative" }}>
            {task ? <CommentsPanel taskId={task?.id}/> : null}
          </Pane>
        </SplitPane>
      </FormProvider>
    </>
  );
};

export const useTaskModal = () => {
  const dispatch = useDispatch();
  return (task?: Task) => {
    dispatch(
      showModal({
        open: true,
        title: null, // should give no title or close btn
        maxWidth: 'lg',
        height: "80vh",
        style: { padding: 0 }
      },
        ({ close }) => <TaskModal task={task} close={close} />
      ));
  }
};