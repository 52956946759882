import React, { memo, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { Resizable } from '@common/Resizable';
import AttributeExplanation from '@components/GraphVisualisation/components/AttributeInfo/AttributeExplanation';
import RuleGraphProvider from '@components/GraphVisualisation/providers/RuleGraphProvider';
import AttributeInfo from '@components/GraphVisualisation/components/AttributeInfo/AttributeInfo';
import AttributeValues from '@components/GraphVisualisation/components/AttributeInfo/AttributeValues';
import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";


const StyledResizable = styled(Resizable)`
  .Pane2 {
    margin: 0;
  }
`;

export interface FocusNodeProps {
  node: any,
  graph: any,
  onNodeChange: (node: any) => void
}

const FocusNode = (props :FocusNodeProps) => {
  const {node, onNodeChange, graph} = props;
  return (
    <Box position='relative' height='100%'>
      <RuleGraphProvider onSelectedNodeChange={onNodeChange} startNode={node} debug={true} graph={graph} >
        <StyledResizable
          split='vertical'
          defaultSize='50%'
          pane1Style={{
            minWidth: "max(30%, 400px)"
          }}
          pane2Style={{ overflow: 'auto' }}
          allowResize
        >
          <AttributeInfo showTitle={false} showInputValues={false} />
          <AttributeValues />
        </StyledResizable>
      </RuleGraphProvider>
    </Box>
  )
}
export default FocusNode;
