import DecisionFlowGraph, {type DecisionFlowGraphProps, type DecisionFlowHandle} from "./DecisionFlowGraph";
import {findDescendant, getNode, setNodes, usePlateEditorRef} from "@udecode/plate-core";
import {EditorState} from "@common/editor/RuleAuthor/multiEditor";
import {useParsedGraph} from "@pages/models/release/GraphContext";
import {useFullRelease} from "@common/hooks_useFullRelease";
import {createCompilerContext, parse, type ParseResult} from "@packages/compiler";
import {useMemo, useRef} from "react";
import {replaceNodeChildren} from "@udecode/plate";
import {cloneDeep} from "lodash";

export interface RuleEditorDecisionFlowGraphProps extends DecisionFlowGraphProps {}

const RuleEditorDecisionFlowGraph = (props: RuleEditorDecisionFlowGraphProps) => {
  const { style, noDataStyle, ...otherProps } = props;
  const editorRef = usePlateEditorRef(EditorState.rules);
  const release = useFullRelease();

  const parsedGraph = useParsedGraph();

  const compilerContext = useMemo(() => {
    return createCompilerContext({
      graph: parsedGraph,
      relationships: release?.relationships,
      documentTree: release?.documents,
    });
  }, [parsedGraph, release?.relationships, release?.documents]);

  const parseText = (text: string) => {
    return parse({
      input: text,
      context: compilerContext,
    });
  };

  const flowGraphRef = useRef<DecisionFlowHandle | null>(null);

  return (
    <DecisionFlowGraph
      ref={flowGraphRef}
      noDataStyle={{
        display: "none",
        ...noDataStyle,
      }}
      style={{
        minHeight: 320,
        border: "1px solid gray",
        borderRadius: "0.5rem",
        ...style,
      }}
      onRequiredDecisiveChange={(step, trigger, value) => {
        const containmentGraphNode = parsedGraph.node(step.containment);
        if (!containmentGraphNode) {
          return;
        }

        const result = findDescendant(editorRef, {
          at: [],
          match: (node) => {
            if (node.type === "rule" && node.expression === "conclusion") {
              const text = node.children?.[0]?.text;
              if (typeof text === "string") {
                if (text === containmentGraphNode.description) {
                  return true;
                }
              }
            }
            return false;
          },
        });
        if (result) {
          const parentPath = [...result[1]];
          parentPath.pop();
          const connectorNode = getNode(editorRef, parentPath);

          if (connectorNode?.type === "connector") {
            const operatorNode = connectorNode.children?.[1];
            if (operatorNode?.type !== "operator") {
              return;
            }

            const inputGraphNode = parsedGraph.node(trigger.input);
            let inputParseResult: ParseResult | undefined;
            const inputResult = findDescendant(editorRef, {
              at: parentPath,
              match: (node) => {
                if (node.type === "rule" && node.expression === "condition") {
                  const text = node.children?.[0]?.text;
                  if (typeof text === "string") {
                    const parsed = parseText(text);
                    if (parsed?.attributes.find((attr) => attr.description === inputGraphNode.description)) {
                      inputParseResult = parsed;
                      return true;
                    }
                  }
                }
                return false;
              },
            });

            if (inputParseResult && inputResult) {
              const firstExpression = inputParseResult.expressions[0];
              if (firstExpression.type === "command") {
                if (firstExpression.command.id === "not") {
                  if (firstExpression.arguments.length !== 1 || firstExpression.arguments[0].type !== "attribute") {
                    console.warn("Complex input not() expression", firstExpression);
                    return;
                  }
                }
              } else if (firstExpression.type !== "attribute") {
                console.warn("Complex input expression", firstExpression);
                return;
              }

              // update operator node
              setNodes(
                editorRef,
                {
                  operator: value.required ? "and" : "or",
                },
                {
                  at: [...parentPath, 1],
                },
              );

              // update input node
              replaceNodeChildren(editorRef, {
                nodes: [{ text: value.required ? inputGraphNode.description : `not(${inputGraphNode.description})` }],
                at: inputResult[1],
              });

              for (const action of trigger.actions) {
                // @ts-ignore
                const outcomeGraphNode = parsedGraph.node(action.outcome);
                if (!outcomeGraphNode) {
                  continue;
                }

                let outcomeParseResult: ParseResult | undefined;
                const outcomeResult = findDescendant(editorRef, {
                  at: parentPath,
                  match: (node) => {
                    if (node.type === "rule") {
                      const text = node.children?.[0]?.text;
                      if (typeof text === "string") {
                        const parsed = parseText(text);
                        if (parsed?.attributes.find((attr) => attr.description === outcomeGraphNode.description)) {
                          outcomeParseResult = parsed;
                          return true;
                        }
                      }
                    }
                    return false;
                  },
                });

                if (outcomeParseResult && outcomeResult) {
                  const firstOutcomeExpression = outcomeParseResult.expressions[0];
                  if (firstOutcomeExpression.type === "command") {
                    if (firstOutcomeExpression.command.id === "known") {
                      if (
                        firstOutcomeExpression.arguments.length !== 1 ||
                        firstOutcomeExpression.arguments[0].type !== "attribute"
                      ) {
                        console.warn("Complex outcome known() expression", firstOutcomeExpression);
                        return;
                      }
                    }
                  } else if (firstOutcomeExpression.type !== "attribute") {
                    console.warn("Complex outcome expression", firstOutcomeExpression);
                    return;
                  }

                  // update outcome node
                  replaceNodeChildren(editorRef, {
                    nodes: [
                      {
                        text: value.decisive ? outcomeGraphNode.description : `known(${outcomeGraphNode.description})`,
                      },
                    ],
                    at: outcomeResult[1],
                  });
                }
              }

              const triggerFlowNode: any = flowGraphRef.current?.flowGraph.node(step.containment);
              if (triggerFlowNode) {
                const flowNodeTrigger = triggerFlowNode.triggers.find((t: any) => t.input === trigger.input);
                flowNodeTrigger.required = value.required;
                flowNodeTrigger.decisive = value.decisive;
                flowGraphRef.current?.optimisticFlowGraphUpdate(cloneDeep(flowGraphRef.current.flowGraph));
              }
            }
          }
        }
      }}
      {...otherProps}
    />
  );
};

export default RuleEditorDecisionFlowGraph;
