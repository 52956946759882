import { Roles } from "auth/permissions";
import { lazy } from "react";

const Projects = lazy(() => import("../pages/projects/Projects"));

export const projects = [
  {
    path: "/projects",
    layout: "decisively",
    role: [Roles.ProjectReader, Roles.ProjectWriter, Roles.ProjectTechnical],
    exact: true,
    component: Projects,
  },
];
