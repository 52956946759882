import {
  mockAuthProvider,
  //AzureIdentityProvider
} from '@imminently/imminently_platform';
import find from 'lodash/find';
import {
  AzureIdentityProvider
} from './immiProvider';

import { config } from './config';

let authProvider;
switch (config.AUTH_TYPE) {
  case 'azure':
  case 'azure-b2c':
    console.log('[AuthProvider] Using Azure Identity Provider');
    authProvider = AzureIdentityProvider({
      b2c: config.AUTH_TYPE === 'azure-b2c' ? true : false,
      authenticatedRedirectPath: '/',
      endpoint: config.AUTH_ENDPOINT,
      client_id: config.AUTH_CLIENT_ID,
      header_key: config.AUTH_HEADER,
      scope: config.AUTH_SCOPE,
      //additionalInformationCheck: config.BACKEND_URL + '/auth/me',
      customHeaders: (headers) => {
        headers['X-TENANCY'] = localStorage.getItem('decisively.tenant');
        return headers;
      },
      getBackendUrl: (user) => {
        return config.BACKEND_URL;
      },
      onAfter: (user, token) => {
        //console.log('[AuthProvider] onAfter', user, token);
        // Record the tenancy info
        let tenant = localStorage.getItem('decisively.tenant');
        //let region = localStorage.getItem('decisively.region');
        if (tenant) {
          // They already have a tenant. Make sure it matches the possible tenants for the user
          let existing = find(user.groups, { id: tenant });
          if (!existing) {
            // They must have been removed from their tenant (or they are trying to change the tenant via cookie)
            // Reset their cookies and we will pick a new tenant below
            localStorage.removeItem('decisively.tenant');
            //localStorage.removeItem('decisively.region');
            tenant = undefined;
          } else {
            // Ignore regions
           /* // There tenancy is the same. Double-check that the region is okay
            if (existing.regions && existing.regions[region]) {
              // Tenancy and region are okay. 
              // Noting to do or change here
              return;
            } else {
              // The don't have this region anymore (or they are trying to get to another region by adjusting their cookies)
              // Reset to their home region
              localStorage.setItem('decisively.region', existing.home_region);
              return; // Tenancy okay and now region is okay
            }*/
          }
        } else {
          console.log('[AuthProvider] No tenancy information found, using the first one');
          // No tenancy information at all. Default to the first one
          if (!user.groups || user.groups.length === 0) throw 'No valid tenancy information';
          tenant = user.groups[0];
          localStorage.setItem('decisively.tenant', tenant.id);
          //localStorage.setItem('decisively.region', tenant.home_region);
        }
      }
    });
    break;
  default:
    authProvider = mockAuthProvider({
      authenticatedRedirectPath: '/',
    })
}

export default authProvider;