import { useDispatch } from "react-redux";
import { DiscIcon } from '@radix-ui/react-icons';
import { FormProvider, useForm } from "react-hook-form";
import { Box, Breadcrumbs, IconButton, Typography } from "@material-ui/core";
import { FlagOutlined } from "@material-ui/icons";
import { Action, ModalActions, showModal } from "@modals";
import { scrollable } from "@common/scrollbar";
import { flowTasksService, FlowTask } from "services";
import { createFlowTask, FlowFormTask } from "@common/tasks/taskUtils";
import { AssigneeSelector, CheckboxInput, DateSelector, PrioritySelector, StatusSelector, TaskDetail, TextInput, TitleField } from "@common/tasks/FormControls";
import { CalendarIcon, CloseIcon, UsersIcon } from "@icons";
import { useFullScope } from "@common/hooks";
import { ProjectWriterGuard } from "auth/ComponentGuard";
import { useNotification } from "@common/notifications";
import styles from "./tasks.module.scss";
import clsx from "clsx";
import { Stack } from "@components";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import styled from "styled-components";
import { colors } from "theme";
import { useTranslation } from "react-i18next";
import { useInterviewInteraction } from "@common/interview";
import { Session, SessionInstance } from "@decisively-io/interview-sdk";
import { SessionInterviewForm } from "./components";
import { EmptyContent } from "@common";
import { DialogClose, useBasicModal, useModalContext } from "@modals/Modals";

const PDFViewer = styled(DocViewer)`
  /* border: 1px solid red; */
  background-color: #a3a3a3 !important;
  border-radius: 0.5rem;

  #pdf-controls {
    display: none;
    box-shadow: none;
    border: 1px solid #e5e5e5;
    border-radius: 0.5rem;
    background-color: ${p => p.theme.palette.background.default};

    button, a {
      box-shadow: none;
      border: none;
      &:hover {
        background-color: #f5f5f5;
        border-radius: 0.25rem;
      }
    }
  }

  .react-pdf__Document {
    padding: 2rem 0;
  }

  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }
`;

const FileViewer = ({ file }: { file?: File }) => {
  const docs = file ? [
    {
      uri: window.URL.createObjectURL(file),
      fileName: file.name,
    }
  ] : [{
    uri: "/National-Police-Check-NPC-Application-Form.pdf",
    fileName: "National-Police-Check-NPC-Application-Form.pdf",
  }];

  return (
    <PDFViewer
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
        },
      }}
      documents={docs}
      pluginRenderers={DocViewerRenderers}
    />
  );
};

const InterviewContainer = styled.div`
  > div {
    min-height: 50vh;
    max-height: 50vh;
  }
  .formWrap_2NgTRe > .form_eyu2Bt {
    max-width: 100%;
  }
`;

const isLastStep = (steps: Session["steps"], id: string): boolean => {
  if (!Array.isArray(steps) || steps.length === 0) return false;
  const last = steps[steps.length - 1];
  if (last.id === id) {
    return true;
  }
  if (last.steps?.length) {
    return isLastStep(last.steps, id);
  }
  return false;
};

const useIsLastInterviewStep = (session: SessionInstance) => {
  if (!session) return false;
  const { steps, screen, status } = session;
  if (!steps) return false;
  const singleStep = steps.length === 1;
  const lastStep = isLastStep(steps, screen?.id) && status === "in-progress";
  // in this case, if its a single step, we always want to display the actions
  return singleStep || lastStep;
};

export const FlowTaskForm = ({ task, close, standAlone }: { task: FlowTask; close: () => void; standAlone?: boolean }) => {
  const notify = useNotification();
  const { t } = useTranslation();
  const { action, query } = flowTasksService.useMutateResource(task, {});

  const { session, error: interviewError, provider } = useInterviewInteraction(task.model, task.session, task.interaction);
  const lastStep = useIsLastInterviewStep(session as SessionInstance);

  const methods = useForm({
    defaultValues: createFlowTask(task)
  });

  const { handleSubmit } = methods;

  const approve = handleSubmit((data: FlowFormTask) => {
    const outcome = task.approvalOutcome ?? true;
    const payload = {
      outcome,
      justification: data.justification,
      data: data.interviewData, // the interview data
    };
    action(payload)
      .then(res => {
        flowTasksService.invalidateAllQueries();
        close();
      }).catch((e) => {
        notify.error(t('tasks.error_approve_msg'));
        console.error(e);
      });
  });

  const reject = handleSubmit((data: FlowFormTask) => {
    const outcome = task.rejectionOutcome ?? true;
    const payload = {
      outcome,
      justification: data.justification,
      data: data.interviewData, // the interview data
    };
    action(payload)
      .then(res => {
        flowTasksService.invalidateAllQueries();
        close();
      }).catch((e) => {
        notify.error(t('tasks.error_reject_msg'));
        console.error(e);
      });
  });

  const primaryAction: Action = {
    name: task.approvalLabel ?? t('tasks.approve_label'),
    onClick: approve,
    loading: query.isLoading,
    disabled: query.isLoading || !lastStep,
  };

  const secondaryAction: Action[] = [{
    name: task.rejectionLabel ?? t('tasks.reject_label'),
    onClick: reject,
    loading: query.isLoading,
    disabled: query.isLoading || !lastStep,
  }];

  return (
    <FormProvider {...methods}>
      <Box className={clsx(styles.taskDetails, styles.container, styles.borderBottom)}>
        <TitleField />
        <Box className={styles.details}>
          <TaskDetail label={t('metadata.status')} icon={<DiscIcon width={24} height={24} />}>
            <StatusSelector omit={["closed", "rejected", "approved"]} />
          </TaskDetail>
          <TaskDetail label={t('tasks.assignee')} icon={<UsersIcon />}>
            <AssigneeSelector />
          </TaskDetail>
          <TaskDetail label={t('tasks.due_date')} icon={<CalendarIcon />}>
            <DateSelector label="Pick a date" />
          </TaskDetail>
          <TaskDetail label={t('tasks.priority_label')} icon={<FlagOutlined />}>
            <PrioritySelector />
          </TaskDetail>
        </Box>
      </Box>
      <InterviewContainer>
        {
          session
            ? <SessionInterviewForm interviewProvider={provider} session={session as SessionInstance} />
            : <EmptyContent messages={[interviewError ? interviewError : "Loading session..."]} img="" variant="h5" style={{ minHeight: 300 }} />
        }
      </InterviewContainer>
      <Stack className={clsx(styles.taskDetails, styles.borderTop)} style={standAlone ? {
        position: 'absolute',
        width: '100%',
        bottom: 0
      } : {}}>
        <Typography variant="h6">{t('explanation.justification')}</Typography>
        <TextInput multiline minRows={4} name="justification" placeholder={t('tasks.justification_placeholder')} />
        <ProjectWriterGuard>
          <ModalActions className={styles.actions} primary={primaryAction} secondary={secondaryAction} />
        </ProjectWriterGuard>
      </Stack>
    </FormProvider>
  );
};

export const TaskModal = ({ task, close, standAlone }: { task: FlowTask; close: () => void; standAlone: boolean }) => {
  const { workspace, project, release } = useFullScope();
  const { fullScreen } = useModalContext();
  const headerHeight = 40 + 62 + 58; // combined size in px of the header, info, status bar and tabs
  // of not fullscreen, include the 2rem margin around the modal
  const height = fullScreen ? `calc(100vh - ${headerHeight}px)` : `calc(100vh - 4rem - ${headerHeight}px)`;

  return (
    <>
      <Box className={clsx(styles.header, styles.borderBottom)} >
        <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrums}>
          {/* TODO this should actually be driven off the task if edit, scope if create */}
          <Box>{workspace?.name}</Box>
          <Box>{project?.name}</Box>
          <Box>Release {release?.releaseNo}</Box>
        </Breadcrumbs>
        <DialogClose />

      </Box>
      <div className={clsx(scrollable, styles.body)} style={{height, minWidth: "calc(100vw - 4rem)"}}>
        <FlowTaskForm task={task} close={close} standAlone={standAlone} />
      </div>
    </>
  );
};

export const useSessionTaskModal = () => {
  const openBasicModal = useBasicModal();


  return (task: FlowTask) => {

    openBasicModal({
      header: null,
      maxWidth: false,
      allowResize: true,
      children: <TaskModal task={task} close={close} standAlone={true}/>
    });
    /*dispatch(
      showModal({
        open: true,
        title: null, // should give no title or close btn
        // TODO this wont work as we are hard overriding the header
        allowResize: true,
        maxWidth: 'xl',
        height: "80vh",
        style: { padding: 0 }
      },
        ({ close }) => <TaskModal task={task} close={close} />
      ));*/
  }
};