import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { css } from "@emotion/react";
import cn from "clsx";

const styles = {
  list: css`
    // inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground
    display: inline-flex;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    background-color: #f3f4f6;
    padding: 0.25rem;
    color: #6b7280;
  `,
  trigger: css`
    // inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 0.125rem;
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 100ms;
    outline: none;
    &[data-state="active"] {
      background-color: #f9fafb;
      color: #1f2937;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  `,
  content: css`
    // mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2
    margin-top: 0.5rem;
    outline: none;
    &:focus-visible {
      outline: none;
      box-shadow: 0px 0px 0px 3px rgba(59, 130, 246, 0.5);
    }
  `,
};

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(className)}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(className)}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(className)}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export type TabValue = {
  value: string;
  label: string;
  icon?: JSX.Element;
  content: JSX.Element;
};

export { Tabs, TabsList, TabsTrigger, TabsContent };
