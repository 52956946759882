import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Search, SecondaryButton } from "@components";
import { getNodeLabel, getSCTheme, scrollableMixin } from "@common";
import ReportNode from "./ReportNode";
import {SortableTreeWithoutDndContext} from "react-sortable-tree";
import { isTemporal } from "@pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types";
import { showModal } from "@imminently/imminently_platform";
import { useDispatch } from "react-redux";
import AttributeContext from "@pages/models/release/AttributeContext";
import  ReleaseContextProvider from "@pages/models/release/ReleaseContextProvider";
import { useFullRelease } from "@common/hooks_useFullRelease";
import FocusNode from "@components/FocusNode/FocusNode";
import { get, last, debounce } from "lodash";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import { useSortableTreeRefCtx, WithSortableTreeRefCtx } from "./SortableTreeRefCtx";
import { useGetter } from "@common/hooks/HooksGeneral";

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-top: 0.5rem;
  display: flex;
`;


const calcRowHeight = p => {
  const {
    reference,
    description,
    links,
    hidden,
    node,
    type,
  } = p.node;

  if (type === "special") {
    return 30;
  }

  if (!node || hidden) return 0;

  const value = node.derived ?? node.input ?? node.value;
  const isValueTemporal = isTemporal(value);
  const pref = localStorage.getItem("decisively.temporalViewPref");

  return (
    /* border-top + border-bottom */4 +
    /* padding */16 +
    /* title */24 +
    /* reference */(reference === undefined ? 0 : (18 + 4)) +
    /* desc */(description === undefined ? 0 : (Math.ceil(description.length / 85) * 18 + 4)) +
    /* links */(links === undefined || links.length === 0 ? 0 : (21 * links.length + 4)) +
    /** value */(isValueTemporal ? (pref === "chart" ? 80 : 40) : (28 + 4))
  );
};

const CollapseControlsWrap = styled.div`
  display: flex;
  gap: 0.25rem;

  > * {
    flex-shrink: 0;
  }

  > *:nth-child(3) {
    width: 5rem;

    .MuiFormLabel-root, legend {
      font-size: 0.825rem;
    }

    .MuiInputBase-root {
      height: 2rem;
      font-size: 0.75rem;
      font-weight: bold;
    }

    fieldset {
      border-color: ${p => getSCTheme(p).palette.secondary.main};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
    }
  }
`;


const StyledSortableTree = styled(SortableTreeWithoutDndContext)`
  padding: 1.5rem 2rem;

  .ReactVirtualized__Grid {
    margin-left: -44px;
  }

  .rst__lineBlock, .rst__lineChildren:after {
    visibility: hidden;
  }


  .rst__nodeContent {
    position: absolute;
  }

  .rst__virtualScrollOverride {
    ${scrollableMixin};
    overflow: overlay !important;
  }
`;


function changeAllNodes(node, expanded = true, maxLevel, curLevel = 0) {
  return {
    ...node,
    expanded: (maxLevel && curLevel >= maxLevel) ? false : expanded,
    children: Array.isArray(node.children)
      ? node.children.map(it => changeAllNodes(it, expanded, maxLevel, curLevel + 1))
      : undefined,
  };
}

const DecisionReportCore = ({ goal, graph }) => {
  const [ searchTerm, setSearchTermRaw ] = useState("");
  const [ searchTermDebounced, setSearchTermDebouncedRaw ] = useState("");
  const setSearchTermDebounced = useMemo(
    () => debounce( setSearchTermDebouncedRaw, 500 ),
    [ setSearchTermDebouncedRaw ],
  );
  const setSearchTerm = useCallback( search => {
    setSearchTermRaw( search );
    setSearchTermDebounced( search );
  }, [ setSearchTermRaw, setSearchTermDebounced ]);

  const [ collapseTo, setCollapseTo ] = useState("1");
  const [ foundCount, setFoundCount ] = useState(0);
  const [ focusOffset, setFocusOffset ] = useState(0);
  const dispatch = useDispatch();
  const release = useFullRelease();

  const sortableTreeRefCtxValue = useSortableTreeRefCtx();
  const reactVirtualizedListProps = useMemo(() => ({
    ref: (ref) => {
      sortableTreeRefCtxValue.ref.current = ref;
    },
  }), [ sortableTreeRefCtxValue ]);

  const recurse = (id, seen = []) => {
    // Add this node
    const node = graph.node(id);
    console.log(node);
    if (!node || node.hidden == true) return;
    if (seen.indexOf(id) > -1) {
      const children = graph.successors(id);
      if (!children || children.length === 0) return { node: node, children: []};
      return {
        node: node,
        children: [ {
          type: "special",
          title: "Proven elsewhere",
        } ],
      };
    }
    seen.push(id);

    const children = graph.successors(id).map(child => {
      return recurse(child, seen);
    }).filter(x => x);

    return {
      node: node,
      title: node.description,
      expanded: true,
      children: children,
    };
  };


  // need to construct the tree data
  const [ tree, setTree ] = useState(() => {
    if (!goal) return; // Build from first root?
    return [ recurse(goal) ].filter(x => x);
  });
  useEffect(() => {
    if (!goal) return;
    setTree([ recurse(goal) ].filter(x => x));
  }, [ goal, graph ]);


  const clearSearchTerm = () => {

  };
  const expandAll = () => {
    setTree([ changeAllNodes(tree[0]) ]);
  };
  const collapseAll = () => {
    setTree([ changeAllNodes(tree[0], false) ]);
  };
  const handleCollapseTo = ({ target: { value } }) => {
    setTree([ changeAllNodes(tree[0], true, value) ]);
    setCollapseTo(value);
  };
  const selectNextMatch = useCallback(() => setFocusOffset(focusOffset => (
    focusOffset !== null
      ? (focusOffset + 1) % foundCount
      : 0),
  ), [ foundCount ]);
  const selectPrevMatch = useCallback(() => setFocusOffset(focusOffset => (
    focusOffset !== null
      ? (foundCount + focusOffset - 1) % foundCount
      : foundCount - 1),
  ), [ foundCount ]);
  const handleSearchKeyDown = useCallback(e => {
    if (e.key === "Enter") {
      selectNextMatch();
    }
  }, [ selectNextMatch ]);
  const getGraph = useGetter(graph);

  const displayModal = (node) => {
    dispatch(
      showModal({
          open: true,
          title: getNodeLabel(node),
          maxWidth: "xl",
          contentStyle: {
            height: 'calc(100vh - 2rem)'
          },
          allowResize: true,
        },
        ({ setDynamicTitle, history, setHistory }) => {
          return (
            <ReleaseContextProvider release={release}>
              <AttributeContext.Provider value={node}>
                <FocusNode preventLinks={true} node={last(history) || node} graph={getGraph()} onNodeChange={(node) => {
                  if (!node) return;
                  setDynamicTitle(getNodeLabel(node));
                  if (history.length === 0 || node.id !== last(history).id) setHistory([ ...history, node ]);
                }}/>
              </AttributeContext.Provider>
            </ReleaseContextProvider>
          );
        },
      ),
    );

  };

  const { selectedNode } = useGraphVisualisation();

  useEffect(() => {
    if (selectedNode) {
      setSearchTerm(selectedNode.description);
    }
  }, [ selectedNode ]);


  return (
    <Wrap>
      <Box display="flex" justifyContent="space-between" padding="0.5rem 2rem">
        <Grid item>
          <Search
            placeholder="Search report..."
            value={searchTerm}
            setValue={setSearchTerm}
            onKeyDown={handleSearchKeyDown}
            onClear={clearSearchTerm}
          />
        </Grid>
        <Box display="flex" gridGap="1rem" alignItems="center">
          <CollapseControlsWrap>
            <SecondaryButton size="small" onClick={expandAll}>Expand All</SecondaryButton>
            <SecondaryButton size="small" onClick={collapseAll}>Collapse All</SecondaryButton>
            <FormControl variant="outlined" size="small">
              <InputLabel>Collapse to</InputLabel>
              <Select value={collapseTo || "1"} label="Collapse to" onClick={handleCollapseTo}>
                {
                  [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10+" ].map(it => (
                    <MenuItem key={it} value={it}>{it}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </CollapseControlsWrap>
          {foundCount > 0 ? `${focusOffset + 1}/${foundCount}` : null}
          <Box display="flex" gridGap="0.25rem">
            <IconButton aria-label="back" onClick={selectPrevMatch}>
              <ArrowBackIosIcon/>
            </IconButton>
            <IconButton aria-label="forward" onClick={selectNextMatch}>
              <ArrowForwardIosIcon/>
            </IconButton>
          </Box>
        </Box>
      </Box>
      <StyledSortableTree
        treeData={tree}
        onChange={setTree}
        canDrag={false}
        rowHeight={calcRowHeight}
        isVirtualized={true}
        searchQuery={searchTermDebounced}
        searchFocusOffset={focusOffset}
        searchMethod={(term) => {
          if (!term.searchQuery) return false;
          const cmp = get(term, "node.node.description");
          if (cmp && term.searchQuery && String(cmp).toLowerCase().match(
            String(term.searchQuery).toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
          )) return true;
          return false;
        }}
        searchFinishCallback={(matches) => {
          setFoundCount(matches.length);
          setFocusOffset(focusOffset => (matches.length > 0 ? focusOffset % matches.length : 0));
        }}
        nodeContentRenderer={(props) => <ReportNode graph={graph} {...props} onClick={displayModal}/>}
        reactVirtualizedListProps={reactVirtualizedListProps}
      />
    </Wrap>
  );
};

const DecisionReport = props => (
  <WithSortableTreeRefCtx>
    <DecisionReportCore {...props} />
  </WithSortableTreeRefCtx>
);

export default DecisionReport;
