import { FormikSelect, FormikTextField, FormikCheckbox } from "@components/forms/FormikControls";
import { Stack } from "@components";
import { useFormikContext } from "formik";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { useIsProjectTechnicalWriter } from "auth/permissions";
import { Typography } from "@mui/material";
import { get } from "lodash";

export const AttributeIntegrationForm = ({ scope }: { scope: string }) => {
  const { values } = useFormikContext<any>();
  const release = useFullReleaseStrict();
  const canEdit = useIsProjectTechnicalWriter();

  const connections = release.connections?.map(it => ({ key: it.name, value: it.id }));
  const requestMapping = release.mapping?.map(it => ({ key: it.name, value: it.id }));
  const responseMapping = release.mapping?.map(it => ({ key: it.name, value: it.id }));

  const contentTypeOptions = [
    { value: "application/json", key: "JSON" },
    { value: "application/x-www-form-urlencoded", key: "Form URL Encoded" },
  ];

  const selectedConnection = release.connections?.find(it => it.id === get(values, `${scope}.connection`));

  const methodOptions = [
    { value: "get", key: "GET" },
    { value: "post", key: "POST" },
    { value: "put", key: "PUT" },
    { value: "patch", key: "PATCH" },
    { value: "delete", key: "DELETE" },
  ];

  const scopedValue = (name: string) => `${scope}.${name}`;

  return (
    <Stack gridGap="1rem">
      <FormikTextField fullWidth label="Title" required name={scopedValue("title")} disabled={!canEdit} />
      <FormikSelect label="Connection" required name={scopedValue("connection")} options={connections || []} disabled={!canEdit} />
      {/* Show Base URL from selected connection */}
      {selectedConnection?.url && (
        <Typography variant="subtitle2" color="textSecondary">
          Base URL: <strong>{selectedConnection.url}</strong>
        </Typography>
      )}
      {/* Path Input Field */}
      <FormikTextField fullWidth required label="Path (relative to Base URL)" name={scopedValue("path")} disabled={!canEdit} />
      {/* General API Settings */}
      <FormikSelect label="HTTP Method" required name={scopedValue("restMethod")} options={methodOptions} disabled={!canEdit} />
      <FormikSelect label="Content Type" required name={"contentType"} options={contentTypeOptions} disabled={!canEdit} />
      {/* Query & Mapping */}
      <FormikTextField fullWidth label="Query Parameters" name={scopedValue("query")} disabled={!canEdit} />
      <FormikSelect label="Request Mapping" name={scopedValue("requestMapping")} options={requestMapping || []} disabled={!canEdit} />
      <FormikSelect label="Response Mapping" name={scopedValue("responseMapping")} options={responseMapping || []} disabled={!canEdit} />
      {/* Retry & Security Settings */}
      <FormikTextField fullWidth type="number" label="Retry Attempts" name={scopedValue("retryAttempts")} disabled={!canEdit} />
    </Stack>
  );
};
/*
{
  "url": "string",
  "method": "GET|POST|PUT|PATCH|DELETE",
  "contentType": "application/json|application/x-www-form-urlencoded",
  "authType": "None|Basic|API Key|Oauth 2.0|JWT|Bearer",
  // Required for Basic
  "username": "string",
  "password": "string",
  // Required for API Key or Bearer
  "apiKey": "string",
  // Required for Oauth 2.0
  "oauth": {
    "clientId": "string",
    "clientSecret": "string",
    "tokenUrl": "string",
    "scope": "string"
  },
  // Required for JWT
  "jwt": "string",

  "query": "string",
  "requestMapping": "linked mapping id",
  "responseMapping": "linked mapping id",
  "retryAttempts": "number",
  "notificationEmail": "email",
  "tlsVersion": "1.2|1.3",
  "verifyCerts": "boolean"
}
  */