/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { Button, SecondaryButton, Label, Item as MenuItem, withMenu, Switch } from '@components';
import styled from 'styled-components';
import {
  Typography,
  useTheme,
  Dialog,
  IconButton,
  Box,
  Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';
import { GridList } from '@common';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  hideModal,
  CRUD_UPDATE_SUCCESS,
  CRUD_GET_LIST_SUCCESS,
  crudUpdate,
  showNotification,
  showModal
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@imminently/imminently_platform';
import { LoadingDotsJSX, MenudotsIcon } from '@icons';
import Card from '@material-ui/core/Card';
import Skeleton from '@material-ui/lab/Skeleton';
// import { useDrag, useDrop } from 'react-dnd';
import * as ImportReleaseModal from './ImportReleaseModal';
import * as DeleteReleaseModal from './DeleteReleaseModal';
import { setRelease, setProject } from '../../redux/scope';
import { exportRelease } from '../../redux';
import * as combinatorV2NS from '../../redux/combinatorV2.saga';
import * as reduxNS from './redux';
// eslint-disable-next-line import/extensions
import * as deleteReleaseNS from './deleteRelease.saga';
import * as ManageReleaseModalNS from './ManageReleaseModal';
import { ModalActions } from '@modals';
import { Roles, useAuthorise } from 'auth/permissions';
import { ReleaseManagerGuard } from 'auth/ComponentGuard';
import * as populateProjectReleaseCentreNS from './redux';
import { Trans, useTranslation } from 'react-i18next';

// ===================================================================================

/** @type { React.FC< Parameters< IconButton >[ 0 ] > } */
const MenuButton = props => <IconButton {...props}><MenudotsIcon /></IconButton>;
/** @type { React.FC<React.PropsWithChildren<{}>> } */
const MenuWrap = withMenu(MenuButton, {});

const ImportReleaseButton = styled(Button)`
  margin-bottom: 1.5rem;
`;

/**
  @typedef {{
    no: number;
    id: string;
    isCurrent: boolean;
    lastModifiedAt: string;
    basedOn?: number;
    promotedAs?: number;
    project: string;
    env: string;
    description?: string;
    locale: string;
  }} Release
*/

/**
  @typedef {{
    versionNo: number;
    env: string;
    id: string;
    lastModified: string;
    model: string;
    activeVersionFlag: boolean,
    promotedAs: number,
    releaseNo: number,
    basedOn: number;
    description?: string;
    locale: string;
  }} ReleaseInResources
*/

// ===================================================================================

const classesRC = {
  topRow: {
    _: 'topRow',

    texts: {
      _: 'texts',

      name: 'name',
      descOrId: 'descOrId',
    },

    menu: 'menu',
  },

  middleRow: {
    _: 'middleRow',

    tag: 'tag',

    lastModified: {
      _: 'lastModified',

      date: 'date',
      desc: 'desc',
    },
  },

  bottomRow: {
    _: 'bottomRow',

    relationships: {
      _: 'relationships',

      label: 'label',

      basedOn: {
        _: 'basedOn',
        value: 'value',
        link: 'link',
      },

      promotedAs: {
        _: 'promotedAs',

        hidden: 'hidden',
      },
    },

    view: 'view',
  },
};
const relationshipsClasses = classesRC.bottomRow.relationships;


const ReleaseCard = styled(Card)`
  &:hover {
    box-shadow: ${p => p.theme.body.boxShadow};
  }

  border: 1px solid #E5E5E5;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: ${p => p.theme.palette.background.default};

  .${classesRC.topRow._} {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    .${classesRC.topRow.texts._} {
      flex-grow: 1;
      overflow: hidden;
    }

    .${classesRC.topRow.texts.name} {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      margin: 0;
    }

    .${classesRC.topRow.texts.descOrId} {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .${classesRC.topRow.menu} {
      flex-shrink: 0;
    }
  }



  .${classesRC.middleRow._} {
    display: flex;
    margin-bottom: 1rem;
  }

  .${classesRC.middleRow.tag} {
    flex-grow: 1;
  }

  .${classesRC.middleRow.lastModified.date} {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    text-align: right;
  }

  .${classesRC.middleRow.lastModified.desc} {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    text-align: right;
  }



  .${classesRC.bottomRow._} {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .${relationshipsClasses.basedOn._}, .${relationshipsClasses.promotedAs._} {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
    }

    .${relationshipsClasses.basedOn.link} {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }

    .${relationshipsClasses.basedOn.value} {
      font-weight: 700;
    }

    .${relationshipsClasses.label} {
      margin-right: 0.25rem;
    }

    .${relationshipsClasses.promotedAs.hidden} { visibility: hidden; }
  }
`;


const Menu = styled.div`
  background-color: ${p => p.theme.palette.background.default};
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;

  > [data-error=true] {
    color: ${p => p.theme.palette.error.main};
  }
`;

const actionTypes = {
  edit: 'edit',
  duplicate: 'duplicate',
  makeCurrent: 'makeCurrent',
  promote: 'promote',
  // viewApi: 'viewApi',
  export: 'export',
  // leave: 'leave',
  //cleanUp: "cleanUp",
  delete: 'delete',
};

// ===================================================================================


const ExportModal = ({ release, projectName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [includeScenarios, setIncludeScenarios] = useState(false);
  const handleClick = () => {
    dispatch(showNotification(t('release_centre.export_release_notification')));
    dispatch(exportRelease(
      release.id,
      {
        includeScenarios: includeScenarios,
        fallbackProjectName: projectName,
      }
    ));
    dispatch(hideModal())
  }
  const toggleScenarios = () => {
    setIncludeScenarios(!includeScenarios);
  }
  return (
    <>
      <Switch
        color="secondary"
        checked={includeScenarios}
        name="scenarios" label={t('release_centre.include_scenarios')} onChange={toggleScenarios} />
      <ModalActions primary={{
        name: t('export_label'),
        onClick: handleClick
      }} />
    </>
  );
}

/** @type { React.FC< { release: Release; isLastOne?: true; projectName: string; } > } */
const ReleaseCardComp = ({ release, isLastOne, projectName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authorise = useAuthorise();
  const {
    id,
    isCurrent,
    lastModifiedAt,
    no,
    basedOn,
    promotedAs,
    project,
    env,
    description,
    locale,
  } = release;
  const { t } = useTranslation();

  const theme = useTheme();

  const [modalContents, setModalContents] = React.useState(null);
  const resetModalContents = React.useCallback(() => setModalContents(null), [setModalContents]);

  const deleteRelease = React.useCallback(() => {
    dispatch(deleteReleaseNS.AC({
      releaseId: id,
      projectId: project,
    }));
    // dispatch(
    //   combinatorNS.ActionCreator({
    //     toDispatch: reduxNS.ActionCreator(project),
    //     waitFor: {
    //       type: CRUD_DELETE_SUCCESS,
    //       validate: a => /** @type { any } */(a).requestPayload?.id === id,
    //     },
    //     bootstrap: crudDelete('releases', id),
    //   }),
    // );
  }, [dispatch, id, project]);


  const openRelease = React.useCallback(
    () => {
      dispatch(setProject(project));
      dispatch(setRelease(id));
      dispatch(hideModal());
      history.push('/build');
    },
    [id, dispatch, history, project],
  );

  /** @type { ( e: React.MouseEvent< HTMLButtonElement > ) => unknown } */
  const onMenuButtonClick = React.useCallback(e => {
    const { action } = e.currentTarget.dataset;

    const actualizeSameEnvAction = reduxNS.aCreators.requestToActualizeReleases({
      type: 'maybePlusOnePagination',
      componentId: env === 'test' ? 'test' : 'prod',
      projectId: project,
    });

    switch (action) {
      // case actionTypes.viewApi:
      //   dispatch(setProject(project));
      //   dispatch(setRelease(id));
      //   dispatch(hideModal());
      //   history.push('/build/api');
      //   return;
      case actionTypes.edit:
        return void setModalContents(
          <ManageReleaseModalNS._
            close={resetModalContents}
            title={t('release_centre.edit_release')}
            data={{ description, locale }}
            submitData={data => new Promise(
              // eslint-disable-next-line no-promise-executor-return
              r => dispatch(
                combinatorV2NS.ActionCreator({
                  bootstrap: crudUpdate('releases', id, data),
                  success: {
                    type: CRUD_UPDATE_SUCCESS,
                    validate: a => /** @type { any } */(a)?.meta?.id === id,
                    call: () => {
                      r();
                      resetModalContents();
                    },
                  },
                }),
              ),
            )}
          />,
        );
      case actionTypes.duplicate:
        return void setModalContents(
          <ManageReleaseModalNS._
            close={resetModalContents}
            title={t('release_centre.duplicate_release')}
            data={{ description: '', locale }}
            submitData={data => new Promise(
              // eslint-disable-next-line no-promise-executor-return
              r => dispatch(
                combinatorV2NS.ActionCreator({
                  bootstrap: crudUpdate('releases', id, data, { customAction: 'clone' }),
                  success: {
                    type: CRUD_UPDATE_SUCCESS,
                    validate: a => /** @type { any } */(a)?.meta?.id === id,
                    dispatch: combinatorV2NS.ActionCreator({
                      bootstrap: reduxNS.aCreators.requestToActualizeTestAndProd({
                        projectId: project
                      }),
                      success: {
                        type: CRUD_GET_LIST_SUCCESS,
                        validate: a => /** @type { any } */(a)?.meta?.resource === 'releases',
                        call: () => {
                          r();
                          resetModalContents();
                        },
                      },
                    }),
                  },
                }),
              ),
            )}
          />,
        );
      case actionTypes.makeCurrent:
        dispatch(
          combinatorV2NS.ActionCreator({
            bootstrap: crudUpdate('releases', id, {}, { customAction: 'activate', refresh: false }),
            success: {
              type: CRUD_UPDATE_SUCCESS,
              validate: a => /** @type { any } */(a)?.meta?.id === id,
              dispatch: actualizeSameEnvAction,
            },
          }),
        );
        return;
      case actionTypes.promote:
        return void setModalContents(
          <ManageReleaseModalNS._
            close={resetModalContents}
            title={t('release_centre.promote_release')}
            data={{ description, locale }}
            submitData={data => new Promise(
              // eslint-disable-next-line no-promise-executor-return
              r => dispatch(
                combinatorV2NS.ActionCreator({
                  bootstrap: crudUpdate('releases', id, data, { customAction: 'deploy' }),
                  success: {
                    type: CRUD_UPDATE_SUCCESS,
                    validate: a => {
                      const broadAction = /** @type { any } */(a);

                      return broadAction.meta?.id === id &&
                        broadAction?.meta?.customAction === 'deploy';
                    },
                    dispatch: combinatorV2NS.ActionCreator({
                      bootstrap: reduxNS.aCreators.requestToActualizeTestAndProd({
                        projectId: project,
                      }),
                      success: {
                        type: CRUD_GET_LIST_SUCCESS,
                        validate: a => /** @type { any } */(a)?.meta?.resource === 'releases',
                        call: () => {
                          r();
                          resetModalContents();
                        },
                      },
                    }),
                  },
                }),
              ),
            )}
          />,
        );
      case actionTypes.export:
        hideModal();
        dispatch(showModal(
          {
            open: true,
            title: t('release_centre.export_release'),
          },
          <ExportModal release={release} projectName={projectName} />,
        ))
        return;
      case actionTypes.delete:
        return void setModalContents(<DeleteReleaseModal._ close={resetModalContents} onDelete={deleteRelease} />);
      default:
    }
  }, [project, dispatch, id, setModalContents, env, resetModalContents, deleteRelease, description, locale, projectName]);


  const menuItems = [
    {
      action: actionTypes.edit,
      label: t('edit_label'),
      env: 'test', // can only edit when in test
      role: [Roles.ReleaseManager, Roles.ProjectWriter],
    },
    {
      action: actionTypes.duplicate,
      label: t('duplicate'),
      role: (env) => env === "prod" ? Roles.ReleaseManager : [Roles.ReleaseManager, Roles.ProjectWriter],
    },
    {
      action: actionTypes.makeCurrent,
      label: t('release_centre.make_current'),
      role: (env) => env === "prod" ? Roles.ReleaseManager : [Roles.ReleaseManager, Roles.ProjectWriter],
    },
    {
      action: actionTypes.promote,
      label: t('promote_label'),
      role: Roles.ReleaseManager,
      env: 'test', // can only promote from test to prod
    },
    {
      action: actionTypes.export,
      label: t('export_label'),
      role: [Roles.ReleaseManager, Roles.ProjectWriter],
    },
    {
      action: actionTypes.delete,
      label: t('delete_label'),
      className: "error",
      role: (env) => env === "prod" ? Roles.ReleaseManager : [Roles.ReleaseManager, Roles.ProjectWriter],
    }
  ];

  const mItems = menuItems
    // filter by items for this environment
    .filter(item => item.env !== undefined ? item.env === env : true)
    // remove items we don't have authorisation for
    .filter(({ role }) => role ? (typeof role === "function" ? authorise(role(env)) : authorise(role)) : true)
    // finally, render the items
    .map(({ action, label, role, ...rest }) => {
      const isDisabled = (isLastOne && action === actionTypes.delete) ||
        (action === actionTypes.makeCurrent && release.isCurrent);

      return (
        <MenuItem
          small
          name={label}
          data-action={action}
          onClick={onMenuButtonClick}
          disabled={isDisabled}
          key={action}
          {...rest}
        />
      );
    });

  const menu = mItems.length > 0 ? <Menu>{mItems}</Menu> : null;

  // const [, drag] = useDrag(() => ({
  //   type: DND_RELEASE_CARD_TYPE,
  //   collect: monitor => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  //   item: { id, env },
  // }));

  return (
    <ReleaseCard elevation={0}>
      <div className={classesRC.topRow._}>
        <div className={classesRC.topRow.texts._}>
          <h3 className={classesRC.topRow.texts.name}>
            <Trans i18nKey="release_label"></Trans>
            {' '}
            {no}
          </h3>

          <Tooltip title={description || id}>
            <p className={classesRC.topRow.texts.descOrId}>
              {description || id}
            </p>
          </Tooltip>
        </div>
        {
          menu ? (
            <div className={classesRC.topRow.menu}>
              <MenuWrap>{menu}</MenuWrap>
            </div>
          ) : null
        }
      </div>

      <div className={classesRC.middleRow._}>
        <div className={classesRC.middleRow.tag}>
          {
            isCurrent && (
              <Label color={/** @type { any } */(theme).labels.Current}>
                <Trans i18nKey="current"></Trans>
              </Label>
            )
          }
        </div>
        <div className={classesRC.middleRow.lastModified._}>
          <p className={classesRC.middleRow.lastModified.date}>
            {lastModifiedAt}
          </p>
          <p
            className={classesRC.middleRow.lastModified.desc}
            style={{ color: /** @type { any } */(theme.palette).core.ultimateGrey }}
          >
            <Trans i18nKey="last_modified"></Trans>
          </p>
        </div>
      </div>

      <div className={classesRC.bottomRow._}>

        <div className={relationshipsClasses._}>

          <div className={relationshipsClasses.basedOn._}>
            <span className={relationshipsClasses.label}>
              <Trans i18nKey="release_centre.based_on"></Trans>:
            </span>
            {
              typeof basedOn === 'string'
                ? <span className={relationshipsClasses.basedOn.value}>{basedOn}</span>
                : <span className={relationshipsClasses.basedOn.value}>{t('release_centre.based_on_release', { num: basedOn})}</span>
            }
          </div>

          <div
            className={clsx(
              relationshipsClasses.promotedAs._,
              !promotedAs && relationshipsClasses.promotedAs.hidden,
            )}
          >
            <span className={relationshipsClasses.label}>
              <Trans i18nKey="release_centre.promoted_as"></Trans>:
              {' '}
              {promotedAs || ''}
            </span>

          </div>
        </div>

        <SecondaryButton size='small' onClick={openRelease}>
          <Trans i18nKey="view_label"></Trans>
        </SecondaryButton>


        <Dialog open={modalContents !== null} onClose={resetModalContents}>
          {modalContents}
        </Dialog>
      </div>
    </ReleaseCard>
  );
};


// ===================================================================================

const Wrap = styled.div``;


/**
  @typedef {{
    id: string;
    projectName: string;
  }} IProps
 */

// ===================================================================================

const ResourceLoadingWrap = styled(ReleaseCard)`
  .${classesRC.topRow.texts._} {
    width: 100%;
  }

  .${classesRC.middleRow.tag} {
    width: 75%;
    flex-grow: initial;
  }

  .${classesRC.middleRow.lastModified._} {
    flex-grow: 1;
  }

  .${relationshipsClasses._} {
    width: 20%;
  }
`;

export const ResourceLoading = React.memo(() => (
  <ResourceLoadingWrap>
    <div className={classesRC.topRow._}>
      <div className={classesRC.topRow.texts._}>
        <Skeleton style={{ width: '40%', height: 26 }} />
        <Skeleton style={{ width: '70%' }} />
      </div>
    </div>

    <div className={classesRC.middleRow._}>
      <div className={classesRC.middleRow.tag}>
        <Skeleton style={{ width: '80%' }} />
      </div>
      <div className={classesRC.middleRow.lastModified._}>
        <p className={classesRC.middleRow.lastModified.date}>
          <Skeleton />
        </p>
        <p className={classesRC.middleRow.lastModified.desc}>
          <Skeleton />
        </p>
      </div>
    </div>

    <div className={classesRC.bottomRow._}>
      <div className={relationshipsClasses._}>

        <div className={relationshipsClasses.basedOn._}>
          <Skeleton />
        </div>

        <div className={clsx(relationshipsClasses.promotedAs._)}>
          <Skeleton />
        </div>
      </div>

      <Skeleton style={{
        width: '20%',
        height: 30,
        transform: 'scale(1)',
      }}
      />
    </div>
  </ResourceLoadingWrap>
));
ResourceLoading.displayName = 'ProjectsReleaseCenter/ResourceLoading';

// ===================================================================================

/** @type { (i: number) => JSX.Element } */
const skeleton = i => <ResourceLoading key={i} />;

const defaultReleaseInfo = {
  test: { list: { ids: [] } },
  prod: { list: { ids: [] } },
};

/**
  @type {
    ( projectId: string, list: 'test' | 'prod' ) => {
      isLoaded: boolean;
      canLoadMore: boolean;
      loadedOnce: boolean;
    }
  }
 */
const useReleaseListMeta = (projectId, list) => {
  const maybeListInfo = useSelector(s => reduxNS.extractReleaseList(s, projectId, list));

  return React.useMemo(() => {
    if (maybeListInfo === undefined) return { canLoadMore: false, isLoaded: false, loadedOnce: false };

    return {
      isLoaded: maybeListInfo.loading === false,
      canLoadMore: maybeListInfo.ids.length < maybeListInfo.total,
      loadedOnce: maybeListInfo.loadedOnce,
    };
  }, [maybeListInfo]);
};


/** @type { React.FC< IProps > } */
const ProjectsReleaseCentre = React.memo(({ id: projectId, projectName }) => {
  const dispatch = useDispatch();
  // const renderItem = React.useCallback(r => <ReleaseCardComp key={r.id} release={r} />, []);
  /** @type { Boolean } */
  const isLoaded = useSelector(s => (/** @type { any } */(s)).loading === false);
  const { t } = useTranslation();
  const [importReleaseModalOpen, setImportReleaseModalOpen] = React.useState(false);
  const closeImportReleaseModal = React.useCallback(
    () => setImportReleaseModalOpen(false),
    [setImportReleaseModalOpen],
  );
  const openImportReleaseModal = React.useCallback(
    () => setImportReleaseModalOpen(true),
    [setImportReleaseModalOpen],
  );

  /** @type { ( r: ReleaseInResources ) => Release } */
  const releaseInResourcesToRelease = it => ({
    id: it.id,
    isCurrent: it.activeVersionFlag,
    lastModifiedAt: (() => {
      const m = moment(it.lastModified);
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

      return `${m.format('DD/MM/YYYY HH:mm:ss')} (${tz})`;
    })(),
    no: it.releaseNo,
    project: it.model,
    basedOn: it.basedOn ?? t('release_centre.initial'),
    promotedAs: it.promotedAs,
    env: it.env,
    description: it.description,
    locale: it.locale,
  });

  /** @type { Record< string, ReleaseInResources > } */
  const allReleases = useSelector(s => /** @type { any } */(s).resources.releases.data);
  /**
   * @type { {
   *  test: { list: { ids: string[] } },
   *  prod: { list: { ids: string[] } }
   * } }
   */
  const {
    prod: { list: { ids: prodIds } },
    test: { list: { ids: testIds } },
  } = useSelector(s => /** @type { any } */(s).resources.releases[projectId] || defaultReleaseInfo);


  const testListMeta = useReleaseListMeta(projectId, 'test');
  const loadMoreTestReleases = React.useCallback(() => {
    dispatch(reduxNS.aCreators.requestToLoadMoreReleases({ componentId: 'test', projectId }));
  }, [dispatch, projectId]);

  const prodListMeta = useReleaseListMeta(projectId, 'prod');
  const loadMoreProdReleases = React.useCallback(() => {
    dispatch(reduxNS.aCreators.requestToLoadMoreReleases({ componentId: 'prod', projectId }));
  }, [dispatch, projectId]);

  const currentProjectTestReleases = React.useMemo(
    () => Object.values(allReleases)
      .filter(it => testIds.includes(it.id))
      // .sort((a, b) => Number(new Date(a.lastModified)) - Number(new Date(b.lastModified)))
      .map(releaseInResourcesToRelease),
    [allReleases, testIds],
  );
  const currentProjectProdReleases = React.useMemo(
    () => Object.values(allReleases)
      .filter(it => prodIds.includes(it.id))
      // .sort((a, b) => Number(new Date(a.lastModified)) - Number(new Date(b.lastModified)))
      .map(releaseInResourcesToRelease),
    [allReleases, prodIds],
  );
  const devHasOnlyOneRelease = currentProjectTestReleases.length === 1;

  /** @type { ( r: Release ) => JSX.Element } */
  const renderDevRelease = React.useCallback(
    r => <ReleaseCardComp key={r.id} release={r} isLastOne={devHasOnlyOneRelease || undefined} projectName={projectName} />,
    [devHasOnlyOneRelease, projectName],
  );
  /** @type { ( r: Release ) => JSX.Element } */
  const renderProdRelease = React.useCallback(
    r => <ReleaseCardComp key={r.id} release={r} projectName={projectName} />,
    [projectName],
  );

  // const dropRelease = React.useCallback(/** @type { Pick< Release, 'id' | 'env' > } */r => {
  //   console.log(r);
  // }, [dispatch]);

  // const [{ canDropDev, isOverDev }, dropDev] = useDrop(() => ({
  //   accept: DND_RELEASE_CARD_TYPE,
  //   collect: monitor => ({
  //     canDropDev: monitor.canDrop(),
  //     isOverDev: monitor.isOver(),
  //   }),
  //   drop: dropRelease,
  //   canDrop: item => item.env === 'prod',
  // }));

  // const [{ canDropProd, isOverProd }, dropProd] = useDrop(() => ({
  //   accept: DND_RELEASE_CARD_TYPE,
  //   collect: monitor => ({
  //     canDropProd: monitor.canDrop(),
  //     isOverProd: monitor.isOver(),
  //   }),
  //   drop: dropRelease,
  //   canDrop: item => item.env === 'test',
  // }));

  // ref={dropDev}
  // style={{
  //   ...(canDropDev ? { backgroundColor: BG_COLOR_FOR_WHEN_DND_IS_ALLOWED } : {}),
  //   ...(canDropDev && isOverDev ? { backgroundColor: BG_COLOR_FOR_WHEN_DND_IS_OVER } : {}),
  // }}

  // ref={dropProd}
  // style={{
  //   ...(canDropProd ? { backgroundColor: BG_COLOR_FOR_WHEN_DND_IS_ALLOWED } : {}),
  //   ...(canDropProd && isOverProd ? { backgroundColor: BG_COLOR_FOR_WHEN_DND_IS_OVER } : {}),
  // }}


  return (
    <Wrap>
      <ReleaseManagerGuard>
        <ImportReleaseButton type='primary' onClick={openImportReleaseModal}>
          <Trans i18nKey="release_centre.import_release"></Trans>
        </ImportReleaseButton>
      </ReleaseManagerGuard>

      <Dialog
        fullWidth
        maxWidth='sm'
        open={importReleaseModalOpen}
        onClose={closeImportReleaseModal}
      >
        <ImportReleaseModal._ project={projectId} close={closeImportReleaseModal} />
      </Dialog>


      { /** =================================================== */}

      <Box display='flex' flexDirection='column' gridGap='1.5rem'>
        <GridList
          title={t('release_centre.development_environment')}
          gap='1rem'
          items={testListMeta.loadedOnce ? currentProjectTestReleases : []}
          renderItem={renderDevRelease}
          minWidth='20rem'
          renderSkeleton={skeleton}
          isLoaded={testListMeta.loadedOnce}
        />

        {testListMeta.canLoadMore && (
          <SecondaryButton
            disabled={testListMeta.isLoaded === false}
            onClick={loadMoreTestReleases}
            style={{ alignSelf: 'flex-start' }}
          >
            {testListMeta.isLoaded === false ? LoadingDotsJSX : 'Load more'}
          </SecondaryButton>
        )}
      </Box>

      <br />

      <Box display='flex' flexDirection='column' gridGap='1.5rem'>
        <GridList
          title={t('release_centre.production_environment')}
          gap='1rem'
          items={prodListMeta.loadedOnce ? currentProjectProdReleases : []}
          renderItem={renderProdRelease}
          minWidth='20rem'
          renderSkeleton={skeleton}
          isLoaded={prodListMeta.loadedOnce}
          noItemsJSX={<Typography variant='body1'><Trans i18nKey="release_centre.no_release_prod_label"></Trans></Typography>}
        />


        {prodListMeta.canLoadMore && (
          <SecondaryButton
            disabled={prodListMeta.isLoaded === false}
            onClick={loadMoreProdReleases}
            style={{ alignSelf: 'flex-start' }}
          >
            {prodListMeta.isLoaded === false ? LoadingDotsJSX : t('load_more')}
          </SecondaryButton>
        )}
      </Box>
    </Wrap>
  );
});

export const useOpenReleaseCenter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (project) => {
    dispatch(populateProjectReleaseCentreNS.aCreators.requestToPopulateProjectReleaseCentre({ projectId: project.id }));
    dispatch(
      showModal(
        {
          open: true,
          maxWidth: 'lg',
          title: t('release_centre.title_for_label', { name: project.name}),
          description: t('release_centre.description_label'),
        },
        <ProjectsReleaseCentre id={project.id} projectName={project.name} />,
      ),
    );
  };
};
