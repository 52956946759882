import React, { useMemo } from 'react';
import get from 'lodash/get';
import { countDecimals, getNodeDisplayData, getNodeValue, roundToMaxDigitsAfterDot } from '@common';
import { getNameFromFileAttributeRef } from '@decisively-io/interview-sdk';
import TemporalValueRender from '@components/TemporalValue';
import { Label } from '@components';
import styled from 'styled-components';
import { Tooltip, useTheme } from '@material-ui/core';
import { useGraphVisualisation } from '@components/GraphVisualisation/hooks/useGraphVisualisation';

import moment from 'moment';
import { DownloadFileButton } from './DownloadFileButton';
import { scrollableMixin } from '@common';

const StyledLabel = styled(Label)`
  margin-left: 5px;
  margin-right: 5px;
`;

const StyledDownloadFileButton = styled(DownloadFileButton)`
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.375rem;
  margin-left: 0.5rem;
`;

/**
 * we need this to proerly style file values in right-hand sidebar, \
 * because as opposed to rule graph nodes, where it takes one whole \
 * line and other elements have somespacig, in right-hand sidebar we \
 * have 'Value' heading and a possible 'input' chip, which are really\
 * close one to another
 */
export const fileRefsWrapClsnm = 'file_refs_wrap_r7y3Ylr';
const FileRefsWrap = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow: auto;
  max-width: 100%;

  ${scrollableMixin};
  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
  }
`;

// eslint-disable-next-line complexity
const NodeValue = ({ node, showType = true, hideTemporal = false, temporalType = "bar", labelProps = {} }) => {
  let value;
  let display;
  let tooltip;
  const theme = useTheme();
  const { temporalSlice } = useGraphVisualisation();

  const labelColor = () => {
    if (display === 'Unknown' || display === 'Uncertain') return theme.palette.secondary.light;
    if (typeof value === 'boolean') {
      return value ? theme.labels.public : theme.labels.Author;
    }
    if ((typeof value === 'string' || typeof value === 'number' || (value && value.fileRefs)) && value !== 'Entity' && value !== 'Undefined' && value !== 'uncertain') {
      return theme.labels.public;
    }

    return theme.palette.secondary.light;
  };

  if (typeof node.derived !== 'undefined' || typeof node.input !== 'undefined') {
    value = getNodeValue(node);

    if (typeof value === "string" && node.type === "date") {
      display = value.replace(/T.*$/, "");
    }

    if (typeof value === 'undefined') display = 'Unknown';
    else if (typeof value === 'number') {
      display = roundToMaxDigitsAfterDot(value);
      if (countDecimals(value) > 4) tooltip = value;
    } else if (value === null) display = 'Uncertain';
    else if (value.temporal) {
      if (hideTemporal) return <Label color={theme.labels.public}>Temporal</Label>
      else if (temporalSlice) {
        display = getValueAtDate(value.temporal, temporalSlice);
        tooltip = `Displaying temporal value at ${moment(temporalSlice).format('YYYY-MM-DD')}`
      }
      else return <TemporalValueRender type={temporalType} node={node} value={value} />;
    } else display = value;
  } else display = 'Unknown';


  const renderFileLabel = (ref, index = 0) => {
    const match = ref.match(/^data:id=(.*);base64,([-A-Za-z0-9+/=]*)$/);
    const nameRaw = match ? getNameFromFileAttributeRef(ref) : value;
    const name = nameRaw.length <= 16 ? nameRaw : nameRaw.slice(0, 16) + '…';
    return (
      <Tooltip title={nameRaw || ''}>
        <StyledLabel color={labelColor()} style={{ marginLeft: 5, marginRight: 5 }} {...labelProps} key={index} >
          {String(name)}
          <StyledDownloadFileButton reference={ref} hideName={true} />
        </StyledLabel>
      </Tooltip>
    )
  }

  const renderLabel = () => {
    const maybeFileRefs = (() => {
      if (typeof value === 'string' && value.startsWith('data:')) return [value];
      if (value && Array.isArray(value.fileRefs)) return value.fileRefs;

      return null;
    })();

    if (Array.isArray(maybeFileRefs)) {
      return (
        <FileRefsWrap className={fileRefsWrapClsnm}>
          {maybeFileRefs.map(renderFileLabel)}
        </FileRefsWrap>
      );
    }

    return (
      <StyledLabel color={labelColor()} {...labelProps}>
        {String(display)}
      </StyledLabel>
    );

    // if (typeof value === 'string' && value.startsWith('data:') ) {
    //   return renderFileLabel(value);
    // } else if (Array.isArray(value.fileRefs)) { // assume it's a file value
    //   return (
    //     <>
    //       {value.fileRefs.map(renderFileLabel)}
    //     </>
    //   )
    // } else {

    // }
  }

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <span>
            <StyledLabel color={labelColor()} {...labelProps}>
              <span style={{ textDecoration: 'underline dashed' }}>{display}</span>
            </StyledLabel>
            {showType && typeof node.input !== 'undefined' && <StyledLabel color="lightgray">Input</StyledLabel>}
          </span>
        </Tooltip>
      ) : (
        <>
          {renderLabel()}
          {showType && typeof node.input !== 'undefined' && <StyledLabel color="lightgray">Input</StyledLabel>}
        </>
      )}
    </>
  )
};

export default NodeValue;
