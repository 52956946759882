import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import cn from "clsx";
import clsx from "clsx";
import stl from "./GraphNode.module.scss";
import { ControlIcons } from "../../../pages/models/release/Interview/Designer/components/ControlIcon";
import { LightningIcon, TasksIcon, CommsIcon, EnrichmentIcon, EmailIcon, PhoneIcon } from "../../../icons";
import { withStyles } from "@common/Styling";
import { graphNodeStyles } from "@components/RuleGraph_FullRelease/components/GraphNode";
import { Handle, Position } from "reactflow";
import type { GraphViewNodeProps } from "../../GraphView/GraphView";
import { getAttributeIconAsComponentType } from "../../../common";
import { useParsedGraph } from "@pages/models/release/GraphContext";
import { FlowStep, FlowTrigger } from "@components/flow/DecisionFlowUtils";
import SelectDecisive, { RequiredDecisiveValue } from "../../select/SelectDecisive";
import { DecisionFlowGraphContext } from "@components/flow/DecisionFlowGraph";
import { TriggerType } from "@packages/commons";

interface TriggerNodeProps<N = any> extends GraphViewNodeProps<N> {}

{
  /** TODO probably automate picking out the values and the attribs inside here... */
}
const ConditionText = (props: React.PropsWithChildren<{}>) => {
  return (
    <Typography
      variant="caption"
      className={cn(stl.condition_text, stl.mb_0_5)}
    >
      &raquo; {props.children}
    </Typography>
  );
};

const SeparatorBar = (props: {}) => {
  return (
    <div className={stl.separator_bar}>
      <span className={stl.separator_bar_text}>+</span>
    </div>
  );
};

interface TriggerConditionsProps {
  trigger: FlowTrigger;
  id: string;
  children: React.ReactNode;
  triggerInputId: string;
}

const TriggerConditions = (props: TriggerConditionsProps) => {
  const { trigger, id, triggerInputId, children } = props;

  return (
    <div className={cn(stl.trigger_section, stl.mb_0_5)}>
      <Handle
        // entry
        type={"target"}
        position={Position.Left}
        itemID={triggerInputId}
        // nodeId={triggerInputId}
      />

      <div className={stl.col_lhs}>
        <LightningIcon
          style={{
            marginLeft: "-7px",
          }}
        />
      </div>

      <div className={stl.col_rhs}>
        {/* <div
          className={stl.content_row}
        >
          <ControlIcons.boolean />
          <Typography
            className={cn(stl.trigger_txt, stl.ml_0_5)}
          >
            The <span className={stl.attribute}>'passport verification'</span> is required
          </Typography>
        </div> */}
        {children}
      </div>
    </div>
  );
};

interface TriggerSectionProps {
  trigger: FlowTrigger;
  id: string;
}

const TRIGGER_TYPE_ICONS: Record<TriggerType, React.ComponentType<any>> = {
  "task": TasksIcon,
  "comms": CommsIcon,
  "enrichment": EnrichmentIcon,
  "integration": EnrichmentIcon
}

const TRIGGER_TYPE_NAMES: Record<TriggerType, string> = {
  "task": "Task",
  "comms": "Communication",
  "enrichment": "Enrichment",
  "integration": "Integration"
}

const TriggerSection = (props: TriggerSectionProps) => {
  const { trigger, id } = props;

  const parsedGraph = useParsedGraph();

  const inputNode = parsedGraph.node(trigger.input);

  const booleanValue = inputNode.value?.value;

  return (
    <div>
      {trigger.actions?.length ? <ConditionText>
        When the following is <span className={stl.value}>{`${booleanValue}`}</span>
      </ConditionText> : null}

      {/* <div>
        <Switch label={"Required"} checked={trigger.required}/>
        <Switch label={"Decisive"} checked={trigger.decisive}/>
      </div> */}

      <TriggerConditions
        trigger={trigger}
        id={id}
        triggerInputId={trigger.input || id}
      >
        <div className={stl.content_row}>
          <ControlIcons.boolean />
          <Typography className={cn(stl.trigger_txt, stl.ml_0_5)}>{inputNode.description}</Typography>
        </div>
      </TriggerConditions>
    </div>
  );
};

export interface OutcomeSectionProps {
  trigger: FlowTrigger;
  onRequiredDecisiveChange?: (step: FlowStep, trigger: FlowTrigger, value: RequiredDecisiveValue) => void;
  step: FlowStep;
}

const OutcomeSection = (props: OutcomeSectionProps) => {
  const { trigger, step, onRequiredDecisiveChange } = props;
  const { actions, required, decisive } = trigger;
  const parsedGraph = useParsedGraph();

  const actionsWithOutcome = actions.filter((action: any) => action.outcome);
  if (!actionsWithOutcome.length) {
    return null;
  }

  return (
    <div className={stl.requirement_section}>
      <div className={stl.wrap_select_decisive}>
        <SelectDecisive
          complex={trigger.complex}
          value={{ decisive, required }}
          onChange={(value) => onRequiredDecisiveChange?.(step, trigger, value)}
        />
      </div>

      {/* <div
        className={stl.header_row}
      >
      <div
        className={stl.outcome_icon}
      >
        <LibraryBooksIcon
          style={{
            width: "20px",
          }}
        />
      </div>
      <Typography
        className={stl.outcome_txt}
      >
        {description}
      </Typography>
    </div> */}

      {/* <Typography
        variant="caption"
        className={stl.requirement_description_txt}
      >
        Verification of passport details are sent to external systems for validation
      </Typography> */}

      <Typography
        // className={cn(stl.subtitle_txt, stl.mt_1)}
        className={cn(stl.subtitle_txt)}
        style={{ marginBottom: "0.1rem" }}
      >
        Task outcome:
      </Typography>

      <div className={stl.outcome}>
        {(actionsWithOutcome || []).map((action: any) => {
          if (!action.outcome) {
            return null;
          }
          // console.log("action node", action);
          const node = parsedGraph.node(action.outcome);
          if (!node) {
            // could happen whilst you're mid-build
            console.warn("No node found for action", action);
            return null;
          }

          const taskNode = parsedGraph.node(action.task);
          // console.log("task node", taskNode); <---- this is always undefined...
          const IconComponent =TRIGGER_TYPE_ICONS[action.type as TriggerType] || ControlIcons.unassigned!;

          return (
            <div
              key={node.id}
              className={stl.content_row}
            >
              <IconComponent />
              <Typography className={cn(stl.outcome_txt, stl.ml_0_5)}>{node.description || ""}</Typography>
            </div>
          );
        })}
        {/* <div
          className={stl.content_row}
        >
          <div
            className={stl.outcome_icon}
          >
            <ControlIcons.boolean />
          </div>
          <Typography
            className={cn(stl.outcome_txt, stl.ml_0_5)}
          >
            The <span className={stl.attribute}>'passport'</span> has been verified
          </Typography>
        </div> */}
      </div>
    </div>
  );
};

const TriggerNode = (props: TriggerNodeProps & { classes?: any }) => {
  const decisionFlowGraphState = useContext(DecisionFlowGraphContext);

  const { isDragging, classes, data, ...otherProps } = props;

  const stylingClass = "source"; // "intermediate" | "goal" | "source" | "byValue"

  const renderTypeIcon = () => {
    const nodeType = containmentNode?.type;
    const IconComponent_ = (ControlIcons as any)[nodeType] || ControlIcons.unassigned!;
    const IconComponent = getAttributeIconAsComponentType((nodeType || "") as any, IconComponent_);

    // if (ControlIcons.unassigned === IconComponent_) {
    //   console.warn(`Unassigned icon for node type: ${nodeType}`);
    // }

    return (
      <div className={cn(stl.type_icon, stl[stylingClass])}>
        <IconComponent />
      </div>
    );
  };

  const parsedGraph = useParsedGraph();
  const step = data.node as FlowStep;
  const containmentNode = parsedGraph.node(step.containment);

  // console.log("trigger node", data.node);

  return (
    <div className={cn(props?.classes?.root, stl.container, stl[stylingClass], { [stl.dragging]: isDragging })}>
      {/* header */}
      <div
        className={cn(
          stl.col_container,
          stl[stylingClass],
          // stl["byValue"],
        )}
        style={{
          position: "relative",
        }}
      >
        <div className={stl.col_lhs}>{renderTypeIcon()}</div>
        <div className={cn(stl.col_rhs)}>
          {containmentNode ? containmentNode.description || "" : ""}
          {/* {
            (includeContextMenu)
              ?
              <GraphNodeContextMenu
                node={node}
                isGoal={true}
                isTargetNode={isTargetNode}
              />
              : null
          } */}
        </div>

        <Handle
          // exit
          type={"source"}
          position={Position.Right}
          itemID={containmentNode?.id}
          // nodeId={containmentNode?.id}
        />
      </div>

      {/* body */}
      {data.node.triggers.map((trigger) => {
        const actionsWithOutcome = trigger.actions.filter((action: any) => action.outcome);
        return (
          <div
            key={trigger.input}
            className={clsx(stl.decision_flow_section, classes?.root)}
            {...otherProps}
          >
            <div className={stl.container}>
              <TriggerSection
                id={step.id}
                trigger={trigger}
              />


              {actionsWithOutcome?.length ? <>
                  <ConditionText>And these actions are completed</ConditionText>
                <OutcomeSection
                  step={step}
                  onRequiredDecisiveChange={decisionFlowGraphState.setRequiredDecisive}
                  trigger={trigger}
                />
              </> : <>
                <ConditionText>The following will be triggered</ConditionText>
                {trigger.actions.map((action: any, index) => {
                  if (action.outcome) {
                    return null;
                  }

                  let IconComponent =TRIGGER_TYPE_ICONS[action.type as TriggerType] || ControlIcons.unassigned!;


                  if (action.type === "comms") {
                    IconComponent = action.method === "email" ? EmailIcon : PhoneIcon;
                  }

                  return (
                    <div
                      key={index}
                      className={cn(stl.action_no_outcome)}
                      style={{
                        marginLeft:0
                      }}
                    >
                      <IconComponent/>
                      <span>{TRIGGER_TYPE_NAMES[action.type as TriggerType]}</span>
                    </div>
                  )

                })}
              </>}

              <SeparatorBar />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withStyles(graphNodeStyles)(TriggerNode);
