import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { default as MaterialSwitch, SwitchProps as MaterialSwitchProps } from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const Label = withStyles(() => ({
  root: {
    margin: "0 !important",
    gap: "1rem", // not supported in ie
  },
}))(FormControlLabel);

export interface SwitchProps extends MaterialSwitchProps {
  label?: string;
  labelPlacement?: "start" | "end" | "top" | "bottom";
  labelStyle?: React.CSSProperties;
}

export const Switch = (props: SwitchProps) => {
  const { label, labelPlacement, labelStyle, ...rest } = props;
  const labelProps = { label, labelPlacement, style: labelStyle };
  if (rest.readOnly) {
    // because: https://github.com/mui/material-ui/issues/19571
    delete rest.onChange;
  }

  return <Label {...labelProps} control={<MaterialSwitch {...rest} />}/>;
};
