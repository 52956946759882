import { useParsedGraph } from "@pages/models/release/GraphContext";
import { FlowStep, FlowTrigger, generateFlowGraph } from "@components/flow/DecisionFlowUtils";
import styled from "styled-components";
import GraphView from "@components/GraphView/GraphView";
import DecisionFlowNode from "@components/flow/DecisionFlowNode";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { RequiredDecisiveValue } from "@components/select/SelectDecisive";
import { graphlib } from "dagre";
import { Box } from "@mui/material";

const Container = styled.div`
  height: 100%;
  width: 100%;
  //border-top: 1px solid ${(p) => p.theme.palette.background.border};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export interface DecisionFlowHandle {
  setRequiredDecisive?: (step: FlowStep, trigger: FlowTrigger, value: RequiredDecisiveValue) => void;
  flowGraph: graphlib.Graph;
  optimisticFlowGraphUpdate: (update: graphlib.Graph) => void;
}

export const DecisionFlowGraphContext = React.createContext<DecisionFlowHandle>(undefined as any);

export interface DecisionFlowGraphProps {
  sectionId?: string;
  style?: React.CSSProperties;
  noDataStyle?: React.CSSProperties;
  onRequiredDecisiveChange?: (step: FlowStep, trigger: FlowTrigger, value: RequiredDecisiveValue) => void;
}

const DecisionFlowGraph = React.forwardRef((props: DecisionFlowGraphProps, ref: any) => {
  const { sectionId, onRequiredDecisiveChange, noDataStyle, style, ...otherProps } = props;

  const graph = useParsedGraph();

  const flowGraph = generateFlowGraph(graph, sectionId);
  const [optimisticFlowGraph, setOptimisticFlowGraph] = useState<graphlib.Graph | undefined>(undefined);

  useEffect(() => {
    setOptimisticFlowGraph(undefined);
  }, [graph]);

  const handle = {
    setRequiredDecisive: onRequiredDecisiveChange,
    flowGraph: optimisticFlowGraph ?? flowGraph,
    optimisticFlowGraphUpdate: (update: graphlib.Graph) => {
      setOptimisticFlowGraph(update);
    },
  } satisfies DecisionFlowHandle;
  useImperativeHandle(ref, () => handle, [handle]);

  console.log(flowGraph.nodes());

  const currentFlowGraph = optimisticFlowGraph ?? flowGraph;

  // console.log("currentFlowGraph", currentFlowGraph);

  return (
    <Container
      style={{
        ...style,
        ...(flowGraph?.nodes().length === 0 ? noDataStyle : {}),
      }}
      {...otherProps}
    >
      <DecisionFlowGraphContext.Provider value={handle}>

        <GraphView
          rankDir={"LR"}
          //refitKey={graph.id}
          nodeHeight={600}
          graph={currentFlowGraph}
          loading={Boolean(!graph || !flowGraph)}
          nodeComponent={DecisionFlowNode}
          flowGraphProps={{
            defaultEdgeOptions: {
              type: "smoothstep",
              animated: false,
            },
          }}
        >
          {/* <GraphPallet /> TODO ds come back to this - trying to avoid needing to wrap this in a CP..rather need to decouple the CP from the GraphPallet */}
        </GraphView>
      </DecisionFlowGraphContext.Provider>
      {currentFlowGraph.nodes().length ? null : <Box position={"absolute"} sx={{
        inset: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        No flows to display
      </Box>}
    </Container>
  );
});

export default DecisionFlowGraph;
