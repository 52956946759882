import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import GoalMenu from "@pages/models/release/GoalMenu";
import { useParsedGraph } from "@pages/models/release/GraphContext";
import { useRelease } from "@pages/models/release/ReleaseContextProvider";
import { CloseIcon } from "@icons";

export interface HighlightConnectedToRootModal {
  open: boolean;
  onClose: () => void;
}

const HighlightConnectedToRootModal = (props: HighlightConnectedToRootModal) => {
  const { open, onClose } = props;
  const graph = useParsedGraph();
  const { setHighlightConnectedTo, highlightConnectedTo } = useRelease();

  return (
    <Dialog open={open} onClose={onClose}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "2rem",
          paddingBottom: "2rem",
          minWidth: 550,
        }}
      >
        <Typography>Highlight any nodes that are not connected to:</Typography>
        <GoalMenu
          goalId={highlightConnectedTo}
          onGoalIdChange={setHighlightConnectedTo}
          allowEmpty={true}
          includeDerived={false}
          includeInputs={false}
          graph={graph}
        />
      </DialogContent>
    </Dialog>
  );
};

export default HighlightConnectedToRootModal;
