import cn from 'clsx';
import { forwardRef } from 'react';

import { isMod } from '@components/kdb';
import { Stack } from '@components/meta';
import Tooltip from '@material-ui/core/Tooltip';
import { AttributeUsage } from '@pages/models/release/DataModel/route/Attributes/AttributeUsage';

import { HighlightedText } from '../common/HighlitedText';
import { SearchResult } from './Search.types';
import stl from './SearchAndSelectResultItem.module.scss';
import { Attribute } from '@components/AttributeSummaryCard/AttributeSummaryCard';

export interface SearchAndSelectResultItemProps {
  result: SearchResult;
  additionalInfo?: {
    entityName?: string;
    type?: string;
    matchingText?: string;
    highlightStr?: string;
  };
  onDocumentOpen?: () => void;
}

const SearchAndSelectResultItem = forwardRef<HTMLDivElement, SearchAndSelectResultItemProps>((props, ref) => {
  const { result: { original }, additionalInfo } = props;

  const onDocumentClick = (e: React.MouseEvent, doc: string) => {
    // if (props.onDocumentOpen) {
    //   const newWindow = isMod(e);
    //   props.onDocumentOpen(doc, newWindow);
    //   return true; // we are doing custom override
    // }
    props.onDocumentOpen?.();
  };

  const resultContainer = () => {
    return (
      <div className={stl.containerResult}>
        <Stack gridGap="0.5rem" px="1rem" py="0.5rem">
          <Tooltip disableFocusListener title={additionalInfo?.matchingText || ""}>
            <div className={cn(stl.attributeValue, stl.clamp3Lines)}>
              <HighlightedText
                text={additionalInfo?.matchingText || ""}
                highlight={additionalInfo?.highlightStr || ""}
              />
            </div>
          </Tooltip>
          <AttributeUsage noDuplicates node={original} onClick={onDocumentClick} />
        </Stack>
        <Attribute node={original} />
      </div>
    );
  };

  return (
    <div ref={ref} className={stl.container}>
      {resultContainer()}
    </div>
  );
});

export default (SearchAndSelectResultItem);
