import React from "react";
import { Handle, Position, useStore } from "reactflow";
import { withStyles } from "../../../common/Styling";
import { TargetIcon } from "@icons";
import cn from "clsx";
import { ControlIcons } from "../../../pages/models/release/Interview/Designer/components/ControlIcon";
import find from "lodash/find";
import GraphNodeContextMenu from "./GraphNodeContextMenu";
import GraphNodeBar from "./GraphNodeBar";
import { useGraphVisualisation } from "../../GraphVisualisation/hooks/useGraphVisualisation";
import NodeValue from "../../GraphVisualisation/components/AttributeInfo/NodeValue";
import { Tooltip } from "@material-ui/core";
import type { ControlType } from "@decisively-io/interview-sdk";
import { getAttributeIconAsComponentType, getContainedBy, getIndexForNode } from "@common";
import GraphNodeCountEar from "./GraphNodeCountEar";
import { GraphViewNodeProps } from "@components/GraphView/GraphView";
import { ParsedRuleGraph } from "@packages/commons";

import stl from "./GraphNode.module.scss";

export const defaultTypeFunc = (node: any, graph: any) => {
  // intermediate is the safest default, as you'll have both types of connector, and won't miss out on drawing an edge
  if (!graph) return "intermediate";

  if ((graph.predecessors(node.id) || []).length === 0) {
    return "goal";
  }
  if ((graph.successors(node.id) || []).length > 0) {
    return "intermediate";
  }
  if (node.conditions || node.value || node.rows) {
    // These are nodes that have a default value (as source nodes have no calculations on them)
    return "intermediate";
  }

  // const edges = find(graph.edges(), { target: node.id });
  // console.log("++++++++++++++++++ node", node);
  // console.log("++++++++++++++++++ edges", edges);

  return "source";
};


export const byValueTypeFunc = () => "byValue";

export interface GraphNodeProps extends GraphViewNodeProps  { classes?: any; graph?: ParsedRuleGraph, horizontal?: boolean; }

const GraphNode = (props: GraphNodeProps) => {

  const {
    id,
    data,
    includeContextMenu = true,
    xPos,
    yPos,
    isDragging,
    isDecisionFlow,
    graph: propsGraph,
    horizontal,
  } = props;
  const node = data.node;
  const nodeId = node?.id;
  const nodePath = node?.path;
  const nodeType = (node?.type || "") as ControlType;
  const {
    colorScheme,
    entityColorMapping,
    graph: graphVizGraph,
    setRestrictedViewNodesToMerge,
    debug,
    restrictedView,
  } = useGraphVisualisation();
  const graph = propsGraph || graphVizGraph;

  // const searchMatchId = useSearchMatchCtx();
  // const className = React.useMemo(
  //   () => (searchMatchId === nodeId ? SelectedSearchNodeClsnm : undefined),
  //   [searchMatchId, nodeId],
  // );
  let indexInfo: any = getIndexForNode(node, graph) || {};
  let nodeIndex = "";
  if (indexInfo) {
    nodeIndex = indexInfo.value;
  }
  const entityName = node?.entity || "";
  const isGlobalEntity = entityName === "global";

  const graphNodeType = defaultTypeFunc(node, graph);

  let nodeClass: string = graphNodeType;

  let hasConnectorTop = graphNodeType === "intermediate" || graphNodeType === "source";
  let hasConnectorBottom = isDecisionFlow ? true : graphNodeType === "intermediate" || graphNodeType === "goal"; // TODO ds refine this, we might not always need the connector but we need to see the flow graph
  const isValueNode = debug;
  const isTargetNode = (() => {
    if (restrictedView?.rootPath) {
      if (nodePath) {
        return (nodePath === restrictedView?.rootPath);
      }
      return (nodeId === restrictedView?.rootPath);
    }

    return (false);
  })();

  // -- value (debugger) node specific
  const edgesStore = useStore(
    (state) => state.edges,
  );
  if (isValueNode) {
    const edges = edgesStore;
    const parents = find(edges, { target: id });
    const children = find(edges, { source: id });
    const value = typeof node.input === "undefined"
                ? node.derived
                : node.input;

    hasConnectorTop = !!parents;
    hasConnectorBottom = !!children;

    if (colorScheme === "by-value") {
      // we twiddle the nodeClass here slightly too in order to get the right color
      if (value === false) {
        nodeClass = "falsy";
      } else if (value === null) {
        nodeClass = "unknown";
      } else if (typeof value === 'undefined') {
        nodeClass = "uncertain";
      } else {
        nodeClass = "truthy";
      }
    }
    // TODO I'm pretty sure this color scheme makes no sense on a debug graph, so let's remove it if everyone agrees
    // else if (colorScheme === "by-type") {
    //   // we won't know the "type" as the nodeClass will have been set as "byValue" here, so we need to have a reasonable guess
    //   if (hasConnectorTop && hasConnectorBottom) {
    //     nodeClass = "intermediate";
    //   } else if (hasConnectorBottom) {
    //     nodeClass = "goal";
    //   } else {
    //     nodeClass = "source";
    //   }
    // }
  }

  /** the by-entity color scheme trumps everything */
  if (colorScheme === "by-entity") {
    const entityColor = entityColorMapping.find(mapping => mapping.entity === entityName);
    if (entityColor) {
      nodeClass = entityColor.colorKey.replace("--value-color-", "");
    }
  }

  // -- rendering

  const entityFlag = () => {

    let cleanIndex = (nodeIndex && nodeIndex.length > 8) ? `${nodeIndex.substring(0, 8)}...` : nodeIndex;
    let contained = getContainedBy(node.path, graph);
    const idLabel = indexInfo.hint ? indexInfo.hint : nodeIndex;

    const content = (
      <div className={stl.entity_flag}>
        {entityName}{cleanIndex ? `:${cleanIndex}` : ''}
      </div>
    );

    if (isGlobalEntity) {
      return (content);
    }

    return (
      <Tooltip title={
        <div style={{ whiteSpace: "pre-line" }}>
          <strong>Entity</strong>: {entityName}{"\n"}
          {idLabel ? <><strong>Id</strong>: {idLabel}</> : null}
          {
            indexInfo.hint ? (
              <>
                {"\n"}
                <span><strong>Identifier</strong>: {nodeIndex}</span>
              </>
            ) : null
          }
          {
            contained ? (
              <>
                {"\n"}
                <strong>Contained by</strong><span>{contained.replace(/^Contained by/, "")}</span>
              </>
            ) : null
          }
        </div>}>
        {content}
      </Tooltip>
    )

  };

  const renderHandle = (type: "target" | "source", position: Position) => {

    return (
      <Handle
        type={type}
        position={position}
        className={stl.connector_handle}
        draggable={false}
        isConnectable={false}
        // id={id || nodePath || nodeId}
      />
    );
  };

  const renderTypeIcon = () => {

    // TODO check if we can update this one to use the new icons?
    const IconComponent_ = (ControlIcons as any)[nodeType] || ControlIcons.unassigned!;
    const IconComponent = getAttributeIconAsComponentType((nodeType || "") as any, IconComponent_);

    // if (ControlIcons.unassigned === IconComponent_) {
    //   console.warn(`Unassigned icon for node type: ${nodeType}`);
    // }

    return (
      <div
        className={cn(
          stl.type_icon,
          stl[nodeClass],
        )}
      >
        <IconComponent />
      </div>
    );
  };

  const getNodeLabel = () => {

    const maxLabelLength = 90;
    const label = node.description || "";
    if (label.length <= maxLabelLength) {
      return (label);
    }
    const labelShort = `${label.substr(0, maxLabelLength)}...`;
    // now trim the label to the last word
    const labelTrimmed = `${labelShort.slice(0, labelShort.lastIndexOf(" "))}...`;

    return (labelTrimmed);
  };

  const renderValueSection = () => {

    return (
      <div
        className={cn(
          stl.value_section,
          stl[nodeClass],
        )}
      >
        <NodeValue
          node={node}
          showType={false}
        />
      </div>
    );
  };

  const renderTargetIcon = () => {

    if (!isTargetNode) {
      return null;
    }

    return (
      <div
        className={stl.target_icon}
      >
        <TargetIcon />
      </div>
    );
  };

  const getNamespaceLabel = () => {
    const namespace = node.definedIn?.replace(".docx", "").replace(".xlsx", "");
    return namespace ? <div className={stl.namespace}>{namespace}</div> :  null;
  }

  return (
    <div
      className={cn(
        props?.classes?.root,
        stl.container,
        stl[nodeClass],
        {[stl.target_node]: isTargetNode},
        {[stl.dragging]: isDragging},
      )}
      // style={(xPos !== undefined && yPos !== undefined) ? { position: "relative", right: xPos, top: yPos } : undefined}
    >
      {hasConnectorTop ? renderHandle("target", horizontal ? Position.Left : Position.Top) : null}
      {entityFlag()}
      {renderTargetIcon()}
      {!horizontal ? <GraphNodeCountEar
        parsedGraph={graph}
        currNodeId={nodePath || nodeId}
        visibleNodeIds={(restrictedView?.visibleNodes) || null}
        isTargetNode={isTargetNode}
        isDragging={isDragging}
        addVisibleNodes={setRestrictedViewNodesToMerge}
        showHidden={!(debug ?? false)}
      /> : null}
      <div
        className={cn(
          stl.col_container,
          stl[nodeClass],
        )}
      >
        <div
          className={stl.col_lhs}
        >
          {renderTypeIcon()}
        </div>
        <div
          className={cn(
            stl.col_rhs,
            {[stl.value_node]: isValueNode},
          )}
        >
          {/*getNamespaceLabel()*/}
          {getNodeLabel()}
          {/* the entity name is already above, so why repeat it? */}
          {/* {isValueNode ? <div><small>{entityNameWithIndex}</small></div> : null} */}
          {/* {isValueNode && nodeIndex ? <div><small>{nodeIndex}</small></div> : null} */}
          {
            (includeContextMenu)
            ?
              <GraphNodeContextMenu
                node={node}
                // okay, so these assumptions don't work...
                // isGoal={graphNodeType === "goal" || (graphNodeType === "byValue" && hasConnectorBottom && !hasConnectorTop)}
                isGoal={true}
                isTargetNode={isTargetNode}
              />
            : null
          }
        </div>
      </div>
      {isValueNode ? renderValueSection() : null}
      <GraphNodeBar
        node={node}
      />
      {hasConnectorBottom ? renderHandle("source", horizontal ? Position.Right : Position.Bottom) : null}
    </div>
  );
};

export const graphNodeStyles = (theme: any) => ({
  root: {
    "--txt-color": "white",
    "--border-color": theme.palette.background.border,
    "--handle-color": theme.palette.background.darkBorder,
    "--intermediate--bg-color": theme.labels.Current,
    "--intermediate-bg-color-darker": "#A7FDB0",
    "--goal-bg-color": theme.labels.Developer,
    "--goal-bg-color-darker": "#FFE5A6",
    "--input-bg-color": "#D8E0FD",
    "--input-bg-color-darker": "#AABCFB",
    backgroundColor: theme.palette.secondary.main,
    // the debugger visual has it's own colors
    "--value-color-default": "#8AB17D",
    "--value-color-default-darker": "#6C8F63",
    // "--value-color-default-darker": "#{darken(#8AB17D, 10%)}",
    "--value-color-falsy": "#f94144",
    "--value-color-falsy-darker": "#ba2f31",
    "--value-color-unknown": "#ced4da",
    "--value-color-unknown-darker": "#989da3",
    "--value-color-uncertain": "#e9ecef",
    "--value-color-uncertain-darker": "#b0b2b5",
    // but we can also use this rotating color scheme for the value nodes on a per-entity basis
    "--value-color-entity-0": "#FFE2FE", // magenta hue
    "--value-color-entity-1": "#FFEEEE", // pale red
    "--value-color-entity-2": "#FFF2D2", // fiesta
    "--value-color-entity-3": "#FEFFDB", // banana
    "--value-color-entity-4": "#CBFED0", // apple
    "--value-color-entity-5": "#ECF0FE", // beau blue
    "--value-color-entity-6": "#F6EDFF", // deep blue
    "--value-color-entity-7": "#F6EDFF", // lavender
  },
})

export default withStyles(graphNodeStyles)(GraphNode);
