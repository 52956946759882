import { PropsWithChildren, useState } from 'react';
import { Command } from 'cmdk';
import { Divider } from '@material-ui/core';
import { QueryParams, Resource, BackendResource, ResourceService } from '@imminently/immi-query';
import styled from 'styled-components';

import { LoadingDots } from '@icons';
import { scrollable } from '@common';
import { useSearch, UseSearchProps } from '@components/Search';
import { useScrollable } from '@common/hooks/useScrollable';
import { ModalActions, ModalActionsProps } from '@modals';
import { Popover, PopoverTrigger, PopoverContent } from '../Popover/Popover';
import '../styles.scss';
import { Trans } from 'react-i18next';

const MenuActions = styled(ModalActions)``;

const PopperContent = styled(PopoverContent)`
  min-width: 300px;
  max-width: 300px;
`;

const CommandMenu = styled(Command)`
  outline: none;

  [cmdk-input] {
    font-family: inherit;
    border: 1px solid ${p => p.theme.palette.background.darkBorder};
    padding: 0.5rem 1rem;
    width: 100%;
    border-radius: 0.5rem;
    font-size: 1rem;
    outline: none;

    &:hover, &:focus, &:active, &:focus-within {
      border-color: ${p => p.theme.palette.background.dark};
    }
  }

  [cmdk-group-heading] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: inherit;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.75rem;
    padding: 0 0.5rem;
    text-transform: uppercase;
  }

  [cmdk-list] {
    max-height: 220px;
    margin: 0 -0.5rem;
  }

  [cmdk-item] {
    &[data-selected="true"] {
      background-color: ${p => p.theme.palette.secondary.hover};
    }
  }

  [cmdk-loading] {
    text-align: center;
    padding: 1rem;
  }

  [cmdk-empty] {
    text-align: center;
    padding: 1rem;
  }
`;

export const Combobox = ({ label, data, isLoading, loadingMore, canLoad, loadMore, renderItem, onClick, search, children, actions }) => {
  const scrollLoad = useScrollable(loadMore, canLoad);
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={(v) => setOpen(v)}>
      <PopoverTrigger asChild>
        {children}
      </PopoverTrigger>
      <PopperContent>
        <CommandMenu shouldFilter={false}>
          <Command.Input autoFocus placeholder={search.placeholder} value={search.value} onValueChange={search.setValue} />
          {
            isLoading
              ? <Command.Loading><LoadingDots /></Command.Loading>
              : data?.length === 0
                ? <Command.Empty><Trans i18nKey="no_results"></Trans>.</Command.Empty>
                : (
                  <Command.Group heading={(
                    <>
                      {label}
                      {loadingMore ? <LoadingDots /> : null}
                    </>
                  )}>
                    <Command.List {...scrollLoad} className={scrollable}>
                      {
                        data.map((item) => (
                          <Command.Item
                            key={item.id}
                            value={item.id}
                            onSelect={() => {
                              setOpen(false);
                              search.setValue("");
                              onClick(item);
                            }}>
                            {renderItem(item)}
                          </Command.Item>
                        ))
                      }
                    </Command.List>
                  </Command.Group>
                )
          }
        </CommandMenu>
        <Divider style={{ margin: "0 -0.5rem 0.5rem -0.5rem" }} />
        {/* @ts-ignore */}
        <MenuActions {...actions} />
      </PopperContent>
    </Popover>
  );
};

export type ComboboxProps<T extends Resource> = {
  label: string;
  service: ResourceService<T>;
  filter?: QueryParams,
  renderItem: (item: BackendResource<T>) => React.ReactNode;
  onSelect: (item: BackendResource<T>) => void;
  searchProps?: UseSearchProps;
  actions?: ModalActionsProps;
} & PropsWithChildren;

export const ImmiCombobox = <T extends Resource>({ label, service, filter, searchProps, renderItem, onSelect, actions, children }: ComboboxProps<T>) => {
  const { filter: sFilter, ...search } = useSearch(searchProps);
  const { data, total, query, loadMore } = service.useInfiniteResources({
    perPage: 10,
    filter: {
      name: sFilter.length > 0 ? sFilter : undefined,
      // sort: { field: 'created', order: 'desc' },
      ...filter,
    },
  });

  const canLoad = !query.isError && !query.isFetching && (query.hasNextPage ?? false);
  const scrollLoad = useScrollable(loadMore, canLoad);

  const [open, setOpen] = useState(false);
  const heading = (
    <>
      {`${label} • ${total}`}
      {query.isFetchingNextPage ? <LoadingDots /> : null}
    </>
  );

  return (
    <Popover open={open} onOpenChange={(v) => setOpen(v)}>
      <PopoverTrigger asChild>
        {children}
      </PopoverTrigger>
      <PopperContent>
        <CommandMenu shouldFilter={false}>
          <Command.Input autoFocus placeholder={search.placeholder} value={search.value} onValueChange={search.setValue} />
          {
            query.isLoading
              ? <Command.Loading><LoadingDots /></Command.Loading>
              : data?.length === 0
                ? <Command.Empty><Trans i18nKey="no_results"></Trans>.</Command.Empty>
                : <Command.Group heading={heading} />
          }
          <Command.List {...scrollLoad} className={scrollable}>
            {
              data.map((item) => (
                <Command.Item
                  key={item.id}
                  value={item.id}
                  onSelect={() => {
                    setOpen(false);
                    search.setValue("");
                    onSelect(item);
                  }}>
                  {renderItem(item)}
                </Command.Item>
              ))
            }
          </Command.List>
        </CommandMenu>
        {
          actions ? (
            <>
              <Divider style={{ margin: "0 -0.5rem 0.5rem -0.5rem" }} />
              {/* @ts-ignore */}
              <MenuActions {...actions} />
            </>
          ) : null
        }
      </PopperContent>
    </Popover>
  );
};
