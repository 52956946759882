import { FormikGoalSelect, FormikSelect, FormikTextField } from "@components/forms/FormikControls";
import { Stack } from "@components";
import { useFormikContext } from "formik";
import { useIsProjectTechnicalWriter } from "auth/permissions";
import { get } from "lodash";
import { AttributeCommunicationProxyForm } from "./AttributeCommunicationProxyForm";

export const AttributeCommunicationForm = ({ scope }: { scope: string; }) => {
  const { values } = useFormikContext<any>();
  const canEdit = useIsProjectTechnicalWriter();
  
  const methodOptions = [
    { value: "email", key: "Email" },
    { value: "sms", key: "SMS" },
    { value: "proxy", key: "Proxy" }
  ];

  const selectedMethod = get(values, `${scope}.method`);
  const isProxy = selectedMethod === "proxy";

  const scopedValue = (name: string) => `${scope}.${name}`;

  return (
    <Stack gridGap="1rem">
      <FormikTextField fullWidth label="Title" name={scopedValue("title")} disabled={!canEdit} />
      <FormikSelect label="Method" name={scopedValue("method")} options={methodOptions} disabled={!canEdit} />
      {isProxy ? <AttributeCommunicationProxyForm scope={scope} readOnly={!canEdit} /> : null}
      <FormikGoalSelect label="Who" name={scopedValue("who")} disabled={!canEdit} />
      <FormikTextField fullWidth label="Subject" name={scopedValue("subject")} disabled={!canEdit} />
      <FormikTextField 
        fullWidth 
        multiline 
        minRows={4} 
        label="Body" 
        name={scopedValue("body")} 
        disabled={!canEdit}
      />
    </Stack>
  );
};
