import React from "react";
import { GraphColorScheme, RuleGraphCtx, RuleGraphProviderCtx } from "../providers/RuleGraphProvider";
import dagre, { graphlib } from "dagre";

export const collectPredecessorsOrSuccessors = (
  dagreGraph: graphlib.Graph<{}>,
  node: string,
  depth: number,
  type: "predecessors" | "successors",
) => {

  let currentDepth = 0;
  let currentNodes = [node];
  let retNodes: string[] = [];
  while (currentDepth < depth) {
    const newNodes: string[] = [];
    currentNodes.forEach((n) => {
      const currNewNodes = (
        type === "predecessors"
          ? (dagreGraph.predecessors(n) || [])
          : (dagreGraph.successors(n) || [])
      ) as unknown as string[];
      retNodes.push(...currNewNodes);
      newNodes.push(...currNewNodes);
    });
    currentNodes = newNodes;
    currentDepth++;
    if (currentNodes.length === 0) {
      break;
    }
  }
  return ([...new Set(retNodes)]);
};

/**
 *
 * @param outOfContextDefaults - it won't be often this will be used, but very occasionally, you might need to use a \
 * component that uses this hook, where a static default would be absolutely fine.
 * @returns
 */
export const useGraphVisualisation = (outOfContextDefaults?: any) => {

  try {
    const ruleGraphCtx = React.useContext<RuleGraphProviderCtx>(RuleGraphCtx);

    const countEntityColors = 8;
    const entityColorMapping = ruleGraphCtx.entities.map((entity, idx) => ({
      entity,
      colorKey: `--value-color-entity-${idx % countEntityColors}`,
    }));

    const showFocusAndExpandControls = ["classic", "classic-beta"].includes(ruleGraphCtx.graphVizType);

    /**
     * This calculates the maximum from a chosen root node to any other node in the graph,
     * but with the added restriction that we **must** form a tree structure from that
     * chosen root node first (i.e. no backwards traversals are allowed).
     *
     * TODO: there's a heap of reused stuff here from the worker and the above function,
     *       so we need to clean this up and more it to more of a utility function.
     */
    const maxTreeDepthFromRestrictedViewRootPath = React.useMemo(() => {

      let maxDepthFromFocus: number | null = null;
      const restrictedViewRootPath = ruleGraphCtx.restrictedView?.rootPath;
      // console.log(`Calculate maxTreeDepthFromRestrictedViewRootPath for ${restrictedViewRootPath}`);
      const graph = ruleGraphCtx.graph;
      // const isShowHidden = !(ruleGraphCtx.debug);
      const isShowHidden = true;

      if (restrictedViewRootPath && graph) {
        let parsedGraph = graph;
        if (!(parsedGraph._nodes)) {
          // we need to get the graph into `graphlib.Graph<{}>` format
          parsedGraph = graphlib.json.read(graph);
        }

        // replicate out our display graph
        // TODO not great we're doing the same work as our worker here....

        let allRelevantNodes: string[] = [];
        const maxExploreDepth = 10; // beyond this they can expand manually...
        const allPredecessors = collectPredecessorsOrSuccessors(parsedGraph, restrictedViewRootPath, maxExploreDepth, "predecessors");
        const allSuccessors = collectPredecessorsOrSuccessors(parsedGraph, restrictedViewRootPath, maxExploreDepth, "successors");

        allRelevantNodes = [
          restrictedViewRootPath,
          ...allPredecessors,
          ...allSuccessors,
        ];

        // console.log(`allRelevantNodes: (count ${allRelevantNodes.length})`, allRelevantNodes);

        const displayGraph = new dagre.graphlib.Graph();
        parsedGraph.nodes().forEach((id) => {

          const node = parsedGraph.node(id);

          if (!node) {
            console.log("invalid node", id);
            return;
          }
          if (!allRelevantNodes.includes(id)) {
            return;
          }
          if (!isShowHidden && node.hidden) {
            return;
          }

          displayGraph.setNode(id, node?.description || "-");
        });
        parsedGraph.edges().forEach((edge) => {
          const node1: any = parsedGraph.node(edge.v);
          const node2: any = parsedGraph.node(edge.w);
          if (!node1 || !node2) {
            console.log("invalid nodes", edge);
            return;
          }
          if (!allRelevantNodes.includes(edge.v) || !allRelevantNodes.includes(edge.w)) {
            return;
          }
          if (!isShowHidden && (node1.hidden || node2.hidden)) {
            return;
          }

          displayGraph.setEdge(edge.v, edge.w, {});
        });

        // now run dijkstra's on the tree structure we've created, and the depth will be completely accurate to what's drawn
        if (displayGraph.node(restrictedViewRootPath)) {
          const dj = graphlib.alg.dijkstra(
            displayGraph,
            restrictedViewRootPath,
            undefined,
            (v) => (displayGraph as any).nodeEdges(v),
          );
          let maxDepthFromFocusInner = 0;
          Object.keys(dj).forEach((w) => {
            if (dj[w].distance !== Infinity) {
              // Infinite distance means these nodes aren't connected to the root
              const reachableNode = parsedGraph.node(w);
              const isHidden = reachableNode.hidden ?? false;
              const isGlobal = reachableNode?.entity === "global";
              if ((!isHidden || isShowHidden)) {
                maxDepthFromFocusInner = Math.max(maxDepthFromFocusInner, dj[w].distance);
              }
            }
          });
          maxDepthFromFocus = maxDepthFromFocusInner;
        }
      }

      // check if restrictedViewDepth exceeds maxDepthFromFocus
      const restrictedViewDepth = ruleGraphCtx.restrictedView?.graphDepth ?? null;
      if (restrictedViewDepth !== null && maxDepthFromFocus !== null && (restrictedViewDepth > maxDepthFromFocus)) {
        ruleGraphCtx.restrictViewToDepth(maxDepthFromFocus);
      } else if (maxDepthFromFocus === null && restrictedViewDepth !== null) {
        ruleGraphCtx.restrictViewToDepth(null);
      } else if (restrictedViewDepth === null && maxDepthFromFocus !== null) {
        if (maxDepthFromFocus > 0) {
          // just default it to 1 so the user can walk outwards
          ruleGraphCtx.restrictViewToDepth(1);
        } else if (maxDepthFromFocus === 0) {
          // they've selected something with no connections...
          // this is a special case, but we need to set the depth to something other than null
          ruleGraphCtx.restrictViewToDepth(0);
        }
      }

      return (maxDepthFromFocus);
    }, [ruleGraphCtx.graph, ruleGraphCtx.restrictedView?.rootPath]);

    // --

    return ({
      graphVizType: ruleGraphCtx.graphVizType,
      extraVizTypes: ruleGraphCtx.extraVizTypes,
      setGraphVizType: ruleGraphCtx.setGraphVizType,
      graph: ruleGraphCtx.graph,
      selectedNode: ruleGraphCtx.selectedNode,
      selectedNodeId: ruleGraphCtx.selectedNodeId,
      selectedNodeValue: ruleGraphCtx.selectedNode?.value ?? null,
      setSelectedNodeId: ruleGraphCtx.setSelectedNodeId,
      rstSelectedNode: () => ruleGraphCtx.setSelectedNodeId(null),
      triggerFocus: ruleGraphCtx.triggerFocus,
      defaultView: ruleGraphCtx.defaultView,
      hasClassic: ruleGraphCtx.hasClassic,
      hasNebula: ruleGraphCtx.hasNebula,
      hasData: ruleGraphCtx.hasData,
      release: ruleGraphCtx.release || ruleGraphCtx.fullRelease,
      debug: ruleGraphCtx.debug,
      hasDecisionReport: ruleGraphCtx.hasDecisionReport,
      hasFocus: ruleGraphCtx.hasFocus,
      report: ruleGraphCtx.report,
      switchView: ruleGraphCtx.switchView,
      goal: ruleGraphCtx.goal,
      searchText: ruleGraphCtx.searchText,
      setSearchText: ruleGraphCtx.setSearchText,
      colorScheme: ruleGraphCtx.colorScheme,
      setColorScheme: ruleGraphCtx.setColorScheme,
      entities: ruleGraphCtx.entities,
      entityColorMapping,
      temporalSlice: ruleGraphCtx.temporalSlice,
      setTemporalSlice: ruleGraphCtx.setTemporalSlice,

      showFocusAndExpandControls,
      restrictedView: ruleGraphCtx.restrictedView,
      rstRestrictedView: ruleGraphCtx.rstRestrictedView,
      restrictViewToDepth: ruleGraphCtx.restrictViewToDepth,
      setRestrictedViewRootPath: ruleGraphCtx.setRestrictedViewRootPath,
      setVisibleNodes: ruleGraphCtx.setVisibleNodes,
      rstVisibleNodes: ruleGraphCtx.rstVisibleNodes,
      addVisibleNodes: ruleGraphCtx.addVisibleNodes,
      // isGraphViewRestricted: !!(ruleGraphCtx.restrictedView?.rootPath && (ruleGraphCtx.restrictedView?.graphDepth ?? null) !== null),
      setRestrictedViewNodesToMerge: ruleGraphCtx.setRestrictedViewNodesToMerge,
      nodeRenderLimit: ruleGraphCtx.nodeRenderLimit,
      setNodeRenderLimit: ruleGraphCtx.setNodeRenderLimit,

      // maxDepthFromRestrictedViewRootPath, // not used - see bottom of file for implementation
      maxTreeDepthFromRestrictedViewRootPath,
    });
  } catch (e) {
    console.error("Error in useGraphVisualisation - likely trying to use this out of context. Defaults will be returned", e);
    return (outOfContextDefaults || {});
  }
};

/**
   * This calculates the maximum from a chosen root node to any other node in the graph.
   * Move to the bottom here for the time being, because we're not using it...
   */
// const maxDepthFromRestrictedViewRootPath = React.useMemo(() => {

//   let maxDepthFromFocus: number | null = null;
//   const restrictedViewRootPath = ruleGraphCtx.restrictedView?.rootPath;
//   const graph = ruleGraphCtx.graph;
//   const isShowHidden = !(ruleGraphCtx.debug);

//   if (restrictedViewRootPath && graph) {
//     let graphToWalk = graph;
//     if (!(graphToWalk._nodes)) {
//       // we need to get the graph into `graphlib.Graph<{}>` format
//       graphToWalk = graphlib.json.read(graph);
//     }
//     if (graphToWalk.node(restrictedViewRootPath)) {
//       const dj = graphlib.alg.dijkstra(
//         graphToWalk,
//         restrictedViewRootPath,
//         undefined,
//         (v) => graphToWalk.nodeEdges(v), // if you wanted to find all paths use this
//       );
//       let maxDepthFromFocusInner = 0;
//       // console.log("dj", dj);
//       // console.log("test node", graphToWalk.node("0adcf633-d964-492d-9a7e-d6dbc62b4b73"));
//       // console.log("isShowHidden", isShowHidden);
//       Object.keys(dj).forEach((w) => {
//         if (dj[w].distance !== Infinity) {
//           // Infinite distance means these nodes aren't connected to the root
//           const reachableNode = graphToWalk.node(w);
//           const isHidden = reachableNode.hidden ?? false;
//           const isGlobal = reachableNode?.entity === "global";
//           if ((!isHidden || isShowHidden)) {
//             maxDepthFromFocusInner = Math.max(maxDepthFromFocusInner, dj[w].distance);
//             // console.log("used node", dj[w]);
//             // console.log("which is this node", graphToWalk.node(w))
//           }
//         }
//       });
//       maxDepthFromFocus = maxDepthFromFocusInner;
//     }
//   }

//   // check if restrictedViewDepth exceeds maxDepthFromFocus
//   const restrictedViewDepth = ruleGraphCtx.restrictedView?.graphDepth ?? null;
//   if (restrictedViewDepth !== null && maxDepthFromFocus !== null && (restrictedViewDepth > maxDepthFromFocus)) {
//     ruleGraphCtx.restrictViewToDepth(maxDepthFromFocus);
//   } else if (maxDepthFromFocus === null && restrictedViewDepth !== null) {
//     ruleGraphCtx.restrictViewToDepth(null);
//   } else if (restrictedViewDepth === null && maxDepthFromFocus !== null) {
//     if (maxDepthFromFocus > 0) {
//       // just default it to 1 so the user can walk outwards
//       ruleGraphCtx.restrictViewToDepth(1);
//     } else if (maxDepthFromFocus === 0) {
//       // they've selected something with no connections...
//       // this is a special case, but we need to set the depth to something other than null
//       ruleGraphCtx.restrictViewToDepth(0);
//     }
//   }

//   return (maxDepthFromFocus);
// }, [ruleGraphCtx.graph, ruleGraphCtx.restrictedView?.rootPath]);