import React from 'react';
import { Handle, Position, useStore } from 'reactflow';
import get from 'lodash/get';
import find from 'lodash/find';
import styled from 'styled-components';
import { useSearchMatchCtx } from './SearchMatchCtx';
import TemporalValueRender from '@components/TemporalValue';
import NodeValue from '@components/GraphVisualisation/components/AttributeInfo/NodeValue';


export const SelectedSearchNodeClsnm = 'selected-search-node';

const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const InputContainer = styled.div`
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: white;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 230px;
  background-color: #287271;
`;

const GoalContainer = styled.div`
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: black;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 230px;
  background-color: #E9C46A;
`;

const IntermediateContainer = styled.div`
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: white;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 230px;
  background-color: #8AB17D;
`;

const NodeContainer = styled.div`
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: white;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 230px;
  background-color: #8AB17D;
`;

const FalseContainer = styled.div`
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: white;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 150px;
  background-color: #f94144;
`;

const UncertainContainer = styled.div`
  border-radius: 5px;
  border-style: dashed;
  border-width: 1px;
  border-color: black;
  color: black;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 150px;
  background-color: #e9ecef;
`;

const UnknownContainer = styled.div`
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: black;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 150px;
  background-color: #ced4da;
`;

const MoreContainer = styled.div`
  border-radius: 10px;
  height: 20px;
  width: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: black;
  font-size: 12px;
  text-align: center;
  background-color: #7293A0;
`;


// eslint-disable-next-line complexity
export const ByValueNode: React.FC< Record< string, any > > = p => {
  const { data, type, id, sourcePosition, targetPosition } = p;
  const {edges} = useStore((edges) => ({edges}));

  const searchMatchId = useSearchMatchCtx();
  const className = React.useMemo(
    () => (searchMatchId === id ? SelectedSearchNodeClsnm : undefined),
    [searchMatchId, id],
  );

  const parents = find(edges, { target: id });
  const children = find(edges, { source: id });
  const node = data.node;
  //  console.log('node', node);
  const value = typeof node.input === 'undefined' ? node.derived : node.input;
  let Container = NodeContainer;
  let valueDesc: React.ReactNode = '';
  if (value === false) {
    Container = FalseContainer;
    valueDesc = 'false';
  } else if (value === null) {
    Container = UncertainContainer;
    valueDesc = 'Uncertain';
  } else if (typeof value === 'undefined') {
    Container = UnknownContainer;
    valueDesc = 'Unknown';
  } else if (value.temporal) valueDesc = <TemporalValueRender inline ranges={get(value, 'temporal.ranges')} temporalEditorProps={undefined} />;
  else valueDesc = value.toString();

  let desc = node.description;
  if ( node.type === "date" && typeof valueDesc === "string" && valueDesc.match(iso8601Pattern) ) {
    valueDesc = valueDesc.replace(/T.+$/, "");
  }

  if (node.index) desc += ` (${ node.entity }:${ node.index })`;

  // // console.log('build node', data, parents, children)
  return (
    <Container className={className}>
      <div style={{paddingBottom: "10px"}}>
        {parents && (
          <Handle
            type='target'
            position={Position.Top}
            style={{ background: 'hsla(0,0%,100%,.4)' }}
          />
        )}
        {desc}
      </div>
      <NodeValue node={node} showType={false} />
      {children && (
        <Handle
          type='source'
          position={Position.Bottom}
          style={{ background: 'hsla(0,0%,100%,.4)' }}
        />
      )}
    </Container>
  );
};

const EntityInfo: React.FC< { node: any } > = React.memo(({ node }) => {
  if (node.entity === 'global') return null;

  return (
    <div>
      <em>
        on entity&nbsp;
        {node.entity}
        {' '}
        {node.index ? `(${ node.index })` : '' }
      </em>
    </div>
  );

});
EntityInfo.displayName = 'components/RuleGraph/__nodeTypes/EntityInfo';


export const InputNode: React.FC< { data: any } > = React.memo(({ data }) => {
  const node = data.node;
  const nodeId = data.node.id;
  const searchMatchId = useSearchMatchCtx();
  const className = React.useMemo(
    () => (searchMatchId === nodeId ? SelectedSearchNodeClsnm : undefined),
    [searchMatchId, nodeId],
  );

  return (
    <InputContainer className={className}>
      <Handle
        type='target'
        position={Position.Top}
        style={{ background: 'hsla(0,0%,100%,.4)' }}
      />
      {node.description}
      <EntityInfo node={node} />
    </InputContainer>
  );
});
InputNode.displayName = 'components/RuleGraph/__nodeTypes/InputNode';


export const GoalNode: React.FC< { data: any } > = React.memo(({ data }) => {
  const node = data.node;
  const nodeId = data.node.id;
  const searchMatchId = useSearchMatchCtx();
  const className = React.useMemo(
    () => (searchMatchId === nodeId ? SelectedSearchNodeClsnm : undefined),
    [searchMatchId, nodeId],
  );

  return (
    <GoalContainer className={className}>
      {node.description}
      <EntityInfo node={node} />
      <Handle
        type='source'
        position={Position.Bottom}
        style={{ background: 'hsla(0,0%,100%,.4)' }}
      />
    </GoalContainer>
  );
});
GoalNode.displayName = 'components/RuleGraph/__nodeTypes/GoalNode';


export const IntermediateNode: React.FC< { data: any } > = React.memo(({ data }) => {
  const node = data.node;
  const nodeId = data.node.id;
  const searchMatchId = useSearchMatchCtx();
  const className = React.useMemo(
    () => (searchMatchId === nodeId ? SelectedSearchNodeClsnm : undefined),
    [searchMatchId, nodeId],
  );

  return (
    <IntermediateContainer className={className}>
      <Handle
        type='target'
        position={Position.Top}
        style={{ background: 'hsla(0,0%,100%,.4)' }}
      />
      {node.description}
      <EntityInfo node={node} />
      <Handle
        type='source'
        position={Position.Bottom}
        style={{ background: 'hsla(0,0%,100%,.4)' }}
      />
    </IntermediateContainer>
  );
});
IntermediateNode.displayName = 'components/RuleGraph/__nodeTypes/IntermediateNode';


const MoreNode = ({ data }) => {
  return (
    <MoreContainer>
      <Handle
        type='target'
        position={Position.Top}
      />
      {data.label}
      <Handle
        type='source'
        position={Position.Bottom}
      />
    </MoreContainer>
  );
};


export const nodeTypes = {
  source: InputNode,
  intermediate: IntermediateNode,
  goal: GoalNode,
  byValue: ByValueNode,
  more: MoreNode
};
