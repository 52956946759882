import { toast } from "react-toastify";
import { useNotification as useImmiNotification } from "@imminently/imminently_platform";
import { useEffect } from "react";

/** Component that binds legacy platform notifications into latest toast system */
export const ImmiNotifications = () => {
  const { notification, hide } = useImmiNotification();

  useEffect(() => {
    if(!!notification) {
      // we have a notification to process
      const { message, type, messageArgs } = notification;
      const duration = messageArgs?.smart_count ?? 3000;
      toast(message, { autoClose: duration, type, onClose: hide });
    }
  }, [notification]);
};

export const useNotification = () => {
  const makeToast = (
    msg: string,
    level: "info" | "warning" | "error" = "info",
    duration: number | undefined = undefined,
  ) => {
    toast(msg, { autoClose: duration, type: level });
  };

  makeToast.info = (msg: string, duration?: number) => {
    console.log(`[Notify] ${msg} (info)`);
    toast.info(msg, { autoClose: duration });
  }
  makeToast.success = (msg: string, duration?: number) => {
    console.log(`[Notify] ${msg} (success)`);
    toast.success(msg, { autoClose: duration });
  }
  makeToast.warning = (msg: string, duration?: number) => {
    console.log(`[Notify] ${msg} (warning)`);
    toast.warning(msg, { autoClose: duration });
  }
  makeToast.error = (msg: string, duration?: number) => {
    console.log(`[Notify] ${msg} (error)`);
    toast.error(msg, { autoClose: duration });
  }
  makeToast.promise = toast.promise;
  makeToast.loading = toast.loading;
  makeToast.update = toast.update;

  return makeToast;
};

// Re-export of useNotification to match more modern naming conventions
export const useNotify = useNotification;
// re-export toast for use in other parts of the app under a similar name
export const notify = toast;
