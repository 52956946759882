import React, { PropsWithChildren, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { hideModal, useResourceList, getOneHook, crudGetOne } from "@imminently/imminently_platform";
import { PageHeader, useGetFullRelease, useProject, DefaultSkeleton } from "@common";
import { showConfirmation, addProject } from "@modals";

import { useLocation } from "react-router";
import { useScope } from "../../../redux/scope";
import GraphContext, { ParsedGraphContextProvider } from "./GraphContext";
import { useTranslation } from "react-i18next";
import ReleaseContextProvider from "./ReleaseContextProvider";

export type ReleaseWrapperProps = {
  title: string;
  skeleton?: React.ReactNode;
  header?: boolean;
  scopeComponent?: any;
} & PropsWithChildren;

const ReleaseWrapper = ({ children, skeleton = <DefaultSkeleton />, title, header = true, scopeComponent }: ReleaseWrapperProps) => {
  useGetFullRelease();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { workspace, project: projectId, release: releaseId } = useScope();
  const project = useProject(projectId);
  // const release = useRelease(releaseId);
  const { record: release } = getOneHook("releases", releaseId);
  const [graph, setGraph] = useState();
  const projects = useResourceList("models", workspace, "default");

  const hasNoProjects = () => {
    // we have no projects if not loading and list is > 0
    return projects.loading === false && projects.loadedOnce && projects.ids.length === 0;
  };

  // deprecated, this nolonger appears to be needed, as we are using getFullRelease, and dispatching crudGetOne elsewhere
  // assuming nothing is broken, this can be removed
  // useEffect(() => {
  //   if (releaseId) dispatch(crudGetOne("releases", releaseId));
  // }, [dispatch, releaseId]);

  useEffect(() => {
    if (release && release.rule_graph) setGraph(release.rule_graph);
  }, [dispatch, release]);

  useEffect(() => {
    if (hasNoProjects()) {
      // no projects, show modal
      dispatch(
        showConfirmation({
          title: "Create project",
          body: "Build does not work without an active project. Please create one now.",
          actions: {
            primary: {
              name: "Create project",
              onClick: () => dispatch(addProject(t)),
            },
            secondary: [
              {
                name: "Projects",
                onClick: () => {
                  dispatch(hideModal());
                  dispatch(push("/projects"));
                },
              },
            ],
          },
        }),
      );
    }
  }, [dispatch, projectId]);
  // const validRelease = release && (graph || pathname === "/build/entities") && release.model === projectId && (project ? project.workspace === workspace : true);
  return (
    <ReleaseContextProvider release={release}>
      <ParsedGraphContextProvider>
        <GraphContext.Provider value={graph}>
          {header && (
            <PageHeader
              title={title}
              scope
              scopeComponent={scopeComponent}
            >
            </PageHeader>
          )}
          <Suspense fallback={skeleton}>{children}</Suspense>
        </GraphContext.Provider>
      </ParsedGraphContextProvider>
    </ReleaseContextProvider>
  );
};

export default ReleaseWrapper;
