import { forwardRef } from 'react';
import { useRouteMatch } from 'react-router';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';
import Tab, { TabProps } from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/styles';

import { TestingIcon, ProjectsIcon, BuildIcon, MonitorIcon } from '@icons';
import { useRouting } from './hooks/HooksRouting';
import { Roles, useAuthorise, useHasRole } from 'auth/permissions';
import { useTranslation } from 'react-i18next';

const StyledTabs = withStyles(theme => ({
  root: {
    minHeight: '2.5rem',
    maxHeight: '2.5rem',
    overflow: 'visible',
    '&[data-active=false]': {
      '& .MuiTab-root': {
        color: theme.header.color,
        transition: 'color 0ms',
      },
      '& $indicator': {
        display: 'none',
      },
    },
  },
  scroller: {
    overflow: 'visible !important',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '0.25rem',
    bottom: '-0.5rem',
    transition: 'all cubic-bezier(0.88, -0.35, 0.565, 1.35) 0.4s',
    '& > span': {
      width: '100%',
      borderRadius: '1rem 1rem 0 0',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(forwardRef<any, TabsProps>((props, ref) => <Tabs ref={ref} {...props} TabIndicatorProps={{ children: <span /> }} />));

const StyledTab = withStyles(theme => ({
  root: {
    minWidth: '100px',
    minHeight: '2.5rem',
    color: theme.header.color,
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    opacity: 1,
    zIndex: 10,
    transition: 'color 150ms 250ms',
    padding: '0 1.5rem',
  },
  selected: {
    color: theme.palette.primary.main,
  },
  wrapper: {
    flexDirection: 'row',
    '& > *:first-child': {
      margin: '0 0.5rem 0 0 !important',
    },
  },
}))(forwardRef<any, TabProps>((props, ref) => <Tab ref={ref} disableRipple {...props} />));

export const Navigation = () => {
  const { goToPage } = useRouting();
  const match = useRouteMatch();
  const authorise = useAuthorise();
  const { t, i18n } = useTranslation();

  const tabs = [
    // {
    //   name: "Dashboard",
    //   icon: <DashboardIcon></DashboardIcon>,
    //   route: /^\/?$/,
    //   onClick: () => goToPage("/"),
    // },
    {
      name: t('projects_label'),
      icon: <ProjectsIcon />,
      route: /^\/projects.*$/,
      onClick: () => goToPage('/projects'),
    },
    {
      name: t("build_label"),
      icon: <BuildIcon />,
      route: /^\/(build|models).*$/,
      onClick: () => {
        return goToPage('/build');
      },
      role: [Roles.ProjectReader, Roles.ProjectWriter, Roles.ProjectTechnical],
    },
    {
      name: t("verify_label"),
      icon: <TestingIcon />,
      route: /^\/verify.*$/,
      onClick: () => goToPage('/verify'),
      role: Roles.Tester,
    },
    {
      name: t("monitor_label"),
      icon: <MonitorIcon />,
      route: /^\/(monitor).*$/,
      onClick: () => goToPage('/monitor/overview'),
      role: Roles.ReportViewer,
    },
  ].filter(t => t.role ? authorise(t.role) : true);

  const active = tabs.findIndex(t => t.route.test(match.url));
  const value = active >= 0 && active;
  return (<>
    <StyledTabs value={value} data-active={value}>
      {tabs.map(tab => (
        <StyledTab key={`nav-${tab.name}`} icon={tab.icon} label={tab.name} onClick={tab.onClick} />
      ))}
    </StyledTabs>
  </>);
};
