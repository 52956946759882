import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { CloseIcon } from "@icons";
import dagre from "dagre";
import { DropdownMenu, Menu } from "@components";
import { Chip } from "@material-ui/core";
import { getContainedBy, getIndexForNode } from "../../../common";
import { useTranslation } from "react-i18next";

const StyledDropDown = styled(DropdownMenu)`
  width: 100%;
  border-color: ${ props => (props.error ? props.theme.palette.error.main : 'inherit') };
`;

export interface GoalMenuProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  graph: any;
  onClick?: (id: string) => void;
  onGoalIdChange?: (id: string) => void;
  menuClassName?: string;
  allowEmpty?: boolean;
  /** a `placeholder` only makes sense if `allowEmpty` is `true` */
  placeholder?: string;
  goalId?: string;
  /** if `true` non instances (other than globals) will be ignored */
  instances?: boolean;
  disabled?: boolean;
  includeGoals?: boolean;
  includeInputs?: boolean; // Whether to show input nodes
  includeDerived?: boolean; // Whether to show derived nodes
  includeInferred?: boolean;
  includeIdentifiers?: boolean;
  includeMeta?: boolean;
  error?: boolean;
  /** if present, should only show attributes that belong to this entity */
  entityName?: string;
  /** if true - ignore .hidden */
  showHidden?: boolean;
  /** if true - ignore .identifier */
  showIdentifiers?: boolean;
};

const GoalMenu = (props: GoalMenuProps) => {
  const {
    graph,
    onClick,
    onGoalIdChange,
    menuClassName,
    allowEmpty = false, /* not sure why */
    goalId: goalIdFromProps,
    instances = false,
    includeDerived = true,
    includeInputs = true,
    includeGoals = true,
    includeInferred = false,
    includeIdentifiers = false,
    includeMeta = false,
    entityName,
    showHidden,
    showIdentifiers,
    disabled,
    ...otherProps
  } = props;
  const { t } = useTranslation();
  const graphRef = React.useRef(graph);

  const [ stateGoalId, setStateGoalId ] = React.useState(goalIdFromProps);

  const currentGoalId = goalIdFromProps ?? stateGoalId;

  const handleGoalIdChange = (id) => {
    if (id !== stateGoalId) {
      setStateGoalId(id);
      if (onGoalIdChange) {
        onGoalIdChange(id);
      }
    }
  };

  const _graph = graph && (typeof graph.nodes === "function" ? graph : dagre.graphlib.json.read(graph)); // Depends if the graph has already been generated

  const getChipLabel = (node: any) => {

    let indexInfo: any = getIndexForNode(node, graph) || {};
    let nodeIndex = "";
    if (indexInfo) {
      nodeIndex = indexInfo.value;
    }
    let cleanIndex = (nodeIndex && nodeIndex.length > 8) ? `${nodeIndex.substring(0, 8)}...` : nodeIndex;
    let contained = getContainedBy(node.path, graph);
    const idLabel = indexInfo.hint ? indexInfo.hint : nodeIndex;
    const entityName = node.entity;

    // NOTE: globals will never have the `cleanIndex` populated, but it's fine...no need for special conditions
    const chipLabel = `${entityName}${cleanIndex ? `: ${ cleanIndex }` : ''}`;

    return (chipLabel);
  };

  const currentGoal = useMemo(() => {
    if (_graph) {
      const goal = _graph.node(currentGoalId);
      return goal ? goal.description : "";
    }
    return "";
  }, [ currentGoalId ]);

  useEffect(() => {

    if (graphRef.current !== graph) {
      graphRef.current = graph; // This used to be in the below code - but it caused additional goal resets.
    }

    if (_graph && !allowEmpty && currentGoalId === "") {
      const goals = _graph.sources();
      if (!goals || goals.length === 0) return;

      const goal = goals.filter(node => node && (node.goal || (!node.fk && !node.identifier && !node.inferred && !node.meta)));
      if (goal) {
        const goalId = instances ? (goal.path || goal.id) : goal.id;
        handleGoalIdChange(goalId);
      }
    }
  }, [ currentGoalId, _graph, graph, allowEmpty ]);

  useEffect(() => {
    if (!goalIdFromProps) return;

    const goals = _graph.sources();
    if (!goals || goals.length === 0) return;


    /*
    // Why do we do this? This just unnecessarily resets the goal to the same id?
    const goal = _graph.node(goalIdFromProps);
    if (goal) {
      handleGoalIdChange(goal.id);
    }*/
  }, [ goalIdFromProps ]);


  const nodes: any[] = [];
  if (_graph) {
    _graph.nodes().forEach(n => {

      const node = _graph.node(n);
      if(!node) {
        return;
      }
      if (node.fk || (node.identifier && !showIdentifiers)) {
        return;
      }

      // if (instances && !(node.path || node.entity === "global")) {
      //   return;
      // } else if (!instances && node.path) {
      //   return;
      // }
      // why over complicate it?...just check hidden
      if (instances && node.hidden && !showHidden) {
        return;
      }

      if(entityName && node.entity !== entityName) return;
      //if (_graph.successors(n).length === 0) return; // Only show goals and intermediate
      //console.log('n', node)
      let type = 'goal';

      if (node.meta) type = 'meta';
      else if (node.inferred || node.fk) type = 'inferred';
      else if (node.identifier) type = 'identifier';
      else if ((_graph.predecessors(n) || []).length > 0) {
        if ((_graph.successors(n) || []).length > 0) type = 'intermediate';
        else type = 'input';
      }

      const cfg = {
        id: node.id,
        name: node.description,
        action: <Chip
          style={{ background: "white" }}
          variant="outlined"
          size="small"
          // label={node.entity}
          label={getChipLabel(node)}
        />,
        type: type,
        // nothing is actually using this
        // isgoal: !((_graph.predecessors(n) || []).length > 0),
        path: node.path,
        entity: node.entity,
      };
      nodes.push(cfg);
    });
  }

  let groups: any = [];
  if (includeGoals) groups.push({
    name: t('debugger.goals'),
    filter: i => i.type === 'goal',
  });
  if (includeInputs) groups.push({
    name: t('debugger.inputs'),
    filter: i => i.type === 'input'
  });
  if (includeDerived) groups.push({
    name: t('debugger.derived'),
    filter: i => i.type === 'intermediate',
  });
  if (includeInferred) groups.push({
    name: t('debugger.inferred'),
    filter: i => i.type === 'inferred',
  })
  if (includeIdentifiers) groups.push({
    name: t('debugger.identifier'),
    filter: i => i.type === 'identifier',
  });

  if (includeMeta) groups.push({
    name: "Meta",
    filter: i => i.type === 'meta'
  });
  return (
    <div
      style={{
        display: "block",
        position: "relative",
      }}
    >
      {
        (currentGoalId && currentGoalId !== "" && allowEmpty && !disabled)
        ?
          <div
            style={{
              position: "absolute",
              right: 35,
              top: "70%",
              transform: "translateY(-70%)",
              cursor: "pointer",
              zIndex: 1,
              backgroundColor: "white",
            }}
            onClick={() => handleGoalIdChange("")}
          >
            <CloseIcon />
          </div>
        :
          null
      }
      <StyledDropDown
        showFullOnHover={true}
        value={currentGoal}
        disabled={disabled}
        {...otherProps}
      >
        {({ close }) => {
          const select = (g) => {
            const selectedId = instances ? (g.path || g.id) : g.id;
            handleGoalIdChange(selectedId);
            close();
          };
          return (
            <Menu
              onOptionClick={select}
              search={t('build.search_attributes_placeholder')}
              // @ts-ignore
              width={500}
              // @ts-ignore
              height={400}
              className={menuClassName}
              // @ts-ignore
              groupBy={groups}
              items={nodes}
            />
          );
        }}
      </StyledDropDown>
    </div>
  );
};

export default React.memo(GoalMenu);
