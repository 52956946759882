import { useEffect, useRef, useState } from "react";
import { NotificationIcon } from "@icons";
import { Badge, Box, IconButton, Popover, Typography } from "@material-ui/core";
import { findReleaseDocument, Release, Job } from "@packages/commons";
import DurationSince from "@pages/models/release/Interview/Designer/components/duration-since/DurationSince";
import Tooltip from "@material-ui/core/Tooltip";
import styles from "./ReleaseNotifications.module.scss";
import clsx from "clsx";
import { type BackendResource, filterAction } from "@imminently/immi-query";
import * as fullReleaseRedux from "redux/fullRelease/reducer";
import { documentService, releaseService } from "services";
import { getAuthHook, crudGetOne } from "@imminently/imminently_platform";
import { useDispatch, useSelector } from "react-redux";
import { toTitleCase } from "@components/util/UtilString";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { useNotify } from "@common/notifications";
import { filter } from "rxjs";
import { Avatar } from "@components";

export interface ReleaseNotificationsProps {
  release: BackendResource<Release> | null;
}

interface JobWithDocumentInfo extends Job {
  documentName: string;
}

const findDocumentById = (documents: any, documentId: string) => {
  return findReleaseDocument(documents, (doc) => doc.reference === documentId);
};

// very naughty having a module level variable, but it's a quick way to prevent duplicate notifications
const received: string[] = [];

const useReleaseJobs = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { user } = getAuthHook();

  const release = useFullRelease();

  let inProgress = false;
  let jobs: JobWithDocumentInfo[] = [];
  // let compilingDocuments = 0;
  const documentsCompiling = {};
  const documentsWithErrors = {};

  // @ts-ignore - documents exists in the store, we just haven't typed it
  const editingDocument = useSelector(s => s.documents?.document);

  if (release?.jobs?.length) {
    jobs = [];
    for (const job of release.jobs) {
      const document = job.documentId ? findDocumentById(release.documents, job.documentId) : undefined;
      if (job.status === "queued") {
        inProgress = true;
      }
      if (job.documentId && job.type === "compileDocument") {
        if (job.status === "queued") {
          if (!documentsCompiling[job.documentId]) {
            documentsCompiling[job.documentId] = true;
            // compilingDocuments += 1;
          }
          // inProgress = true;
          // @ts-ignore
        } else if (job.status === "failed" || job.status === "error") {
          documentsWithErrors[job.documentId] = true;
        }
      }
      jobs.push({
        ...job,
        documentId: job.documentId,
        documentName: document?.name ?? "",
      });
    }
    jobs.sort((a, b) => (b.updated || b.started) - (a.updated || a.started));
  }

  const releaseId = release?.id; // use this so that the effect only runs when the release changes
  useEffect(() => {
    if (releaseId) {
      const sub = releaseService.pubsub$
        .pipe(
          filterAction("release-job-update"),
          // filter out any jobs we've already received
          filter((msg: any) => msg.data.result && !received.includes(msg.data.result.id))
        )
        .subscribe((msg) => {
          const result = msg.data.result;
          const jobId: string = result.id;
          received.push(jobId); // add to list of received jobs
          // notify.info(`Job ${jobId} updated, type: ${result.type}, status: ${result.status}`);
          if (msg.data.releaseId === releaseId ) {
            // update release as graph should have changed
            // updates legacy release, which uses old immi platform
            dispatch(crudGetOne("releases", releaseId));
            // upates full release, which uses new query system
            dispatch(fullReleaseRedux.aCreators.requestToActualize());
            const jobType = result.type;
            if (jobType === "compileDocument") {
              const documentId = result.documentId;
              // only alert them if they are the one who compiled
              if (documentId && msg.data.author === user.id) {
                documentService.invalidateAllQueries();
                if (result.status === "failed") {
                  notify.error(`Something went wrong compiling '${result.title}', please try again`);
                } else if (result.errors?.length) {
                  notify.error(`Compiled '${result.title}' with ${result.errors.length} error${result.errors.length !== 1 ? "s" : ""}`);
                } else {
                  notify.info(`Compiled '${result.title}'`);
                }
              }
            } else if (jobType === "add-entity") {
              if (msg.data.author === user.id) {
                if (editingDocument) {
                  documentService.invalidateAllQueries();
                }
              }
            }
          }
        });
      return () => {
        sub.unsubscribe();
      };
    }
  }, [releaseId]);

  return { jobs, inProgress, documentsWithErrors };
}

export const ReleaseNotifications = () => {
  // const { release: releaseId } = useScope();
  const anchorRef = useRef<any>();
  const [menuVisible, setMenuVisible] = useState(false);
  const { jobs, inProgress, documentsWithErrors } = useReleaseJobs();

  const errors = Object.keys(documentsWithErrors).length;
  const count = jobs.filter(j => j.status === "queued").length;
  const hasErrors = errors > 0;

  // const tooltipText = errors ? (
  //   <Typography color={"error"}>
  //     {errors} document{errors === 1 ? "" : "s"} with errors
  //   </Typography>
  // ) : compilingDocuments ? (
  //   <span>
  //     {compilingDocuments} document{compilingDocuments === 1 ? "" : "s"} compiling
  //   </span>
  // ) : "Notifications";

  const tooltipText = "Notifications";
  // console.log('job count: ', jobs, count);
  // invisible={count === 0}
  return (
    <>
      <Tooltip title={tooltipText}>
        <Badge overlap="circular" variant={hasErrors && count === 0 ? "dot" : "standard"} badgeContent={count} showZero={hasErrors} color={hasErrors && count === 0 ? "error" : "primary"}>
          <IconButton
            color="inherit"
            ref={anchorRef}
            className={clsx(styles.square, { [styles.loading]: inProgress })}
            onClick={() => setMenuVisible((visible) => !visible)}
          >
            <NotificationIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        open={menuVisible}
        onClose={() => setMenuVisible(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorRef.current}
        style={{ maxHeight: 500 }}
      >
        <Box
          className={styles.container}
          minWidth={350}
          maxWidth={350}
          minHeight={100}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          {jobs && jobs.length > 0 ? (
            jobs.map((job, i) => {
              return (
                <Box
                  fontSize={15}
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  gridGap={"0.1rem"}
                  className={clsx(styles.item, styles[job.status])}
                  borderTop={i !== 0 ? "1px solid #ededed" : undefined}
                  key={i}
                >
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Typography variant="caption" className={styles.type}>
                      {job.description}
                    </Typography>

                    <Typography variant="caption" className={styles.type}>
                      {toTitleCase(job.status)}
                    </Typography>
                  </Box>
                  <Tooltip
                    placement={"bottom-start"}
                    title={job.title || job.documentName}
                  >
                    <Typography noWrap className={styles.name}>
                      {job.title}
                    </Typography>
                  </Tooltip>


                  <Box
                    fontSize={12}
                    flexDirection={"row"}
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Tooltip title={`${new Date(job.started).toLocaleString()}`} placement="right">
                      <div>
                        {job.started && (
                          <DurationSince
                            verb={job.updated ? "updated" : "started"}
                            value={Date.now() - (job.updated || job.started)}
                          />
                        )}
                      </div>
                    </Tooltip>
                    <Tooltip title={job.user?.full_name} placement="left">
                      <Avatar src="/" alt={job.user?.full_name} size="xsmall" />
                    </Tooltip>
                  </Box>

                </Box>
              );
            })
          ) : (
            <i>No notifications</i>
          )}
        </Box>
      </Popover>
    </>
  );
};
