import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ClearIcon } from '@icons';
import { getSize } from './util/UtilStyling';
import { system } from 'theme';

export const Badge = styled.span`
  --size: ${p => getSize(p.size)};
  min-width: var(--size);
  line-height: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  font-size: 0.75rem;
  text-align: center;
  color: ${p => p.theme.palette.primary.contrastText};
  background-color: ${p => p.theme.palette.primary.main};
  padding: ${p => (p.children.toString().length > 1 ? '0 calc(var(--size) / 4)' : 0)};
`;

export const Label = styled.span`
  // use fontsize, lineheight, padding to make total height of 1.5rem
  /* height: 1.5rem; */
  border-radius: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-align: center;
  color: ${p => p.theme.palette.primary.contrastText};
  background-color: ${p => p.theme.system[p.color] ?? p.color};
  padding: 0.25rem 0.365rem;
  white-space: nowrap;
  cursor: default;
`;

export const FlexLabel = styled(Label)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const ExperimentalLabel = () => (
  <Tooltip title="This feature is experimental and may not work as expected">
    <FlexLabel color={system.trafficAmber10} style={{ color: system.trafficAmber, border: `1px solid ${system.trafficAmber}` }}>
      <span>Experimental</span>
    </FlexLabel>
  </Tooltip>
);

export const Tag = props => {
  return <Chip deleteIcon={<ClearIcon />} {...props} />;
};

export const Stack = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Flex = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const TextLabel = styled(Typography)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  width: 6rem;
  flex-shrink: 0;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const DocumentLink = styled(Typography)`
  padding: 0.5rem 0;
  cursor: pointer;
  text-decoration: underline;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
`;
