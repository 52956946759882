import React, { useState, useEffect } from "react";
import { useGraphVisualisation } from "./hooks/useGraphVisualisation";
import { RuleGraphVizBaseProps } from "./types/RuleGraphViz";
import { useFullRelease } from "@common/hooks_useFullRelease";
import NoRecords from "@pages/NoRecords";
import RuleGraphProvider from "./providers/RuleGraphProvider";
import styled from "styled-components";
import { Resizable } from "@common";
import GraphToolbar from "./components/GraphToolbar/GraphToolbar";
import Classic from "./components/Classic";
import Nebula from "./components/Nebula";
import Data from "./components/Data/Data";
import AttributeInfo from "./components/AttributeInfo/AttributeInfo";
import Report from "./components/Report";

const Container = styled.div`
  /* display: flex; */
  /* flex-flow: column; */
  /* flex: 1; */
  height: 100%;
  background-color: ${p => p.theme.palette.background.default};
  overflow: hidden !important;
  position: relative;
  width: 100%;
`;

const VisualisationArea = styled(Resizable)`
  height: calc(100% - 4.5rem) !important;
`;
const Pane = styled.div`
  height: 100%;
`;

export interface GraphVizTypeInfo {
  id: string;
  nameTranslationKey: string;
  component: React.ComponentType<any>;
}

// import stl from "./GraphViz.module.scss";

// TODO we'll see what from here I move into RuleGraphVizBaseProps
interface GraphVizProps {
  /** Show/hide the graph toolbar. Default - true */
  showToolbar?: boolean;
  graph: any;
  debug?: boolean;
  hasClassic?: boolean;
  hasNebula?: boolean;
  hasDecisionReport?: boolean;
  hasData?: boolean;
  hasFocus?: boolean;
  report?: any;
  goal?: any;
  extraVizTypes?: GraphVizTypeInfo[];
}

export type WithGraphCoreSplitProps = React.PropsWithChildren<{
  className?: string;
}>;
/**
 * when we work with sessions at "/monitor/overview" route, we need to\
 * be able to show Data tab outside of GraphCore, but also have the\
 * right sidebar opened when we select an attribute. And in order to do\
 * that the best option seems to extract only the part with SplitPane\
 * into a separate component and reuse it both in GraphCore and then in\
 * "/monitor/overview". \
 * This component is the result of such extraction.
 */
export const WithGraphCoreSplit: React.FC< WithGraphCoreSplitProps > = p => {
  const { children, className } = p;
  const { selectedNode } = useGraphVisualisation();

  const resizerProps = React.useMemo(() => ({
    defaultSize: "70%",
    pane1Style: selectedNode ? { minWidth: "50%", maxWidth: "75%" } : { minWidth: "100%", maxWidth: "100%" },
    pane2Style: selectedNode ? { minWidth: "25%", maxWidth: "50%" } : { minWidth: "0%", maxWidth: "0%" },
  }), [selectedNode]);

  return (
    <VisualisationArea split="vertical" className={className} {...resizerProps}>
      <Pane style={{ display: "flex" }}>
        {children}
      </Pane>
      <Pane>
        {selectedNode ? <AttributeInfo /> : null}
      </Pane>
    </VisualisationArea>
  )
}
export const GraphCore = () => {
  const { graphVizType, extraVizTypes } = useGraphVisualisation();

  const renderViz = (report) => {
    switch (report) {
      case 'classic':
        return <Classic />;
      case "classic-beta":
        return <Classic />;
      case 'nebula':
        return <Nebula />;
      case 'data':
        return <Data />;
      case 'decisionreport':
        return <Report />
      default:
        const extraVizType = extraVizTypes?.find(vizType => vizType.id === report);
        if (extraVizType) {
          const { component: VizComponent } = extraVizType;
          return <VizComponent />;
        }

        return (
          <div>
            <br />
            <br />
            Unknown visualization type: <strong>{report}</strong>
          </div>
        )
    }
  };

  return (
    <Container>
      <GraphToolbar />
      <WithGraphCoreSplit>
        {renderViz(graphVizType)}
      </WithGraphCoreSplit>
    </Container>
  )
}

const GraphViz = (props: (GraphVizProps & RuleGraphVizBaseProps)) => {

  const {
    showToolbar = true,
    graph,
    debug = false,
    hasClassic,
    hasNebula,
    hasDecisionReport = false,
    hasData,
    report,
    goal,
    extraVizTypes
  } = props;
  const release = useFullRelease();
  if (!release) return null; // There is a period when the release is loading, so don't showing anything

  if (!graph) {
    return (
      <NoRecords
        title='No Graph Detected'
        body="Either you haven't started modelling, or an error has occurred. If the latter, please contact support."
      />
    );
  }

  return (
    <RuleGraphProvider
      goal={goal}
      report={report}
      debug={debug}
      graph={graph}
      hasNebula={hasNebula}
      hasData={hasData}
      hasClassic={hasClassic}
      hasDecisionReport={hasDecisionReport}
      extraVizTypes={extraVizTypes}
    >
      <GraphCore />
    </RuleGraphProvider>
  );
};

export default (GraphViz);
