import React, { useMemo } from "react";
import type { Release } from "@packages/commons";
import { useLocalStorage } from "react-use";
import graphlib from "@dagrejs/graphlib";

export interface ReleaseContextState {
  release: Release;
  highlightConnectedTo: string | undefined;
  setHighlightConnectedTo: (value: string | undefined) => void;
  highlightConnectedToDistances: { [node: string]: graphlib.Path } | undefined;
}

const ReleaseContext = React.createContext<ReleaseContextState | undefined>(undefined);

export const useRelease = () => {
  const context = React.useContext(ReleaseContext);
  if (context === undefined) {
    throw new Error("useRelease must be used within a ReleaseContext");
  }
  return context;
};

export interface ReleaseContextProviderProps {
  release: Release;
  children: React.ReactNode;
}

const ReleaseContextProvider = (props: ReleaseContextProviderProps) => {
  const { release, children } = props;

  const [highlightConnectedTo, setHighlightConnectedTo] = useLocalStorage<string | undefined>(
    `highlightConnectedTo-${release?.id}`,
    undefined,
  );

  const highlightConnectedToDistances = useMemo(() => {
    if (!highlightConnectedTo || !release?.rule_graph) {
      return undefined;
    }
    const parsedGraph = graphlib.json.read(release.rule_graph);
    if (!parsedGraph.node(highlightConnectedTo)) {
      setHighlightConnectedTo(undefined);
      return undefined;
    }

    return graphlib.alg.dijkstra(parsedGraph, highlightConnectedTo);
  }, [highlightConnectedTo, release?.rule_graph, setHighlightConnectedTo]);

  return (
    <ReleaseContext.Provider
      value={{
        release,
        setHighlightConnectedTo,
        highlightConnectedTo,
        highlightConnectedToDistances,
      }}
    >
      {children}
    </ReleaseContext.Provider>
  );
};

export default ReleaseContextProvider;
