import type React from "react";

import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_LI,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  type HotkeyPlugin,
  KEYS_HEADING,
  LinkElement,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  Plate,
  PlateFloatingLink,
  type PlatePlugin,
  type TEditableProps,
  createAutoformatPlugin,
  createBoldPlugin,
  createDeserializeDocxPlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createParagraphPlugin,
  createPlateUI,
  createPlugins,
  createStrikethroughPlugin,
  createTablePlugin,
  createUnderlinePlugin,
  toggleMark,
  withPlaceholders,
} from "@udecode/plate";

import { createBannerPlugin } from "../components/banner";
import { headingEnter, paragraphEnter, paragraphShiftEnter } from "../components/paragraph/plugins";
import { createTabPlugin } from "../plugins/hotkeys";
import { type HotkeysPlugin, formatList, preFormat, withHotKeys } from "../plugins/util";
import RuleEditorDecisionFlowGraph from "@components/flow/RuleEditorDecisionFlowGraph";

const exitBreak = {
  options: {
    rules: [
      {
        hotkey: "mod+enter",
      },
      {
        hotkey: "mod+shift+enter",
        before: true,
      },
      {
        hotkey: "enter",
        query: {
          start: true,
          end: true,
          allow: [...KEYS_HEADING],
        },
      },
    ],
  },
};

const placeholders = [
  {
    key: ELEMENT_PARAGRAPH,
    placeholder: "Type a paragraph",
  },
  {
    key: ELEMENT_H1,
    placeholder: "Heading 1",
  },
  {
    key: ELEMENT_H2,
    placeholder: "Heading 2",
  },
  {
    key: ELEMENT_H3,
    placeholder: "Heading 3",
  },
  {
    key: ELEMENT_H4,
    placeholder: "Heading 4",
  },
  {
    key: ELEMENT_H5,
    placeholder: "Heading 5",
  },
  {
    key: ELEMENT_H6,
    placeholder: "Heading 6",
  },
];

const autoFormat = {
  options: {
    rules: [
      {
        mode: "block",
        type: ELEMENT_H1,
        match: "# ",
        preFormat,
      },
      {
        mode: "block",
        type: ELEMENT_H2,
        match: "## ",
        preFormat,
      },
      {
        mode: "block",
        type: ELEMENT_H3,
        match: "### ",
        preFormat,
      },
      {
        mode: "block",
        type: ELEMENT_H4,
        match: "#### ",
        preFormat,
      },
      {
        mode: "block",
        type: ELEMENT_H5,
        match: "##### ",
        preFormat,
      },
      {
        mode: "block",
        type: ELEMENT_H6,
        match: "###### ",
        preFormat,
      },
      {
        mode: "block",
        type: ELEMENT_LI,
        match: ["* ", "- "],
        preFormat,
        format: (editor) => formatList(editor, ELEMENT_UL),
      },
      {
        mode: "block",
        type: ELEMENT_LI,
        match: ["1. ", "1) "],
        preFormat,
        format: (editor) => formatList(editor, ELEMENT_OL),
      },
      {
        mode: "mark",
        type: MARK_BOLD,
        match: ["**", "__"],
        preFormat,
        format: (editor) => toggleMark(editor, { key: MARK_BOLD }),
      },
      {
        mode: "mark",
        type: MARK_ITALIC,
        match: ["*", "_"],
        preFormat,
        format: (editor) => toggleMark(editor, { key: MARK_ITALIC }),
      },
      {
        mode: "mark",
        type: MARK_STRIKETHROUGH,
        match: ["--"],
        preFormat,
        format: (editor) => toggleMark(editor, { key: MARK_STRIKETHROUGH }),
      },
    ],
  },
};

let components = createPlateUI({
  [ELEMENT_LINK]: LinkElement,
});

components = withPlaceholders(components, placeholders);

export const createSourcePlugins = (plugins: PlatePlugin[] = []) =>
  createPlugins(
    [
      // @ts-ignore
      createHeadingPlugin<HotkeyPlugin & HotkeysPlugin>({
        ...withHotKeys([headingEnter]),
      }),
      // @ts-ignore
      createParagraphPlugin<HotkeyPlugin & HotkeysPlugin>({
        ...withHotKeys([paragraphEnter, paragraphShiftEnter]),
      }),
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin({
        options: {
          hotkey: "mod+shift+x",
        },
      }),
      createListPlugin(),
      createLinkPlugin({
        renderAfterEditable: PlateFloatingLink,
      }),
      createTablePlugin(),
      createTabPlugin(),
      createDeserializeDocxPlugin(),
      createBannerPlugin(),
      createExitBreakPlugin(exitBreak),
      // @ts-ignore
      createAutoformatPlugin(autoFormat),
      ...plugins,
    ],
    {
      components,
    },
  );

export type EditorProps = React.PropsWithChildren & {
  id: string;
  editableProps?: TEditableProps;
};

export const SourceEditor = ({ id, editableProps, children }: EditorProps) => {
  return (
    <Plate
      id={id}
      editableProps={editableProps}
    >
        <RuleEditorDecisionFlowGraph
          sectionId={"global"}
        />
      {children}
    </Plate>
  );
};
