import { getAuthHook } from '@imminently/imminently_platform';

// Note these 'hooks' are not react hooks, they are just functions that return a boolean
// Using a proper 'hook' was causing issues with react router

export enum Roles {
  // Core Roles
  ProjectReader = 'project-reader',
  ProjectWriter = 'project-writer',
  ProjectTechnical = "project-technical",
  Tester = 'tester',
  ReportViewer = 'report-viewer',
  // Additional Permission Roles (add these on top of core roles)
  Decision = 'decision',
  ReleaseManager = 'release-manager',
  ContactsReadAll = 'contacts-read-all',
  ContactsWriter = 'contacts-writer',
  TaskReadAll = 'task-read-all',
  TaskRead = 'task-read',
  BotReader = "bot-reader",
  BotWriter = "bot-writer",
  FileReader = "file-reader",
  // System
  System = "system",
  // Admin Roles
  Owner = 'owner',
  SuperAdmin = 'superadmin',
  // EdWard Roles
  TimesheetReader = "timesheet-reader",
  TimesheetWriter = "timesheet-writer",
  TimesheetManager = "timesheet-manager",
  TimesheetAnalytics = "timesheet-analytics",
  TimesheetSystem = "timesheet-system",
  TimesheetAdmin = "timesheet-admin"
};

export const ALL_ROLES = Object.values(Roles);
export const ADMIN_ROLES = [Roles.Owner, Roles.SuperAdmin];
export const SUPER_ADMIN = [Roles.SuperAdmin];
export const PROJECT_ROLES = [Roles.ProjectReader, Roles.ProjectWriter, Roles.ProjectTechnical];

const useRoles = () => {
  const { user } = getAuthHook();
  return user?.roles ?? [];
};

const authorise = (role: string | string[]) => {
  const userRoles = useRoles();
  const roles = Array.isArray(role) ? role : [role];
  return roles.some(role => userRoles.includes(role));
};

export const useIsSuperAdmin = () => {
  return authorise([Roles.SuperAdmin]);
};

export const useIsAdmin = () => {
  return authorise(ADMIN_ROLES);
};

export const useIsOwner = () => {
  return authorise([Roles.Owner]);
};

export const useIsProjectWriter = () => {
  return authorise([Roles.ProjectWriter]);
};

export const useIsProjectTechnicalWriter = () => {
  return authorise([Roles.ProjectWriter, Roles.ProjectTechnical]);
}

export const useHasRole = (role: string | string[]) => {
  return authorise(role)
};

export const useAuthorise = () => {
  return (role: string | string[]) => authorise(role)
};