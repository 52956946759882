import React from "react";
import * as DatesFilterNS from "./DatesFilter";
import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { RawRuleGraph } from "@packages/commons";
import dagre from "dagre";
import get from "lodash/get";
import GraphView from "@components/GraphView/GraphView";
import GraphNode from "@components/RuleGraph_FullRelease/components/GraphNode";
import { isMac } from "@components/kdb";
import GraphPallet from "@components/RuleGraph_FullRelease/components/GraphPallet";
import { useGraphGoalNodesOrdered } from "@pages/models/release/GraphContext";


// const escapeParentheses = (s: string) => s.replaceAll("(", "\\(").replaceAll(")", "\\)");

export interface RuleGraphFullReleaseProps {
  rawGraph: RawRuleGraph;
  // // defaultNode: string;
  onNodeClick: (path: string, v: any) => unknown;
  onNodeContextClick?: (v: any) => unknown;
  onNodeCtrlClick?: (v: any) => unknown;
  datesFilter?: DatesFilterNS.Props["value"];
  setDatesFilter?: DatesFilterNS.Props["set"];
  showGoalToolbar?: boolean;
  showHidden?: boolean;
  refitKey?: string | number;
}


export const RuleGraphFullRelease: React.FC<RuleGraphFullReleaseProps> = React.memo((props) => {



  const release = useFullRelease();

  const {
    rawGraph,
    showHidden,
    onNodeClick,
    onNodeCtrlClick,
    // datesFilter,
  } = props;
  // const showHidden = true;
  // console.log("rawGraph", rawGraph);


  const {
    setVisibleNodes,
    restrictedView,
    nodeRenderLimit,
    //setRestrictedView,
    goal: currentGoal,
  } = useGraphVisualisation();
  const refitKey = `${release?.id}_${restrictedView?.rootPath}`;



  const {
    selectedNode,
    selectedNodeId,
    graphVizType,
  } = useGraphVisualisation();

  return <>
    <GraphView graph={rawGraph}
               restrictedView={restrictedView?.graphDepth? restrictedView : undefined}
               nodeComponent={GraphNode}
               onNodeClick={onNodeClick}
               showHidden={showHidden}
               onNodeCtrlClick={onNodeCtrlClick}
               selectedNode={selectedNodeId || undefined}
               refitKey={refitKey}
               nodeRenderLimit={nodeRenderLimit}
               goal={currentGoal?.id}
               onVisibleNodesChange={setVisibleNodes}>
      <GraphPallet />
    </GraphView>

  </>;
});
RuleGraphFullRelease.displayName = "components/RuleGraphFullRelease";



// collect all the predecessors and successors down/up to the depth graphDepth
// I think probably don't do this recursively, but iteratively to avoid any stack issues on large graphs
// const allPredecessors: string[] = [];
// const allSuccessors: string[] = [];
// const collectPredecessors = (node, depth) => {
//   if (depth === 0) return;
//   const predecessors = (paredRawGraph.predecessors(node) || []) as unknown as string[];
//   allPredecessors.push(...predecessors);
//   predecessors.forEach(n => collectPredecessors(n, depth - 1));
// };
// const collectSuccessors = (node, depth) => {
//   if (depth === 0) return;
//   const successors = (paredRawGraph.successors(node) || []) as unknown as string[];
//   allSuccessors.push(...successors);
//   successors.forEach(n => collectSuccessors(n, depth - 1));
// };

// DO NOT POST MESSAGES BACK TO THE GRAPH COMPONENT...
// React.useEffect(() => {
//   const onMessage = (e: MessageEvent) => {
//     const msg = e.data;
//     if (msg.type === "graphShowHideAdditionalNodes") {
//       const {
//         srcNodeId,
//         additionalNodeIds,
//         action,
//       } = msg;
//       if (action === "show") {
//         setElementsState((state) => {
//           if (state.mode === "initializing" || state.mode === "refresh") {
//             return (state);
//           }
//           return ({ ...state, mode: "refresh" }) as any;
//         });

//         console.log(`Showing ${(additionalNodeIds || []).length} nodes for ${srcNodeId}`);

//         const msgToWorker: MsgToWorker = {
//           rawGraph,
//           showHidden,
//           typeFunc: typeFunc === undefined ? undefined : "debugGraph",
//           restrictedView: true ? {
//             rootPath: srcNodeId, // safe to force because it's tested in isGraphViewRestricted
//             graphDepth: restrictedViewDepth ?? 1,
//             additionalVisibleNodes: additionalNodeIds || [],
//           } : undefined,
//         };
//         worker.postMessage(msgToWorker);

//       } else if (action === "hide") {
//         console.log("ERR 54215:: hide nodes not implemented yet...")
//       }
//     }
//   };
//   window.addEventListener("message", onMessage);

//   return () => {
//     window.removeEventListener("message", onMessage);
//   };
// }, []);