import { findNode, GraphNode, NodeElement, ParsedRuleGraph, Trigger } from "@packages/commons";
import { forEach } from "lodash";
import { graphlib } from "dagre";
import { validate as uuidValidate } from "uuid";

export interface FlowStep {
  id: string;
  containment: string;
  triggers : FlowTrigger[]
}

export interface FlowTrigger {
  input: string;
  actions: Trigger[];
  required?: boolean;
  decisive?: boolean;
  complex?: boolean;
}

export type TriggerStatus = "requiredDecisive" | "requiredNotDecisive" | "optionalDecisive" | "optionalNotDecisive";

export const getTriggerStatus = (trigger: FlowTrigger): TriggerStatus => {
  if (trigger.required) {
    return trigger.decisive ? "requiredDecisive" : "requiredNotDecisive";
  } else {
    return trigger.decisive ? "optionalDecisive" : "optionalNotDecisive";
  }
}

const findNodeWithDefault: typeof findNode = (graph, documents, descriptionOrId): GraphNode | undefined => {
  if (!descriptionOrId) return undefined as any;

  let node = findNode(graph, documents, descriptionOrId);
  if (!node && !uuidValidate(descriptionOrId)) {
    node = {
      description: descriptionOrId,
    } as GraphNode;
  }
  return node;
};

/*
const convertDecisionFlow = (flow, graph, docName, sectionId, existing) => {
  const rules: any[] = [];
  for (const n of flow.nodes()) {
    const block = flow.node(n);
    switch (block.type) {
      case "trigger": {
        if (!block.triggers) continue;
        // Check if the containment isn't defined elsewhere (which means we can't put it here - so this is an error state)
        let node = findNode(graph, undefined, block.containment);
        if (node) {
          // Check if this is defined in this section - which means we can preserve it
          const conc = findConclusion(node.description, existing);

          if (node.definedIn === docName && conc) {
            // it's defined here - for now I think we don't make any changes and just leave it as is
            rules.push(conc);
          } else {
            //console.log('not found', conc, block.containment, existing, node)
            // It's defined elsewhere - error state
            rules.push({
              type: "p",
              text: `Invalid flow: The rules for ${block.containment} task is defined already in another document or section. Either you need to change the name or bring those rules back into this rule block`,
            });
          }
        } else {
          const conclusion = {
            type: "conclusion",
            text: block.containment,
            sectionId,
            children: [
              {
                type: "operator",
                operator: block.required ? "all" : "any",
                children: block.triggers.map((t) => {
                  const input = findNodeWithDefault(graph, undefined, t.input);
                  if (input) {
                    return {
                      type: "condition",
                      text: block.required ? input.description : `not(${input.description})`,
                    };
                  }
                }),
              },
            ],
          };
          for (const t of block.triggers) {
            for (const out of t.actions) {
              const node = findNodeWithDefault(graph, undefined, out.outcome);
              if (node) {
                conclusion.children[0].children.push({
                  type: "condition",
                  text: block.decisive ? node.description : `${node.description} is known`,
                });
              }
            }
          }

          rules.push(conclusion);
        }
        // We need to add the input attributes, check if it doesn't already exist
        for (const t of block.triggers) {
          node = findNodeWithDefault(graph, undefined, t.input);
          // TODO: we need to check that the definition isn't inside this section (which means we can overwrite/merge)
          if (node && !node.id) {
            // We have to add it
            // Check to see if this trigger is connected to something (which would then be the condition for this)
            const parents = flow.predecessors(n);
            if (parents && parents.length > 0) {
              const conclusion: any = {
                type: "conclusion",
                text: node.description,
                sectionId,
                children: [
                  {
                    type: "operator",
                    operator: "all",
                    children: [],
                  },
                ],
              };
              for (const p of parents) {
                const node = flow.node(p);
                switch (node.type) {
                  case "trigger":
                    conclusion.children[0].children.push({
                      type: "condition",
                      text: findNodeWithDefault(graph, undefined, node.containment)?.description,
                    });
                    break;
                  default:
                    console.log(`Unknown parent trigger ${node.type}`);
                }
              }
              rules.push(conclusion);
            } else {
              rules.push({
                type: "conclusion",
                text: `${node.description} = true`,
                sectionId,
              });
            }
          } else if (node?.definedIn === docName) {
            // Check that to see if the attr is defined within this section, if so we can merge
            // Otherwise it's effectively as if it was defined in another document
            const conc = findConclusion(node?.description, existing);
            if (conc) {
              // It's defined in this section, so we return it as is
              rules.push(conc);
            }
          } // It already exists so we don't need to do anything
        }
        break;
      }
      case "attribute":
        // Ignore: this handled in the trigger
        break;
      default:
        console.log(`Unknown block type in flow: ${block.type}`);
    }
  }
  return rules;
};
*/


const findLCA = (searchNodes, graph) => {
  // https://www.baeldung.com/cs/lowest-common-ancestor-acyclic-graph
  // Algo
  // From each node mark a graph that contains it's parents
  // Then determine the intersection of these nodes
  const commonGraph = new graphlib.Graph();
  const traverseParents = (mark, node, connect = true) => {
    const parents = graph.predecessors(node);
    if (parents && parents.length > 0) {
      for (const p of parents) {
        // Already marked?
        const common: any = commonGraph.node(p);
        if (!common) {
          commonGraph.setNode(p, {
            id: p,
            mark: [mark],
          });
        } else {
          common.mark.push(mark);
          commonGraph.setNode(p, common);
        }
        if (connect) commonGraph.setEdge(p, node);
        // Now process my ancestors
        traverseParents(mark, p);
      }
    }
  };
  for (const n of searchNodes) {
    traverseParents(n, n, false);
  }

  // Now strip all nodes that aren't a common ancestor to all these nodes
  for (const n of commonGraph.nodes()) {
    const node = commonGraph.node(n);
    // @ts-ignore
    if (node.mark.length !== searchNodes.length) commonGraph.removeNode(n);
  }

  // Now the graph only contains those nodes that are a common ancestor;
  const lowest = commonGraph.sinks();
  // There could be multiple lowest ancestors, so return all of them in that case - the calling algo can determine what to do
  if (lowest && lowest.length === 1) return lowest[0];
  else return lowest;
};

export const generateFlowGraph = (graph: ParsedRuleGraph, sectionId) => {
  const flow = new graphlib.Graph();
  //let distance = Graph.alg.dijkstraAll(graph);
  // Find all attribute that have triggers and process them
  for (const n of (graph?.nodes() || [])) {
    const node = graph.node(n);
    // Only include nodes in this section
    if (node?.triggers && (!sectionId || node.sectionId === sectionId)) {
      //console.log('check', node)
      // It has triggers, which are a core part of the flow, lets add them

      // We need to determine if this already exists, but to do so we need the containment. But to get the containment we need the LCA.
      /*
      let element = {
        type: 'trigger',
        triggers: [],
        outputs: []
      }*/
      const searchNodes = [node.id];
      const actions: Trigger[] = [];

      for (const t of node.triggers) {
        // Only handle tasks right now
        const outcome = findNode(graph, undefined, t.outcome);
        actions.push({
          outcome: outcome?.id,
          ...t,
        });
        if (outcome) {
          searchNodes.push(outcome.id);
        }
      }
      //if (searchNodes.length < 2) continue; // This trigger has no outcomes we could find. Won't be able to find an LCA and thus won't get a containment - bad rules most likely
      // Now find the lowest common ancestor of these nodes. This will be the containment node
      const commonParent = findLCA(searchNodes, graph);
      if (Array.isArray(commonParent)) {
        console.log("TODO: handle multiple LCA", commonParent, searchNodes);
      } else if (!commonParent) {
        console.log("TODO: handle no common parent");
      }
      // We have a common parent - this is the containment attribute
      // Now check if this exists in the flow already
      let element: any = flow.node(commonParent);
      // If it doesn't exist yet or it was previously not a trigger (most likely an attribute) then reset to look like a blank trigger
      if (!element || element.type !== "trigger")
        element = {
          type: "trigger",
          triggers: [],
        };
      element.triggers.push({
        input: node.id,
        actions: actions,
      });
      element.containment = commonParent;
      // Determine if required/decisive or complex
      // We can only know this if the rules are designed the way we'd expect
      // Otherwise complex
      // @ts-ignore
      const containment = graph.node(commonParent);

      if (!containment) {
        console.log("TODO: handle containment not found", commonParent);
        continue;
      }

      let isValid = false;
      let required: any;
      let decisive: any;
      //console.log('el', JSON.stringify(element, null, 2));
      if (containment.conditions) {
        const traverse = (attr: string, el: Partial<NodeElement>, parent?: any) => {
          if (el.type === "attribute" && el.attributeId === attr) return parent;
          else if (el.elements) {
            let outcome: any;
            forEach(el.elements, (subEl) => {
              const sub = traverse(attr, subEl, el);
              if (sub) {
                // Send the parent
                outcome = sub;
                return false;
              }
            });
            return outcome;
          }
        };

        for (const t of element.triggers) {
          t.required = false;
          t.decisive = false;
          t.complex = false;

          // Check if the input is required or not
          // Find the definition of this input within the conditions
          const parentRule = traverse(t.input, { elements: containment.conditions });

          if (parentRule && parentRule.type === "not") t.required = false;
          else if (parentRule && (parentRule.type === "or" || parentRule.type === "and")) t.required = true;
          else t.complex = true;

          // Now to determine decisive we need to check the outcomes, which there could be multiple of
          for (const a of t.actions) {
            const parentRule = traverse(a.outcome, { elements: containment.conditions });
            if (parentRule && parentRule.type === "known") t.decisive = false;
            else if (parentRule && (parentRule.type === "or" || parentRule.type === "and")) t.decisive = true;
            else t.complex= true;
          }

        }
        if (typeof required !== "undefined" && typeof decisive !== "undefined") isValid = true;
      }
      /*
      if (containment.conditions) { // TODO: should we support decision tables?
        let operator = get(containment.conditions, '0.type');
        console.log('operator', operator, t);
        if (operator === 'and' || operator === 'or') {
          required = operator === 'and' ? true : false;
          let el1 = get(containment.conditions, '0.elements.0');
          let el2 = get(containment.conditions, '0.elements.1');

          // Check that the first entry is the input node
          if ((el1.type === 'not' && get(containment.conditions, `0.elements.0.elements.0.attributeId`) === t.input) || (el1.type === 'attribute' && el1.attributeId === t.input)) {
            if (element.outputs.length === 1) {
              // if they have multiple outputs the decisive is not possible to determine
              // Check the single output
              if (el2.type === 'known' && get(containment.conditions, `0.elements.0.elements.0.attributeId`) === element.outputs[0].attribute) {
                decisive = false;
              } else if (el2.type === 'attribute' && el2.attributeId === element.outputs[0].attribute) {
                decisive = true;
              }
              if (typeof decisive === 'boolean') isValid = true;
            }
          }
        }
      }*/

      //console.log('isvalid', isValid, required, decisive)

      element.id = element.containment;
      element.height = 100 + element.triggers.length * 200;
      flow.setNode(element.containment, element);
      // We also need to add any children to the 'input' attributes
      for (const t of element.triggers) {
        const children = graph.successors(t.input);
        if (children) {
          for (const c of children) {
            const node: any = flow.node(c);
            let id: string | undefined;
            if (node) {
              // It already exists - do nothing?
              if (node.type === "attribute") id = node.id;
              else if (node.type === "trigger") id = node.containment;
              else console.log("unknown node type in edge connection", node);
            } else {
              flow.setNode(c, {
                type: "attribute",
                height: Math.max(78 + 20, ((node?.description || "1").length / 32) * 17.5),
                id: c,
              });
              id = c;
            }
            //console.log('setting', id, node, element.containment)
            if (id) flow.setEdge(id, element.containment, t.input);
          }
        }
      }
    }
  }
  return flow;
};

/*const findConclusion = (concText, rules) => {
  let conc;
  const traverse = (el) => {
    if (el.type === "conclusion" && el.text.includes(concText)) {
      conc = el;
    } else if (el.children) {
      for (const c of el.children) {
        traverse(c);
      }
    }
  };
  if (rules && Array.isArray(rules)) {
    for (const r of rules) {
      traverse(r);
    }
  }
  return conc;
};*/
