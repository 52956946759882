import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showModal } from "@imminently/imminently_platform";
import FocusModal from "../components/FocusModal";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { useCurrentRelease } from "../../../common";
import type { GraphNode, Relationship, Release } from "@packages/commons";
import { RestrictedView } from "@components/GraphView/GraphView";
import { GraphVizTypeInfo } from "@components/GraphVisualisation/GraphViz";

// -- types (ideally we can re-home these somewhere else)

export type GraphVizType = "classic"
  | "classic-beta" // TODO we'll remove this and it'll become "classic"
  | "nebula";
export type GraphColorScheme = "by-type" | "by-value" | "by-entity";

export const lsNameLastVizMode = "dcsvly.lastVizMode";

// -- types - context

export interface RuleGraphProviderState {
  graphVizType: string;
  fullRelease: Release | null;
  release: Release | null;
  /** this is the graphlib.Graph graph data structure taken from the fullRelease object */
  graph: any;
  selectedNode: GraphNode | null;
  selectedNodeId: string | null;
  triggerFocus: any;
  debug: boolean;
  report: any;
  goal: any;
  searchText: string | null;
  colorScheme: GraphColorScheme;
  entities: string[];
  temporalSlice: any;
  /** this is only relevant if we're restricting the view for some reason, and need to share this state around */
  restrictedView: RestrictedView | null;
  /** provides an upper limit for the number of nodes to render when restrictedView is not being used */
  nodeRenderLimit: number;
  extraVizTypes: GraphVizTypeInfo[] | null;
}

export interface RuleGraphProviderCtx extends RuleGraphProviderState {
  setGraphVizType: (type: GraphVizType) => void;
  setFullRelease: (fullRelease: Release) => void;
  setSelectedNodeId: (id: string | null) => void;
  defaultView: string | null;
  hasClassic: boolean;
  hasNebula: boolean;
  hasData: boolean;
  hasDecisionReport: boolean;
  hasFocus: boolean;
  switchView: any,
  setSearchText: (text: string | null) => void;
  setColorScheme: (scheme: GraphColorScheme) => void;
  setTemporalSlice: any;
  rstVisibleNodes: () => void;
  addVisibleNodes: (nodes: string[]) => void;
  setVisibleNodes: (nodes: string[] | null) => void;
  restrictViewToDepth: (depth: number | null) => void;
  setRestrictedViewRootPath: (rootPath: string) => void;
  setRestrictedViewNodesToMerge: (nodes: string[] | null) => void;
  rstRestrictedView: () => void;
  setNodeRenderLimit: (limit: number) => void;
}

const defaultFn = () => {
  throw new Error("RuleGraphCtx not initialized");
};
const defaultProviderState: RuleGraphProviderCtx = {
  graphVizType: "classic",
  setGraphVizType: defaultFn,
  fullRelease: null,
  release: null,
  setFullRelease: defaultFn,
  graph: null,
  selectedNode: null,
  setSelectedNodeId: defaultFn,
  triggerFocus: defaultFn,
  defaultView: null,
  hasClassic: true,
  hasNebula: true,
  hasData: true,
  debug: false,
  hasDecisionReport: false,
  hasFocus: false,
  report: null,
  switchView: defaultFn,
  selectedNodeId: null,
  goal: null,
  searchText: null,
  setSearchText: defaultFn,
  colorScheme: "by-type",
  setColorScheme: defaultFn,
  entities: [],
  temporalSlice: null,
  setTemporalSlice: defaultFn,
  rstVisibleNodes: defaultFn,
  addVisibleNodes: defaultFn,
  setVisibleNodes: defaultFn,
  restrictedView: null,
  restrictViewToDepth: defaultFn,
  setRestrictedViewRootPath: defaultFn,
  setRestrictedViewNodesToMerge: defaultFn,
  rstRestrictedView: defaultFn,
  nodeRenderLimit: 200,
  setNodeRenderLimit: defaultFn,
  extraVizTypes: null,
};

// -- provider

export const RuleGraphCtx: React.Context<RuleGraphProviderCtx> = React.createContext(defaultProviderState);

export type RuleGraphProviderProps = Partial<Pick<RuleGraphProviderCtx, "fullRelease">>;

/**
 * NOTE: Do **not** use this directly. Use `useGraphVisualisation` instead.
 */
const RuleGraphProvider = (props: React.PropsWithChildren<any>) => {
  const {
    fullRelease: initialFullRelease,
    startNode,
    children,
    graph,
    goal,
    hasNebula = true,
    hasClassic = true,
    hasCustomReport = false,
    hasData = true,
    debug = false,
    hasDecisionReport = false,
    hasFocus = false,
    defaultView,
    report,
    onSelectedNodeChange,
    extraVizTypes,
  } = props;

  const dispatch = useDispatch();
  const release = useFullRelease();
  const [fullRelease, setFullRelease] = React.useState<Release | null>(initialFullRelease ?? defaultProviderState.fullRelease);
  const [selectedNodeId, setSelectedNodeId] = React.useState<string | null>(startNode?.path || startNode?.id || defaultProviderState.selectedNodeId);
  const [searchText, setSearchText] = React.useState<string | null>(defaultProviderState.searchText);
  const [colorScheme, setColorScheme] = React.useState<GraphColorScheme>(defaultProviderState.colorScheme);
  const [temporalSlice, setTemporalSlice] = React.useState(null);
  const [restrictedView, setRestrictedView] = React.useState<RuleGraphProviderState["restrictedView"]>(defaultProviderState.restrictedView);
  const [nodeRenderLimit, setNodeRenderLimit] = React.useState(defaultProviderState.nodeRenderLimit);

  const relationships = (useCurrentRelease()?.relationships) as Relationship[] | undefined;
  const entities = React.useMemo(() => {
    let nonGlobalEntityNames = (relationships || []).filter((it) => it.containment).filter((i) => (i?.name)).map((i) => (i.name)) as string[];
    // not likely nonGlobalEntityNames will contain "global", but remove it if it does
    nonGlobalEntityNames = nonGlobalEntityNames.filter((it) => it !== "global");
    return (["global", ...nonGlobalEntityNames]);
  }, [relationships]);

  const lastViewValid = (lastView) => {
    if ((lastView === 'classic' || lastView === 'classic-beta') && !hasClassic) return false;
    else if (lastView === 'nebula' && !hasNebula) return false;
    else if (lastView === 'data' && !hasData) return false;
    else if (lastView === 'decisionreport' && !hasDecisionReport) return false;
    else if (lastView === 'customreport' && !hasCustomReport) return false;
    return true;
  }
  const defaultGraphVizType = (() => {
    const lastView = localStorage.getItem(lsNameLastVizMode) as GraphVizType | null;
    //console.log('getting default type', defaultView, lastView, lastViewValid(lastView), hasClassic)

    if (lastView && lastViewValid(lastView)) {
      return (lastView);
    } else if (defaultView) {
      return defaultView;
    } else {
      return ("classic-beta");
    }
  })();
  const [graphVizType, setGraphVizType] = React.useState<GraphVizType>(defaultGraphVizType);

  const selectedNode = graph?.node(selectedNodeId);

  useEffect(() => {
    if (onSelectedNodeChange) onSelectedNodeChange(selectedNode);
  }, [selectedNodeId])

  // --

  const triggerFocus = React.useCallback(node => {
    dispatch(
      showModal(
        {
          title: "Explore",
          open: true,
          maxWidth: "xl",
          height: "100%",
          contentStyle: {
            padding: "2rem 2rem 0 2rem",
          },
        },
        <FocusModal release={release} graph={graph} goal={node} />,
      ),
    );
    // And switch to the tab area
  }, [release, graph, dispatch]);

  const switchView = React.useCallback((view, id) => {
    setGraphVizType(view);
    if (id) {
      setSelectedNodeId(id);
    }
  }, [setGraphVizType, graph]);

  useEffect(() => {
    if (graphVizType) {
      localStorage.setItem(lsNameLastVizMode, graphVizType)
    }
  }, [graphVizType])
  useEffect(() => {
    // @ts-ignore of course id exists
    if (startNode && selectedNode && startNode.id !== selectedNode.id) setSelectedNodeId(startNode.path || startNode.id);
  }, [startNode])
  /*
    React.useEffect(() => {
      console.log('f', fullRelease)
      if (fullRelease) {
        const { rule_graph } = fullRelease;
        setRawGraph(rule_graph);
      }
    }, [ fullRelease ]);*/

  // -- visible nodes

  const rstRestrictedView = () => setRestrictedView(null);

  const setVisibleNodes = (nodes: string[] | null) => {
    setRestrictedView((cs) => ({ ...(cs || {}), visibleNodes: nodes || null, nodesToMerge: null } as any));
  }
  const rstVisibleNodes = () => setVisibleNodes(null);
  const addVisibleNodes = (nodes: string[]) => {

    const currNodes = restrictedView?.visibleNodes || [];
    const deduplicatedNewNodes = nodes.filter((node) => !currNodes.includes(node));
    setVisibleNodes([...currNodes, ...deduplicatedNewNodes]);
  };

  const restrictViewToDepth = (depth: number | null) => {
    setRestrictedView((cs) => ({ ...(cs || {}), graphDepth: depth, visibleNodes: null, nodesToMerge: null } as any));
    rstVisibleNodes();
  }

  /**
   * Callback used because we were getting run-away re-renders
   */
  const setRestrictedViewRootPath = React.useCallback((rootPath: string) => {
    if (rootPath) {
      setRestrictedView((cs) => ({ ...(cs || {}), rootPath: rootPath || null, visibleNodes: null, nodesToMerge: null } as any));
    } else {
      rstRestrictedView();
    }
  }, [release, graph, dispatch]);

  const setRestrictedViewNodesToMerge = (nodes: string[] | null) => {
    setRestrictedView((cs) => ({ ...(cs || {}), nodesToMerge: nodes || null } as any));
  }

  // -- rendering

  return (
    <RuleGraphCtx.Provider value={{
      graphVizType,
      switchView,
      setGraphVizType,
      release: fullRelease,
      fullRelease,
      setFullRelease,
      triggerFocus,
      graph,
      selectedNode,
      selectedNodeId,
      setSelectedNodeId,
      defaultView,
      hasNebula,
      hasClassic,
      hasData,
      debug,
      hasDecisionReport,
      hasFocus,
      report,
      goal,
      searchText,
      setSearchText,
      colorScheme,
      setColorScheme,
      entities: entities || defaultProviderState.entities,
      temporalSlice,
      setTemporalSlice,
      setVisibleNodes,
      rstVisibleNodes,
      addVisibleNodes,
      restrictedView,
      restrictViewToDepth,
      setRestrictedViewRootPath,
      setRestrictedViewNodesToMerge,
      rstRestrictedView,
      nodeRenderLimit,
      setNodeRenderLimit,
      extraVizTypes: extraVizTypes || null,
    }}>
      {children}
    </RuleGraphCtx.Provider>
  );
};

export default (RuleGraphProvider);
