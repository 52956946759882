import { useFullRelease } from '@common/hooks_useFullRelease';
import { GraphCore } from '@components/GraphVisualisation/GraphViz';
import RuleGraphProvider from '@components/GraphVisualisation/providers/RuleGraphProvider';
import { useParsedGraph } from '@pages/models/release/GraphContext';
import ReleaseContextProvider from '@pages/models/release/ReleaseContextProvider';

export const VisualisationsPanel = ({ session }: { session: any }) => {
  const release = useFullRelease();
  const graph = useParsedGraph();
  const goalNode = graph.node(session.goal);
  const goal = goalNode ? goalNode.path || goalNode.id : session.goal;

  return (
    <RuleGraphProvider
      graph={graph}
      goal={goal}
      debug={true}
      hasNebula={true}
      hasData={true}
      hasDecisionReport={true}
    >
      {/* TODO remove me - legacy support */}
      <ReleaseContextProvider release={release as any}>
        <GraphCore />
      </ReleaseContextProvider>
    </RuleGraphProvider>
  );
};