import React, { useMemo } from "react";
import { ParsedRuleGraph, RawRuleGraph } from "@packages/commons";
import { parseRawGraph } from "../../../components/RuleGraph_FullRelease/__parseRawGraph";
import { useFullRelease } from "@common/hooks_useFullRelease";
import graphlib from "@dagrejs/graphlib";

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

/**
 * @deprecated Use the useGraph hook instead
 */
// @ts-ignore
const GraphContext = React.createContext();

export const useGraph = () => {
  const context = React.useContext(GraphContext);
  if (context === undefined) {
    return null;
    // unreachable...
    throw new Error("useGraph must be used within a GraphContext");
  }
  return (context ?? null) as RawRuleGraph; // TODO not great typing!!
};

export const ParsedGraphContext = React.createContext<ParsedRuleGraph>(null as unknown as ParsedRuleGraph);

/** A graph context provider that requires a full release and provides a fully parsed graph */
export const ParsedGraphContextProvider = ({ children }) => {
  const release = useFullRelease();

  const graph = useMemo<ParsedRuleGraph>(
    () => (release ? parseRawGraph(release.rule_graph) : new graphlib.Graph()),
    [release?.rule_graph],
  );

  return <ParsedGraphContext.Provider value={graph}>{children}</ParsedGraphContext.Provider>;
};

export const useParsedGraph = () => {
  const context = React.useContext(ParsedGraphContext);
  if (context === undefined) {
    throw new Error("useParsedGraph must be used within a ParsedGraphContext");
  }
  return context;
};

export const useGraphGoalNodesOrdered = (
  graph: ParsedRuleGraph | undefined,
  currentGoal: string | undefined,
  showHidden: boolean | undefined,
) => {
  return React.useMemo(() => {
    const nodes: any[] = [];
    if (graph) {
      for (const n of graph.nodes()) {
        const node = graph.node(n) as any;
        if (!node) {
          continue;
        }
        if (node.fk || node.identifier) {
          continue;
        }

        if (!showHidden && node.hidden) {
          continue;
        }

        //if (parsedGraph.successors(n).length === 0) return; // Only show goals and intermediate

        const cfg = {
          id: node.id,
          name: node.description,
          isGoal: !((graph.predecessors(n) || []).length > 0),
          path: node.path,
          entity: node.entity,
          isGlobal: node.entity === "global",
        };
        nodes.push(cfg);
      }
    }
    const goalNodes = {
      goal: nodes.filter((n) => n.isGoal),
      intermediate: nodes.filter((n) => !n.isGoal),
    };
    const flatGoals = [...goalNodes.goal, ...goalNodes.intermediate];

    if (currentGoal) {
      // if currentGoal is set, let's make sure that it's the first in the list, as we'll want to make sure it's visible
      const idx = flatGoals.findIndex((n) => n.id === currentGoal);
      if (idx > -1) {
        const currentGoalObj = flatGoals[idx];
        flatGoals.splice(idx, 1);
        flatGoals.unshift(currentGoalObj);
      }
    }

    return flatGoals;
  }, [graph, currentGoal, showHidden]);
};

export default GraphContext;
