import { getNodeType } from "@common/graph";
import { GlossaryIcon } from "@icons";
import { useParsedGraph } from "@pages/models/release/GraphContext";

import { AliasInfo } from "./AliasCard";
import type { AliasExpression, AttributeExpression, BaseExpression } from "@packages/compiler/src/Parser";
import AttributeSummaryCard, { Attribute } from "@components/AttributeSummaryCard/AttributeSummaryCard";

type AttributeInfoProps = {
  expression: AttributeExpression;
  parent: string;
};

export const AttributeInfo = ({ expression, parent }: AttributeInfoProps) => {
  const { attributeId } = expression;
  const graph = useParsedGraph();
  const node = graph.node(attributeId);

  return (
    <AttributeSummaryCard
      node={node}
      extraTabs={[
        expression.alias
          ? {
              value: "alias",
              label: "Alias",
              icon: <GlossaryIcon />,
              content: (
                <AliasInfo
                  expression={expression as BaseExpression as AliasExpression}
                  style={{ padding: 0 }}
                />
              ),
            }
          : null,
      ]}
    />
  );
};
