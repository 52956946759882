import { PropsWithChildren, ReactNode, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import styled from 'styled-components';
import { Command } from 'cmdk';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Input, Typography } from '@material-ui/core';
import { ClearIcon, LoadingDots, SearchIcon } from '@icons';
import { AuthUser, userService } from 'services';
import { Avatar } from '@components';
import { useSearch, UseSearchProps } from '@components/Search';
import { Popover, PopoverContent, PopoverContentProps, PopoverTrigger } from '../Popover/Popover';
import { scrollable } from '@common/scrollbar';
import { userToAssignee } from '@common';
import { AssigneesData } from '@packages/commons';
import '../styles.scss';

const mapUser = (user: AssigneesData) => ({
  ...user,
  value: user.identity_id,
  label: user.name,
});

const UserItem = ({ user, endIcon = null }: { user: any; endIcon?: ReactNode; }) => {
  return (
    <div className="UserItem">
      <Avatar size='small' alt={user.first_name} />
      <Typography variant='body1'>{user.label}</Typography>
      {endIcon}
    </div>
  );
};

/** @deprecated Use UserCombobox instead */
export const UserSelect = ({ value, onChange, children }) => {
  const [search, setSearch] = useState('');
  const { data, isLoading } = userService.useGetList({ meta: { first_name: `*${search}*` } });

  // const searchUsers = useCallback(debounce(name => {
  //   // console.log('search users debounced, searching...', name);
  //   dispatch(getUsers(name));
  // }, 300), [dispatch]);

  const onInputChange = v => {
    if (v !== search) {
      // console.log('search input changed', v);
      setSearch(v);
      // searchUsers(v);
    }
  };

  const addUser = user => {
    // debugger;
    onChange([...value, user]);
  };

  const removeUser = user => {
    onChange(value.filter(v => v.id !== user.id));
  };

  const users: AssigneesData[] = data?.data.map(u => userToAssignee(u as AuthUser)) ?? [];
  const selected = value.map(mapUser); // adding the value and label

  const options = users &&
    // @ts-ignore
    uniqBy(users, u => u.identity_id)
      .map(mapUser)
      .filter(u => !selected.find(s => s.id === u.id));

  console.log('user options', users, options);
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="SelectButton">{children}</button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="DropdownMenuContent" align="start">
          <div className="SearchContainer">
            <SearchIcon />
            <Input autoFocus disableUnderline className="SearchInput" type='text' placeholder='Search...' value={search} onChange={(e) => onInputChange(e.target.value)} />
          </div>
          <DropdownMenu.Separator className="DropdownMenuSeparator" />
          {
            selected && selected.length > 0 ? (
              <>
                <DropdownMenu.Label className="DropdownMenuLabel">Assignees</DropdownMenu.Label>
                <DropdownMenu.Group className="DropdownMenuGroup">
                  {selected.map((item, index) => (
                    <DropdownMenu.Item key={index} className="DropdownMenuItem" onSelect={() => removeUser(item)}>
                      <UserItem user={item} />
                      <div className="DropdownMenuItemIndicator">
                        <ClearIcon />
                      </div>
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Group>
              </>
            ) : null
          }
          {
            isLoading ? <LoadingDots /> : (
              <>
                <DropdownMenu.Label className="DropdownMenuLabel">People</DropdownMenu.Label>
                <DropdownMenu.Group className="DropdownMenuGroup">
                  {
                    options.map((item, index) => (
                      <DropdownMenu.Item key={index} className="DropdownMenuItem" onClick={() => addUser(item)}>
                        <UserItem user={item} />
                      </DropdownMenu.Item>
                    ))
                  }
                </DropdownMenu.Group>
              </>
            )
          }
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const PopperContent = styled(PopoverContent)`
  min-width: 300px;
  max-width: 300px;
`;

const CommandMenu = styled(Command)`
  outline: none;

  [cmdk-input] {
    font-family: inherit;
    border: 1px solid ${p => p.theme.palette.background.darkBorder};
    padding: 0.5rem 1rem;
    width: 100%;
    border-radius: 0.5rem;
    font-size: 1rem;
    outline: none;

    &:hover, &:focus, &:active, &:focus-within {
      border-color: ${p => p.theme.palette.background.dark};
    }
  }

  [cmdk-group-heading] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: inherit;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.75rem;
    padding: 0 1rem;
    text-transform: uppercase;
  }

  [cmdk-list] {
    max-height: 220px;
    margin: 0 -0.5rem;
  }

  [cmdk-item] {
    cursor: pointer;
    &[data-selected="true"] {
      background-color: ${p => p.theme.palette.secondary.hover};
    }
  }

  [cmdk-loading] {
    text-align: center;
    padding: 1rem;
  }

  [cmdk-empty] {
    text-align: center;
    padding: 1rem;
  }
`;

export type UserComboboxProps = {
  value: any[];
  onChange: (value: any[]) => void;
  searchProps?: UseSearchProps;
  closeOnSelect?: boolean;
  popover?: PopoverContentProps;
} & PropsWithChildren;

export const UserCombobox = ({ value, onChange, searchProps, closeOnSelect, popover = {}, children }: UserComboboxProps) => {
  const { filter: sFilter, ...search } = useSearch(searchProps);
  const { data, ...query } = userService.useGetList({
    meta: {
      email: sFilter.length > 0 ? sFilter : undefined,
    },
  });

  const total = data?.total;
  const [open, setOpen] = useState(false);

  const onOpenChange = (v: boolean) => {
    setOpen(v);
    // clear search on close
    if (!v) {
      search.setValue("");
    }
  }

  const addUser = user => {
    onChange([...value, user]);
  };

  const removeUser = user => {
    onChange(value.filter(v => v.id !== user.id));
  };

  // make sure to map into AssigneeData
  const users: AssigneesData[] = data?.data.map(u => userToAssignee(u as AuthUser)) ?? [];
  const selected = value.map(mapUser); // adding the value and label

  const options = users &&
    // @ts-ignore
    uniqBy(users, u => u.identity_id)
      .map(mapUser)
      .filter(u => !selected.find(s => s.identity_id === u.identity_id));

  const target = document.getElementsByClassName('MuiDialog-container')[0] as HTMLElement ?? document.body;

  return (
    <Popover modal={true} open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        {children}
      </PopoverTrigger>
      <PopperContent portal={{ container: target }} {...popover}>
        <CommandMenu shouldFilter={false}>
          <Command.Input autoFocus placeholder={search.placeholder} value={search.value} onValueChange={search.setValue} />
          <Command.List className={scrollable}>
            {
              query.isLoading
                ? <Command.Loading><LoadingDots /></Command.Loading>
                : total === 0
                  ? <Command.Empty>No results found.</Command.Empty>
                  : (
                    <>
                      {
                        selected && selected.length > 0 ? (
                          <>
                            <Command.Group heading="Assignees">
                              {selected.map((item, index) => (
                                <Command.Item key={index} value={item.identity_id} onSelect={() => removeUser(item)}>
                                  <UserItem user={item} endIcon={<ClearIcon />} />
                                </Command.Item>
                              ))}
                            </Command.Group>
                          </>
                        ) : null
                      }
                      <Command.Group heading="People">
                        {
                          options.map((item) => (
                            <Command.Item
                              key={item.id}
                              value={item.identity_id}
                              onSelect={() => {
                                addUser(item);
                                if (closeOnSelect) {
                                  onOpenChange(false);
                                }
                              }}>
                              <UserItem user={item} />
                            </Command.Item>
                          ))
                        }
                      </Command.Group>
                    </>
                  )
            }
          </Command.List>
        </CommandMenu>
      </PopperContent>
    </Popover>
  );
};