import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import cls from 'clsx';
import Divider from '@material-ui/core/Divider';
import { scrollableMixin } from '@common/scrollbar';
import { Search } from '../input';
import { ActionsComp as MenuActionsComp } from '../menuAction';
import { ChevrondownIcon, LoadingDots } from '../../icons';
import { useScrollable } from '@common/hooks/useScrollable';

const defaultActions = [];

export const classes = {
  '>search': 'search',

  list: {
    _: 'list',

    '>headingWrap': {
      _: 'list_headingWrap',

      '>heading': 'list_headW_heading',
      '>chevron': 'list_headW_chevron',
    },

    '>items': {
      _: 'list_items',

      '>item': {
        _: 'list_items_item',

        '&.loadMore': 'list_items_loadMore',
      },
    },
  },

  '>upper': 'upper',
  '>split': {
    _: 'split',
    '&.hidden': 'hidden',
  },
  '>lower': 'lower',
  '>bottom': 'bottom',
};

const listHeadingWrapClss = classes.list['>headingWrap'];
const listItemsClss = classes.list['>items'];

const commonPadding = '1rem';

const Wrap = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  width: 19rem;
  max-height: 22rem;
  overflow: auto;

  >.${classes['>search']} {
    padding: 0 0.5rem 0.5rem 0.5rem;
  }

  /* ====================================== */

  .${listHeadingWrapClss._} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${commonPadding};
    min-height: 2rem;
    cursor: pointer;

    > .MuiTypography-root {
      flex: 1;
    }
  }

  .${classes['>upper']},
  .${classes['>lower']} {
    &.noSearch {
      margin: 0;
    }
  }

  .${listItemsClss._} {
    &.closed { display: none }
  }

  .${listItemsClss['>item']._} {
    display: flex;
    align-items: center;
    cursor: pointer;
    /* height: 2rem; */

    &:hover { background-color: ${p => p.theme.palette.background.light}; }
  }

  .${listItemsClss['>item']['&.loadMore']} {
    text-decoration: underline;
    font-weight: bold;
  }

  /* ====================================== */

  >.${classes['>split']._} {
    margin: 0;

    &.${classes['>split']['&.hidden']} {
      display: none;
    }
  }

  /* ====================================== */

  >.${classes['>lower']} {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    >.${classes.list._} {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
    }

    .${classes.list['>items']._} {
      flex-grow: 1;
      overflow: auto;

      ${scrollableMixin};
    }
  }

  /* ====================================== */

  >.${classes['>bottom']} {
    padding: 0 ${commonPadding};
    position: relative;

    > hr {
      // don't want hr to add 1px height
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .hide {
    display: none;
  }

  .limitHeight {
    max-height: 9.5rem;
  }

  .noShrink {
    flex-shrink: 0;
  }
`;
Wrap.displayName = 'AsyncSelect/Core/Wrap';

/**
  @typedef ISection
  @type {{
    heading: string;
    items: Array<{ key: string; i: React.ReactNode; isHelper?: boolean; }>;
    loadMore?: (search: string) => unknown;
    hasMore?: boolean;
    onClick: (key: string) => unknown;
    isLoading?: boolean;
    collapsable?: boolean;
    emptyMessage?: string;
  }}
*/

/**
 * @typedef IScrollSectionProps
 * @type {{
 *  section: ISection;
 *  className?: string;
 * }}
*/

/** @type { React.FC<IScrollSectionProps> } */
const ScrollSection = ({ section, className }) => {
  // hasMore is a hard boolean check in case its undefined
  const scrollLoad = useScrollable(section.loadMore ?? (() => { }), section.hasMore === true);
  const [sectionOpen, setSectionOpen] = useState(true);
  const toggleSectionOpen = () => setSectionOpen(!sectionOpen);

  const onSectionItemClick = (e) => {
    const { key, ishelper } = e.currentTarget.dataset;
    if (key === undefined || ishelper === 'true') return;
    section.onClick(key);
  };

  return (
    <div className={cls(className, (!section.isLoading & section.items.length === 0 && !section.emptyMessage) && 'hide')}>
      <div className={listHeadingWrapClss._} onClick={section.collapsable ? toggleSectionOpen : undefined}>
        <Typography variant='h6'>
          {section.heading}
        </Typography>

        {section.isLoading ? <LoadingDots /> : null}
        {section.collapsable && <ChevrondownIcon {...{
          className: listHeadingWrapClss['>chevron'],
          ...(sectionOpen === false ? { style: { transform: 'rotateZ(180deg)' } } : {}),
        }}
        />}
      </div>

      <div className={cls(listItemsClss._, !sectionOpen && 'closed')} {...scrollLoad}>
        {
          section.items?.length > 0 ? section.items.map(it => (
            <div
              key={it.key}
              data-key={it.key}
              data-ishelper={it.isHelper ? 'true' : 'false'}
              className={listItemsClss['>item']._}
              onClick={onSectionItemClick}
            >
              {it.i}
            </div>
          )) : <Typography variant='body2' style={{ padding: "1rem" }}>{section.emptyMessage}</Typography>
        }
      </div>
    </div>
  );
};

/**
  @typedef IProps
  @type {{
    search?: import('@components/Search').SearchProps;
    upper?: ISection;
    lower?: ISection;
    actions?: any[];
    className?: string;
  }}
*/

/** @type { React.FC< IProps > } */
export const AsyncSelectCore = ({ search = undefined, actions = defaultActions, upper, lower, className }) => {
  return (
    <Wrap className={className}>
      <span style={{ paddingTop: "0.5rem" }} />

      {search ? <Search {...search} className={cls(classes['>search'], search.className)} /> : null}

      {upper ? <ScrollSection section={upper} className={cls(classes['>lower'], 'noShrink', search === undefined && 'noSearch')} /> : null}

      {
        upper && lower && lower.items.length > 0
          ? <Divider className={classes['>split']._} />
          : null
      }

      {lower ? <ScrollSection section={lower} className={cls(classes['>lower'], upper && 'limitHeight')} /> : null}

      {
        actions?.length > 0
          ? (
            <div className={classes['>bottom']}>
              <MenuActionsComp actions={actions} />
            </div>
          )
          : <span style={{ paddingBottom: commonPadding }} />
      }
    </Wrap>
  );
};

export default AsyncSelectCore;