import styled from 'styled-components';
import { useFullRelease } from '@common/hooks_useFullRelease';
import { WithGraphCoreSplit } from '@components/GraphVisualisation/GraphViz';
import Data from '@components/GraphVisualisation/components/Data/Data';
import RuleGraphProvider from '@components/GraphVisualisation/providers/RuleGraphProvider';
import { useParsedGraph } from '@pages/models/release/GraphContext';
import ReleaseContextProvider from "@pages/models/release/ReleaseContextProvider";


const StyledWithGraphCoreSplit = styled(WithGraphCoreSplit)`
  height: 100% !important;
`;


export const DataPanel = ({ session }: { session: any }) => {
  const release = useFullRelease();
  const graph = useParsedGraph();
  const goalNode = graph.node(session.goal);
  const goal = goalNode ? goalNode.path || goalNode.id : session.goal;

  return (
    <RuleGraphProvider
      graph={graph}
      goal={goal}
      debug={true}
    >
      {/* TODO remove me - legacy support */}
      <ReleaseContextProvider release={release as any}>
        <StyledWithGraphCoreSplit>
          <Data />
        </StyledWithGraphCoreSplit>
      </ReleaseContextProvider>
    </RuleGraphProvider>
  );
};
