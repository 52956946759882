import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Card, Typography } from "@material-ui/core";
import { signOut } from "@imminently/imminently_platform";
import { Button } from "@components";

const Info = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 4rem;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const Forbidden = () => {
  const dispatch = useDispatch();
  const signout = () => {
    dispatch(signOut());
  };
  return (
    <Info>
      <Typography component="h1" variant="h1" align="center">
        You do not have access to this page
      </Typography>

      <div>
        <Typography component="h2" variant="h5" align="center">
          If you feel this is an error, please contact support or attempt to try in again
        </Typography>
      </div>


      <Actions>
        <Button component={Link} to="/" type="primary">
          Return to website
        </Button>
        <Button type="tertiary" onClick={signout}>
          Sign out
        </Button>
      </Actions>
    </Info>
  );
};

export default Forbidden;


