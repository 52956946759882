import { createContext, useContext, useEffect, useState } from "react";
// Note slice in redux toolkit uses immer so we mutate state
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { crudGetOne } from "@imminently/imminently_platform";
import type { SectionData } from "@common/editor/components/section/section.types";
import * as fullReleaseRedux from "../../redux/fullRelease/reducer";
import { deserializeDocument, serializeContents, serializeDocument } from "./documents.slice.helpers";
import type { Document, DocumentContents, DocumentError, ParsedRuleGraph, RuleDocContentsV2 } from "@packages/commons";
import { findReleaseDocument, flattenReleaseDocuments } from "@packages/commons";
import { documentService } from "services";
import type { BackendResource, DeepPartial } from "@imminently/immi-query";
import { showConfirmation } from "@modals";
import get from "lodash/get";
import { cleanupJSpreadsheetData } from "@pages/models/release/Documents/DataTable/utils";
import { DESERIALIZED_SYMBOL, type DeserializedDocumentContents } from "./documents.slice.constants";
import { createTransform, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { compile, CompilerContext } from "@packages/compiler";
import { useDebouncedCallback } from "use-debounce";
import { useParsedGraph } from "@pages/models/release/GraphContext";
import { uuid } from "@decisively-io/interview-sdk";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { useTranslation } from "react-i18next";
import { useNotify } from "@common/notifications";
import { useLockFile } from "./FileExplorer/documents.api";

export * from './documents.slice.constants';


// /**
//  * We use this symbol to make sure the types of the deserialized documents are distinct from the serialized ones
//  * This is to prevent accidentally sending a deserialized document to the backend
//  */
// export const DESERIALIZED_SYMBOL = Symbol("deserialized");

// export interface DeserializedRuleSheet {
//   title: string;
//   columns: any[];
//   data: any[];
// }



/**
 * move document edits into editor
 * editor contains documents by id
 * document contents is converted on initial set
 * allows comparison at any time
 * keeps document edits in memory, ie navigating or changing file is not an issue
 *
 * pull the doc from BE store it by id
 * need to store the og document
 * need to store client side contents (converted to slate/plate)
 * need to store errors
 * need to store validations
 * need to store last saved
 */

export interface EditorDocument {
  document: BackendResource<Document>;
  contents: DeserializedDocumentContents;
  errors: DocumentError[];
  validations: any[];
  lastSaved: number | null;
  isDirty: boolean;
  keepLocal: boolean;
  lastLoadedTime: number;
  /**
   * Used to force a re-render of the plate editor.
   * Sometimes we need the editor to re-render, however trying to use other meta information is too aggressive.
   * NOTE forcing a re-render will reset the users scroll position. So use this sparingly.
  */
  renderKey: number;
}

const defaultFrontendSheet = JSON.stringify({ "declarations": [{}], "sheets": [{ "title": "Sheet 1", "headings": [{ "type": "OPM-Condition Heading", "legend": "" }, { "type": "OPM-Conclusion Heading", "legend": "" }], "rows": [[{ "type": "OPM-Condition", "text": "" }, { "type": "OPM-Conclusion", "text": "" }], [{ "type": "OPM-Else", "text": "else" }, { "type": "OPM-Conclusion", "text": "" }]] }] });
const defaultBackendSheet = JSON.stringify({ "declarations": [], "sheets": [] });

// const compareDocuments = (a: Document, b: Document) => {
//   return JSON.stringify(serializeDocument(a)) === JSON.stringify(serializeDocument(b));
// };

export const isDocumentDirty = (document?: EditorDocument) => {
  if (!document || !document.contents) return false;
  const start = Date.now();
  // just use a basic comparison for now
  const { version, type } = document.document;
  const current = serializeContents(document.contents, version, type);
  // we want to run deserialize and serialize as due to auto conversion, the contents may have changed
  // ideally serialize should be the same as raw saved
  const saved = serializeContents(deserializeDocument(document.document).contents, version, type);
  // can't be dirty if one of them doesn't exist
  if (!current || !saved) return false;

  if (type === 'rulesheet') {
    // Special case. When the sheet is first created the backend will store it as `declarations: [], sheets: []`
    // But the front end will have more scaffolding in place to run the UI. If we leave this as dirty then the UI
    // will make it appear that the user changed something, when they didn't. I thought about trying
    // change the serialisation to handle this - but too many edge cases where it could go wrong. Easier to put this in
    // workaround is check if they are both default and if so, not dirty
    if (JSON.stringify(saved) === defaultBackendSheet && JSON.stringify(current) === defaultFrontendSheet) {
      return false;
    }
  }

  const end = Date.now();
  console.log("[isDocumentDirty] took", end - start, "ms");

  return JSON.stringify(current) !== JSON.stringify(saved);
};

const initDocument = (document, localContents?: DeserializedDocumentContents): EditorDocument => {
  // store the original document for reference
  const { contents } = deserializeDocument(document);
  return {
    document,
    // if we have local contents, use them, otherwise use the deserialized contents
    contents: localContents ?? contents,
    errors: document.errors ?? [],
    validations: [],
    lastSaved: null, // don't pre-populate this, we want to know if we have saved
    isDirty: Boolean(localContents), // if we have local contents, we are dirty
    keepLocal: false,
    lastLoadedTime: Date.now(),
    renderKey: 0,
  };
};

const initialState = {} as { [id: string]: EditorDocument };

// interface DocumentAction {
//   id: string;
//   [key: string]: any;
// }

/**
 * document slice structure
 *
 * {
 *  [id]: {
 *   document: {},
 *   contents: [],
 *   errors: [],
 *   validations: [],
 *   lastSaved: null,
 *  }
 * }
 */

const documentsSlice = createSlice({
  name: "documents-new",
  initialState,
  reducers: {
    setDocument: (state, action: PayloadAction<{ id: string; document: BackendResource<Document> }>) => {
      const { id, document } = action.payload;
      const doc = state[id];
      // if we have the internal doc, we are initialised
      const isInitialised = Boolean(doc?.document);
      if (isInitialised) {
        // update the inner document
        state[id].document = document;
        const { contents } = deserializeDocument(document);
        state[id].contents = contents;
        state[id].errors = document.errors ?? [];
        state[id].keepLocal = false; // ensure to reset this value
        state[id].lastLoadedTime = Date.now();
        // do NOT change the render key, as we don't want to always force a re-render
        return;
      }
      // try passing in local contents if it exists
      state[id] = initDocument(document, doc?.contents);
    },
    setContents: (state, action: PayloadAction<{ id: string; contents: DeserializedDocumentContents }>) => {
      const { id, contents } = action.payload;
      if (!contents[DESERIALIZED_SYMBOL]) {
        console.error("Invalid document contents, missing deserialized symbol", contents);
      }
      state[id].contents = contents;
    },
    setErrors: (state, action: PayloadAction<{ id: string; errors: DocumentError[] }>) => {
      const { id, errors } = action.payload;
      state[id].errors = errors;
    },
    setValidations: (state, action: PayloadAction<{ id: string; validations: any[] }>) => {
      const { id, validations } = action.payload;
      state[id].validations = validations;
    },
    setLastSaved: (state, action: PayloadAction<{ id: string; lastSaved: number }>) => {
      const { id, lastSaved } = action.payload;
      state[id].lastSaved = lastSaved;
    },
    setSource: (state, action: PayloadAction<{ id: string; source: any[] }>) => {
      const { id, source } = action.payload;
      if (Array.isArray(state[id].contents)) return;
      (state[id].contents as RuleDocContentsV2).source = source;
    },
    setRules: (state, action: PayloadAction<{ id: string; rules: any[] }>) => {
      const { id, rules } = action.payload;
      if (Array.isArray(state[id].contents)) return;
      (state[id].contents as RuleDocContentsV2).rules = rules;
    },
    setSections: (state, action: PayloadAction<{ id: string; sections: any[] }>) => {
      const { id, sections } = action.payload;
      if (Array.isArray(state[id].contents)) return;
      (state[id].contents as RuleDocContentsV2).sections = sections;
    },
    setDirty: (state, action: PayloadAction<{ id: string; isDirty: boolean }>) => {
      const { id, isDirty } = action.payload;
      state[id].isDirty = isDirty;
    },
    setKeepLocal: (state, action: PayloadAction<{ id: string, keepLocal: boolean }>) => {
      const { id, keepLocal } = action.payload;
      state[id].keepLocal = keepLocal;
    },
    forceRender: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const key = state[id].renderKey + 1;
      state[id].renderKey = key;
    },
  },
  // extra reducer that runs post all actions
  extraReducers: (builder) => {
    // after all actions, run an is dirty check
    builder.addMatcher(
      (action) => action.type.startsWith("documents-new") && action.payload?.id && !action.type.endsWith("setDirty"),
      (state, action) => {
        const document = state[action.payload?.id];
        if (!document) return;
        // parse in/out of JSON as we want a basic object, not a proxy object
        const docJSON = JSON.parse(JSON.stringify(document));
        document.isDirty = isDocumentDirty(docJSON);
      },
    );
  },
});

export const { setDocument, setContents, setErrors, setValidations, setLastSaved, setKeepLocal, forceRender } = documentsSlice.actions;

export type EditDocumentOptions = {
  /** @deprecated */
  serialize?: (value: any) => any;
  /** @deprecated */
  deserialize?: (value: any) => any;
  validate?: (value: DeepPartial<DocumentContents>) => DocumentError[];
};

export const useSections = (documentId: string) => {
  const dispatch = useDispatch();
  const sections = useSelector(
    (state) => state[documentsSlice.name][documentId]?.contents.sections ?? [],
  ) as SectionData[];
  const { setSections } = documentsSlice.actions;

  return {
    sections,
    setSections: (newSections: SectionData[]) => dispatch(setSections({ id: documentId, sections: newSections })),
    addSection: (section: SectionData) => dispatch(setSections({ id: documentId, sections: [...sections, section] })),
    getSection: (id: string) => sections.find((section) => section.id === id),
    setSection: (id: string, section: Partial<SectionData>) => {
      const index = sections.findIndex((s) => s.id === id);
      if (index === -1) throw new Error(`Section with id ${id} not found`);
      const oldSection = sections[index];
      const newData = [...sections];
      newData[index] = { ...oldSection, ...section };
      dispatch(setSections({ id: documentId, sections: newData }));
    },
    removeSection: (id: string) => {
      const index = sections.findIndex((s) => s.id === id);
      if (index === -1) throw new Error(`Section with id ${id} not found`);
      const newData = [...sections];
      newData.splice(index, 1);
      dispatch(setSections({ id: documentId, sections: newData }));
    },
  };
};

const useSaveDocument = (options: EditDocumentOptions = {}) => {
  const dispatch = useDispatch();
  const { mutateAsync, isLoading } = documentService.useUpdate();
  const { validate } = options;

  const { setValidations, setLastSaved, setDocument } = documentsSlice.actions;

  const onSave = async (document: EditorDocument) => {
    if (!document) return; // nothing to save
    const { id } = document.document;
    if (!id) return;
    try {
      console.log("Saving document:", document);
      type Contents = ReturnType<typeof serializeDocument>['contents'];
      // // Convert slate state into backend
      const contents = ((): Contents => {
        const { contents } = serializeDocument({ ...document.document, contents: document.contents } as any);
        const { document: doc } = document;
        if (doc.type !== 'datatable') return contents;

        const headers: string[] = get(contents, 'headers', []);
        const rows = cleanupJSpreadsheetData(get(contents, 'rows', []), headers.length);

        return { rows, headers } as unknown as Contents;
      })();
      const validations = (contents && validate?.(contents)) ?? [];
      dispatch(setValidations({ id, validations }));
      console.log("Dispatching update:", contents);
      // document version update code, move all v2 to v3, leave v1 as is
      const version = document.document.version && document.document.version > 1 ? 3 : 1;
      // @ts-ignore TODO This is where I hate TS being too strict and over typing - not my problem right now
      const res = await mutateAsync({ id, payload: { contents, version } as Partial<Document> });
      console.log("save document response", res);
      // update the document in redux
      // @ts-ignore
      dispatch(setDocument({ id, document: res }));
      // update release as graph should have changed
      // TODO we want this to auto update using sockets
      dispatch(crudGetOne("releases", document.document.release));
      dispatch(fullReleaseRedux.aCreators.requestToActualize());
      // update last saved on success
      dispatch(setLastSaved({ id, lastSaved: Date.now() }));
      return true;
    } catch (err) {
      console.error("Error saving document", err);
      return false;
    }
  };

  return {
    onSave,
    saving: isLoading,
  };
};

export const useModifyDocument = (options: EditDocumentOptions = {}) => {
  const { document } = useRuleDocument();
  // const documentState = useSelector((state) => state[documentsSlice.name][document.id]) as EditorDocument | undefined;
  const { onSave, saving } = useSaveDocument(options);

  const saveDocument = async () => {
    if (!document || saving) return; // nothing to save
    console.log("Saving document:", document);
    await onSave(document);
  };

  return {
    saveDocument,
    saving,
  };
};

export const useDocumentActions = (id: string) => {
  const dispatch = useDispatch();
  const actions = documentsSlice.actions;

  return {
    setDocument: (document: BackendResource<Document>) => dispatch(actions.setDocument({ id, document })),
    setContents: (contents: DeserializedDocumentContents) => dispatch(actions.setContents({ id, contents })),
    setErrors: (errors: DocumentError[]) => dispatch(actions.setErrors({ id, errors })),
    setValidations: (validations: DocumentError[]) => dispatch(actions.setValidations({ id, validations })),
    setLastSaved: (lastSaved: number) => dispatch(actions.setLastSaved({ id, lastSaved })),
    setSource: (source: any[]) => dispatch(actions.setSource({ id, source })),
    setRules: (rules: any[]) => dispatch(actions.setRules({ id, rules })),
    setSections: (sections: any[]) => dispatch(actions.setSections({ id, sections })),
    forceRender: () => dispatch(actions.forceRender({ id })),
  };
};

/** @deprecated this causes too many re-renders, use the new context and hooks */
export const useEditDocument = (id: string, options: EditDocumentOptions = {}) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const actions = useDocumentActions(id);

  const [update, setUpdate] = useState(false);

  const { data: rawDocument } = documentService.useGetOne(id, {
    // refetch every 25 seconds to ensure we keep the editor alive
    // editor timeout is 30 seconds, so this should be enough
    refetchInterval: 25000,
    onError(err) {
      // TODO convert this to a notify
      console.error("Error fetching document", err);
      notify("Document not found", "error");
    },
    onSuccess(data) {
      console.log("Loaded document", data);
      // const doc = deserializeDocument(data);
      // dispatch(setDocument({ id, document: doc }));
      setUpdate(true);
    },
  });

  const document = useSelector((state) => state[documentsSlice.name][id]) as EditorDocument | undefined;
  const { onSave, saving } = useSaveDocument(options);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (update) {
      // a new document was loaded from the BE
      // console.log("document changed", document, rawDocument);
      if (!document?.document && rawDocument) {
        console.log("raw doc only, so should be init", document, rawDocument);
        // initial document load

        dispatch(setDocument({ id, document: rawDocument }));
        setUpdate(false);

        /*if (options.validate) {
          const validations = (rawDocument.contents && options.validate?.(rawDocument.contents)) ?? [];
          dispatch(setValidations({ id, validations }));
        }*/
        return;
      }

      // else we need to check if we can update or have a merge issue
      if (document && rawDocument) {
        // we want to compare edit times
        const lastSaved = document.document.lastModified;
        // console.log("compare docs", document.document, rawDocument);
        // determine if the BE has updated and we are now too old
        const hasUpdated =
          lastSaved &&
          (!rawDocument.lastModified || new Date(lastSaved).getTime() < new Date(rawDocument.lastModified).getTime());
        // console.log("document update timestamps", new Date(lastSaved), new Date(rawDocument.lastModified), hasUpdated);
        // get if the user has local changes
        const isDirty = document.isDirty;
        if (hasUpdated && !isDirty) {
          // console.log("Document was updated, changing local copy");
          dispatch(setDocument({ id, document: rawDocument }));
          // if (doc.version === 2) {
          //   dispatch(setRules({ id, rules: (doc.contents as Contents).rules }));
          // }
          setUpdate(false);
          return;
        }
        if (hasUpdated && isDirty) {
          // we now have a merge issue, only update errors
          dispatch(
            setDocument({
              id,
              document: {
                ...document.document,
                // @ts-ignore
                errors: rawDocument.errors,
              },
            }),
          );
          setUpdate(false);
          return;
        }
      }
    }
  }, [update, rawDocument, document]);

  const saveDocument = async () => {
    if (!document || saving) return; // nothing to save

    console.log("Saving document:", document);
    // setSaving(true);
    await onSave(document);
    // setSaving(false);
  };

  return {
    document,
    isDirty: document?.isDirty ?? false, // easy util
    saving,
    saveDocument,
    ...actions,
  };
};

// a react context for the document, it gets given the document id
// then loads the document using documentService.useGetOne
// then stores the document in the redux store
// the context holds the document and provides the actions

export type IRuleDocumentContext = {
  id: string;
  /** NOTE this contents is deserialized */
  document: EditorDocument; // BackendResource<Document>;
} & ReturnType<typeof useDocumentActions>;

export const RuleDocumentContext = createContext<IRuleDocumentContext>(null as unknown as IRuleDocumentContext);

const useDocumentUpdates = (id: string, rawDocument: BackendResource<Document> | undefined) => {
  const dispatch = useDispatch();
  const document = useSelector((state) => state[documentsSlice.name][id]) as EditorDocument | undefined;

  useEffect(() => {
    // a new document was loaded from the BE
    if (!document?.document && rawDocument) {
      console.log("raw doc only, so should be init", document, rawDocument);
      // initial document load
      dispatch(setDocument({ id, document: rawDocument }));
      return;
    }

    // else we need to check if we can update or have a merge issue
    if (document && rawDocument) {
      // we want to compare edit times
      const lastSaved = document.document.lastModified;
      // determine if the BE has updated and we are now too old
      // hasUpdate if internal stored document is older than latest received from BE
      const hasUpdated = lastSaved &&
        (!rawDocument.lastModified || new Date(lastSaved).getTime() < new Date(rawDocument.lastModified).getTime());

      // get if the user has local changes or has already stated they want to keep local
      const { isDirty, keepLocal } = document;
      if (hasUpdated && !isDirty) {
        console.log("Document was updated, changing local copy");
        dispatch(setDocument({ id, document: rawDocument }));
        return;
      }
      if (hasUpdated && isDirty && !keepLocal) {
        console.log("Document was updated, but local is dirty");
        // TODO come back to this later, its causing too much confusion
        /*
        dispatch(
          showConfirmation({
            title: "Document updated",
            onDismiss: () => {
              // debugger;
              dispatch(setKeepLocal({ id, keepLocal: true }));
            },
            body: (
              <Typography>
                The document has been updated by another user. Would you like to:
                <ul>
                  <li>
                    Keep <strong>Mine</strong>. Allowing you to continue working on your changes.
                    Note saving after this will override their changes.
                  </li>
                  <li>
                    Use <strong>Theirs</strong>? Losing your current changes.
                  </li>
                  <li>
                    <strong>Merge</strong> the document? A new window will open with the latest changes.
                    Please update and save the document in the new window.
                  </li>
                </ul>
              </Typography>
            ),
            actions: (close) => ({
              primary:
              {
                name: "Merge",
                onClick: () => {
                  // make sure we keep the local and stop prompting
                  dispatch(setKeepLocal({ id, keepLocal: true }));
                  window.open(`/doc/${id}`, "_blank", "scrollbars=yes,resizable=yes,top=500,left=500,width=1280,height=720");
                  close();
                }
              },
              secondary: [
                {
                  name: "Mine",
                  onClick: () => {
                    dispatch(setKeepLocal({ id, keepLocal: true }));
                    close();
                  },
                },
                {
                  name: "Theirs",
                  onClick: () => {
                    dispatch(setDocument({ id, document: rawDocument }));
                    close();
                  },
                },
              ]
            }),
          })
        );
        */
        return;
      }
    }
  }, [rawDocument, document]);

  return; // return nothing
}

export const useLiveDocumentCompiler = (id: string) => {
  const document = useSelector((state) => state[documentsSlice.name][id]) as EditorDocument | undefined;
  const parsedGraph = useParsedGraph();
  const [ liveGraph, setLiveGraph ] = useState<ParsedRuleGraph>(parsedGraph);
  const [ compilerContext, setCompilerContext ] = useState<CompilerContext>();
  const release = useFullRelease();

  const compileDocument = useDebouncedCallback(() => {
    if (!document?.isDirty) {
      setLiveGraph(parsedGraph);
      return;
    }
    // compile the document

    const { version, type } = document.document;
    const current = serializeContents(document.contents, version, type);

    const result = compile({
      currentDocument: {
        ...document.document as any,
        contents: current,
      },
      graph: parsedGraph,
      relationships: release?.relationships,
      documentTree: release?.documents,
    });
    result.graph.id = uuid();
    console.log("compile?")
    setLiveGraph(result.graph);
  }, 1000);

  useEffect(() => {
    compileDocument();
  }, [ document ]);

  return {
    liveGraph,
  };
};

export const RuleDocumentProvider = ({ id, children }) => {
  const notify = useNotify();
  const actions = useDocumentActions(id);

  const { data: document } = documentService.useGetOne(id, {
    // refetch every 25 seconds to ensure we keep the editor alive
    // editor timeout is 30 seconds, so this should be enough
    refetchInterval: 25000,
    onError(err) {
      console.error("Error fetching document", err);
      notify("Document not found", "error");
    }
  });

  // listen for updates but do not return anything as we don't want to re-render
  useDocumentUpdates(id, document);

  // const internalDocument = useSelector((state) => state[documentsSlice.name][id]?.document) as BackendResource<Document> | undefined;
  // deserialize as we want to use it for the initial plate values
  // const deserialized = internalDocument ? deserializeDocument(internalDocument) : undefined;
  const reduxDocument = useSelector((state) => state[documentsSlice.name][id]) as EditorDocument | undefined;

  const context = {
    id,
    // document: deserialized,
    document: reduxDocument,
    ...actions,
  } as IRuleDocumentContext;

  return (
    <RuleDocumentContext.Provider value={context}>
      {children}
    </RuleDocumentContext.Provider>
  );
};

export const useRuleDocument = () => {
  const context = useContext(RuleDocumentContext);
  if (!context) {
    console.trace();
    throw new Error("useRuleDocument must be used within a RuleDocumentProvider");
  }
  return context;
};

export const newDocumentsName = documentsSlice.name;

export const useNewDocuments = () =>
  useSelector((state) => state[documentsSlice.name]) as Record<string, EditorDocument>;

export const useNewDocumentSelector = (selector: (documents: Record<string, EditorDocument>) => any) => useSelector((state) => selector(state[documentsSlice.name]));

/**
 * A hook to get the document being currently edited. Useful for other elements of the system.
 * If you are within a document context, try using useRuleDocument to get the latest document instead.
 */
export const useCurrentDocument = () => {
  // @ts-ignore - documents exists, but we have not typed redux
  const currentDocument = useSelector((state) => state.documents?.document);
  const editorDocument = useSelector((state) => state[documentsSlice.name][currentDocument?.reference] ?? null) as EditorDocument | null;
  if (!currentDocument || !editorDocument) return null;
  return {
    ...currentDocument,
    ...editorDocument.document,
  } as Document;
};

export const useIsDocumentDirty = (id?: string) =>
  useSelector((state) => {
    if (!id) return false;
    const doc = state[documentsSlice.name][id];
    return doc?.isDirty ?? false;
  });

export const useDirtyDocuments = (options: EditDocumentOptions = {}) =>
  useSelector((state) => {
    const { onSave } = useSaveDocument(options);
    const [saving, setSaving] = useState(false);
    const docs = state[documentsSlice.name] as Record<string, EditorDocument>;
    const dirty = Object.values(docs).filter((doc) => doc.isDirty);

    const saveAll = async () => {
      if (saving) return;
      setSaving(true);
      const res = await Promise.allSettled(dirty.map(onSave));
      console.log("save all results", res);
      setSaving(false);
    };

    return {
      documents: docs,
      total: dirty.length,
      saving,
      saveAll,
    };
  });

export const useDocumentErrors = (id: string) => {
  const dispatch = useDispatch();
  const doc = useSelector((s) => s[documentsSlice.name][id]);

  const { errors, validations } = doc ?? { errors: [], validations: [] };

  return {
    errors,
    validations,
    hasErrors: errors?.length > 0 || validations?.length > 0,
    setErrors: (errs) => dispatch(setErrors({ id, errors: errs })),
    setValidations: (errs) => dispatch(setValidations({ id, validations: errs })),
  };
};

/** get all compile errors in the project */
export const useCompileErrors = () => {
  // const dispatch = useDispatch();
  const release = useFullRelease();
  const documents = release?.documents ?? [];
  const errors = flattenReleaseDocuments(documents).map(doc => doc.errors);
  return errors;
};

/** get all compile errors for a given document */
export const useDocumentCompileErrors = (id: string) => {
  const release = useFullRelease();
  const documents = release?.documents ?? [];
  const errors = findReleaseDocument(documents, (doc) => doc.reference === id)?.errors ?? [];
  return errors;
};

export const useRevertDocument = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  // since we can't use the query here without an id, store the loading state using setState
  const [loading, setLoading] = useState(false);

  return (id: string) => {
    // show confirmation first, informing the user they will lose changes
    // then on confirmation, revert the document
    dispatch(showConfirmation({
      title: "Revert document",
      body: "Are you sure you want to revert the document? All changes will be lost.",
      actions: (close) => ({
        primary: {
          name: "Revert",
          error: true,
          loading,
          onClick: async () => {
            setLoading(true);
            try {
              // get the latest document from the BE
              const res = await documentService.getOne(id);
              // update the document in redux
              dispatch(setDocument({ id, document: res }));
              // force re-render, as we have reverted the document
              dispatch(forceRender({ id }));
            } catch (e) {
              console.error("Error reverting document", e);
              notify.error("Error reverting document");
            }
            setLoading(false);
            close();
          },
        },
      }),
    }));
  };
};

export const useLockDocument = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const release = useFullRelease();
  const { lockFile } = useLockFile();

  return (id: string) => {
    if (!release) return;
    const doc = findReleaseDocument(release.documents, (doc) => doc.reference === id);
    if (!doc || !doc.reference) return;
    const isLocked = !!doc.locked;
    dispatch(
      showConfirmation({
        title: t('are_you_sure'),
        body: doc.locked
          ? t('documents.unlocking_confirmation', { user: doc.locked.full_name })
          : t('documents.locking_confirmation'),
        actions: (close) => ({
          primary: {
            name: isLocked ? t('documents.lock_locked_action') : t('documents.lock_unlocked_action'),
            error: isLocked,
            onClick: () => {
              lockFile(id, true);
              close();
            },
          },
          secondary: [{
            name: t('documents.unlock_action'),
            // error: true,
            disabled: !isLocked,
            onClick: () => {
              lockFile(id, false);
              close();
            },
          }],
        }),
      }),
    );
  };
};

// we only want to persist documents that are dirty
const persistTransform = createTransform(
  (inboundState, key) => {
    // always store the persist data
    if (key === "_persist") return inboundState;
    const doc = inboundState as EditorDocument;
    // console.log("[PersistTransform] Inbound state", key, doc);
    if (doc.isDirty) {
      // just persist the users contents and isDirty flag (that way we can see its dirty without having to open it)
      // also persist the timestamp in case we want to reference this
      // could be useful later when checking / informing the user they are behind
      return { contents: doc.contents, isDirty: true, timestamp: Date.now() };
    }
    return undefined;
  },
  (outboundState, key) => {
    // console.log("[PersistTransform] Outbound state", key, outboundState);
    return outboundState;
  },
);

const persistedReducer = persistReducer({
  key: "immi.documents",
  storage,
  transforms: [persistTransform],
}, documentsSlice.reducer);

export default persistedReducer;
