import { useFormikContext } from "formik";
import Typography from "@material-ui/core/Typography";

import { FormikCheckbox, FormikChipInput, FormikSelect, FormikTextField } from "@components/forms/FormikControls";
import { ModalFields } from "@modals";
import { scrollable } from "@common";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { useIsProjectTechnicalWriter } from "auth/permissions";
import { ATTRIBUTE_TYPES, AttributeInfoFormValues, NONE_ENUM } from "./useAttributeForm";
import { Tooltip } from "@components";
import { Box } from "@material-ui/core";

export const AttributeForm = () => {
  const release = useFullReleaseStrict();
  const canEdit = useIsProjectTechnicalWriter();
  const { values, handleChange } = useFormikContext<AttributeInfoFormValues>();

  const enumOptions = [{ value: NONE_ENUM, key: "None" }]
    .concat(((release?.enums ?? []).map(e => ({ key: e.id, value: e.id }))) ?? []);

  /* TOOD: needs UI fix:
    - category and phase name should have the same width
    - category and phase name should only appear if their respective checkbox is true
  */
  return (
    <ModalFields style={{ margin: 0 }} className={scrollable}>
      <Typography>System information used to define an attribute.</Typography>
      <FormikTextField 
        fullWidth 
        label="Public name" 
        name="publicId" 
        onChange={handleChange} 
        value={values.publicId}
        disabled={!canEdit}
      />
      <FormikSelect 
        name="type" 
        label="Type" 
        options={ATTRIBUTE_TYPES}
        disabled={!canEdit} 
      />
      <FormikSelect 
        name="enum" 
        label="Enumeration" 
        options={enumOptions}
        disabled={!canEdit}
      />
      <FormikChipInput 
        name="tags" 
        label="Tags"
        disabled={!canEdit}
      />
      <Tooltip title="Marking this attribute as a category allows for grouping child attributes" placement="bottom-start">
        <Box display={"flex"}>
          <FormikCheckbox 
            name="category" 
            label="Category attribute?" 
            disabled={!canEdit}
          />
          <FormikTextField 
            label="Category name" 
            name="categoryName" 
            onChange={handleChange} 
            value={values.categoryName}
            disabled={!canEdit}
          />
        </Box>
      </Tooltip>
      <Tooltip title="Phase attributes are used in decision flows to provide a grouping of tasks for progress" placement="bottom-start">
        <Box display={"flex"}>
          <FormikCheckbox 
            name="phase" 
            label="Phase attribute?" 
            disabled={!canEdit}
          />
          <FormikTextField 
            label="Phase name" 
            name="phaseName" 
            onChange={handleChange} 
            value={values.phaseName}
            disabled={!canEdit}
          />
        </Box>
      </Tooltip>
    </ModalFields>
  );
};
