import { useDispatch } from "react-redux";
import { FormikContext } from "formik";
import styled from "styled-components";

import { scrollableMixin } from "@common";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { Pane, SplitPane } from "@common/SplitPane";
import TabView from "@components/tabs/TabView";
import { ModalActions, showConfirmation, showModal } from "@modals";
import { useParsedGraph } from "@pages/models/release/GraphContext";
import { GraphNode } from "@packages/commons";

import { useAttributeForm } from "./useAttributeForm";
import { AttributeForm } from "./AttributeForm";
import { AttributeMetadata } from "./AttributeMetadata";
import { AttributeExplanation } from "./AttributeExplanation";
import { AttributeUsage } from "./AttributeUsage";
import { AttributeGraph } from "./AttributeGraph";
import { ProjectTechnicalWriterGuard, ProjectWriterGuard } from "auth/ComponentGuard";
import { ModalComponentProps } from "layouts/Modal.types";
import { AttributeIDField } from "./AttributeIDField";
import { Stack } from "@components/meta";
import { AttributeTriggers } from "./AttributeTriggers";
import { useTranslation } from "react-i18next";

const Panes = styled(SplitPane)`
  margin-top: 0.5rem;
  border-top: 1px solid ${(p) => p.theme.palette.background.border};

  >div:not(.gutter) {
    > [role="tablist"] {
      padding: 1rem 1rem 0 1rem;
    }

    > [role="tabpanel"] {
      ${scrollableMixin};
      padding: 0 1rem 1rem 1rem;
    }

    > :last-child {
      padding: 0 1rem 1rem 1rem;
    }
  }
`;

export interface AttributeEditModalProps {
  node: GraphNode,
}

const AttributeEditModal = ({ node, ...props }: AttributeEditModalProps) => {
  const { close } = props as ModalComponentProps;
  const { query, ...formik } = useAttributeForm(node);

  const release = useFullReleaseStrict();

  const { submitForm } = formik;
  const { query: { isLoading } } = query;

  const action = {
    name: "Save",
    onClick: async () => {
      try {
        await submitForm();
        close();
      } catch (e) {
        // error notification should have been auto handled
      }
    },
    disabled: isLoading,
    loading: isLoading,
  };

  return (
    <FormikContext.Provider value={formik}>
      <Panes>
        <Pane>
          <TabView content={[
            { label: "System", component: <AttributeForm /> },
            { label: "Interview", component: <AttributeMetadata /> },
            { label: "Triggers", component: <AttributeTriggers /> },
          ]} />
          <ProjectTechnicalWriterGuard>
            <ModalActions primary={action} />
          </ProjectTechnicalWriterGuard>
        </Pane>
        <Pane>
          <TabView content={[
            {
              label: "Usage", component: (
                <Stack gridGap="1rem">
                  {/* we want to display the value for ease of dev/debugging */}
                  <AttributeIDField id={node.id} />
                  <AttributeUsage showInterviews node={node} onClick={close} />
                </Stack>
              )
            },
            { label: "Definition", component: <AttributeExplanation node={node} /> },
            { label: "Graph", component: <AttributeGraph attribute={node} release={release} /> },
          ]} />
        </Pane>
      </Panes>
    </FormikContext.Provider>
  );
};

export const useEditAttributeModal = () => {
  const dispatch = useDispatch();
  const graph = useParsedGraph();
  const {t} = useTranslation();
  return (id: string) => {
    const attribute = graph.node(id);

    if (!attribute) {
      dispatch(
        showConfirmation({
          title: "Error",
          body: t('edit_attr_modal_cant_find_attr_error'),
          actions: null,
        })
      );
      return;
    }

    dispatch(
      showModal(
        {
          open: true,
          title: attribute.description,
          maxWidth: "xl",
          allowResize: true,
          contentStyle: { padding: 0 },
          // displaying in usage instead
          // headerActions: <AttributeIDCopy id={id} />,
        },
        <AttributeEditModal node={attribute} />
      ),
    );
  };
};
