import React, { useEffect, useId, useMemo, useState } from "react";
import type { Elements } from "@components/RuleGraph_FullRelease/types";
import dagre from "dagre";
import { MsgFromWorker, MsgToWorker } from "@components/RuleGraph_FullRelease/worker/types";
import { useUpdateEffect } from "react-use";
import Box from "@material-ui/core/Box";
import { LayoutIcon, LoadingDotsJSX } from "@icons";
// import ReactFlow, { Controls, isNode, ReactFlowProps, ReactFlowProvider, useZoomPanHelper } from "reactflow";
import {
  ReactFlow as ReactFlowv11, // released april 2023, and current as of nov 2024, but the v12 docs are there, so a release must be imminent...
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
  Background,
  ReactFlowProvider,
  ReactFlowProps,
  isNode,
  isEdge,
  useReactFlow,
  applyNodeChanges,
  applyEdgeChanges,
  Node as ReactFlowNode,
  Edge as ReactFlowEdge,
} from "reactflow";
import GraphPallet from "@components/RuleGraph_FullRelease/components/GraphPallet"; // TODO this needs to be on the flow graph and it's not working in debug anymore
import styled from "styled-components";
import { SelectedSearchNodeClsnm } from "@components/RuleGraph_FullRelease/__nodeTypes";
import find from "lodash/find";
import { isMac } from "@components/kdb";
import {useGraphGoalNodesOrdered} from "@pages/models/release/GraphContext";
import get from "lodash/get";
import {collectPredecessorsOrSuccessors} from "@components/GraphVisualisation/hooks/useGraphVisualisation";
// import { flushSync } from "react-dom"; // TODO ds just check if we need this anymore

const ReactFlow = styled(ReactFlowv11)`
  // TODO just confirm we have a pro subscription...
  .react-flow__attribution {
    display: none !important;
  }
`;

export interface RestrictedView {
  rootPath: string;
  /** where null is no restriction/max */
  graphDepth: number | null;
  visibleNodes: string[] | null;
  nodesToMerge: string[] | null;
}

const ANIMATION_DURATION = 500;

const GraphRefit = (props: {refitKey?: string,
  onFit?: () => void,
  elementsState: ElementState, centerElementId?: string}) => {
  const { refitKey, onFit, elementsState, centerElementId } = props;
  const { setViewport, fitView, setCenter } = useReactFlow();

  const fitViewRef = React.useRef(fitView);
  fitViewRef.current = fitView;

  const refitKeyChanged = React.useRef<boolean>(false);
  const prevRefitKey = React.useRef<string | undefined>(undefined);
  if (prevRefitKey.current !== refitKey) {
    refitKeyChanged.current = true;
    prevRefitKey.current = refitKey;
  }

  const [forceRerender, setForceRerender] = useState<number>(0);

  useEffect(() => {
    if (!refitKeyChanged.current || elementsState.mode === "initializing" || elementsState.mode === "empty") {
      return;
    }
    refitKeyChanged.current = false;
    setTimeout(() => {

      if (centerElementId) {
        const element: any = find(elementsState.elements, [ 'id', centerElementId ]);
        if (element) {
          const x = element.position.x + element.data.width / 2;
          const y = element.position.y + element.data.height / 2;
          // setCenter(x, y, 1, ANIMATION_DURATION);
          setCenter(x, y, { zoom: 1, duration: ANIMATION_DURATION });
          onFit?.();
          return;
        }
      }

      fitViewRef.current(undefined);
      onFit?.();
    }, 20);

  }, [ refitKey, elementsState.mode ]);

  return null;
}

const StyledControls = styled(Controls)`
  bottom: 10px;
  top: inherit;
`;

const Wrap = styled.div`
  flex-grow: 1;
  position: relative;

  .${SelectedSearchNodeClsnm} {
    /* background-color: indianred; */
    outline: 2px solid indianred;
    /* border: radius: 5px; */
  }
`;

const RelayoutBtnContainer = styled.div`
  position: absolute;
  bottom: 123px;
  left: 14px;
  z-index: 100;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 4px;
  padding-left: 6px;
  background-color: white;
  svg {
    padding-top: 2px;
    padding-left: 2px;
    width: 20px;
    height: 20px;
  }
`;

const worker = new Worker("/dagreWorker.js");

type ElementState = (
  // TODO remove the union
  | { mode: "initializing" | "empty", elements: undefined }
  | { mode: "initialized" | "refresh", elements: Elements }
  );

export interface GraphViewNodeProps<N = any> {
  id: string;
  data: {
    height: number;
    width: number;
    node: N
  };
  includeContextMenu?: boolean;
  xPos?: number;
  yPos?: number;
  isDragging?: boolean;
  isDecisionFlow?: boolean;
}

export interface GraphViewProps<N = any> {
  graph: any;
  children?: React.ReactNode;
  refitKey?: string | number;
  nodeRenderLimit?: number;
  nodeComponent: React.ComponentType<GraphViewNodeProps<N>>;
  selectedNode?: string;
  showHidden?: boolean;
  onVisibleNodesChange?: (visibleNodes: string[] | null) => void;
  onRepositionNode?: (nodeId: string, x: number, y: number) => void;
  onNodeClick?: (nodeId: string, node: N) => void;
  onNodeCtrlClick?: (nodeId: string) => void;
  restrictedView?: RestrictedView;
  onRestrictedViewNodesMerge?: (nodes: string[]) => void;
  nodeHeight?: number;
  loading?: boolean;
  /** layout direction */
  rankDir?: "TB" | "BT" | "LR" | "RL";
  flowGraphProps?: ReactFlowProps;
  goal?: string;
}

const GraphView = <N,>(props: GraphViewProps<N>) => {
  const {
    graph,
    goal,
    rankDir,
    loading, nodeHeight, children, restrictedView,
    onNodeClick, onNodeCtrlClick, onRepositionNode, showHidden, onVisibleNodesChange, selectedNode, refitKey, nodeRenderLimit, nodeComponent,
    flowGraphProps,
  } = props;




  const id = useId();

  const resolvedNodeRenderLimit = nodeRenderLimit ?? 200;
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [elementsState, setElementsState] = React.useState<ElementState>({
    mode: "empty",
    elements: undefined, // TODO might refactor this out into edge and node separately...not sure yet
  });
  const requiresRefit = React.useRef<boolean>(false);

  const ignoreNewPositions = React.useRef<{
    repositionedNodes: { [key: string]: { x: number, y: number } };
    oldPositions: { [key: string]: { x: number, y: number } };
    // works nicely without these, but they might be useful in the future if you want even more control...
    // newNodesRootPos: { x: number, y: number };
    // newNodesRelativePosition: "above" | "below";
  } | null>(null);
  const resetLayout = React.useRef<boolean>(false);
  const additionalVisibleNodes = React.useRef<string[]>([]);

  const rawGraph = useMemo(() => {
    if (!graph) {
      return graph;
    }

    return (
      (typeof graph.node === "function") ? dagre.graphlib.json.write(graph) : graph
    );
  }, [graph]);

  const parsedGraph = useMemo(() => {
    if (!graph) {
      return graph;
    }

    return (
      (typeof graph.node === "function") ? graph : dagre.graphlib.json.read(graph)
    );
  }, [graph]);
  const goalNodesOrdered = useGraphGoalNodesOrdered(parsedGraph as any, goal, showHidden);


  const repositionNode = (nodeId: string, x: number, y: number) => {
    if (ignoreNewPositions.current) {
      ignoreNewPositions.current.repositionedNodes = {
        ...ignoreNewPositions.current.repositionedNodes || {},
        [nodeId]: { x, y },
      };
    } else {
      ignoreNewPositions.current = {
        oldPositions: {},
        repositionedNodes: {
          [nodeId]: { x, y },
        },
      };
    }
  };

  // -- lifecycle

  React.useEffect(() => {
    if (elementsState.elements && elementsState.elements.length) {
      setNodes(elementsState.elements.filter(e => e.type === "node") as ReactFlowNode[]);
      setEdges(elementsState.elements.filter(e => e.type !== "node") as ReactFlowEdge[]);
    }
  }, [elementsState]);

  const nodeTypes = React.useMemo(() => ({
    node: nodeComponent as any,
  }), [nodeComponent]);

  // -- web worker

  const workerListener = React.useCallback((e: MessageEvent<MsgFromWorker>) => {

    const {
      id: messageId,
      elements,
      visibleNodes,
    } = e.data;

    // console.log("Worker message", e.data);

    if (messageId !== id) {
      return;
    }

    if (ignoreNewPositions.current) {
      console.log("Ignoring new positions");

      for (const newEl of elements) {
        if ((newEl as any).position) {
          const repositionData = ignoreNewPositions.current.repositionedNodes[newEl.id];
          const oldPosData = ignoreNewPositions.current.oldPositions[newEl.id];
          const replacementPosition = repositionData || oldPosData;
          if (replacementPosition) {
            newEl["position"] = { x: replacementPosition.x, y: replacementPosition.y };
          }
        }
      };
      // you don't need to hang onto ignoreNewPositions.current.repositionedNodes, because elements will get saved into
      // `elementsState` next, so this will find its way into ignoreNewPositions.current.oldPositions on the next expansion
      ignoreNewPositions.current = null;
      requiresRefit.current = false;
    }

    // const nodes = elements.filter(e => e.type === "node") as ReactFlowNode[];
    // const edges = elements.filter(e => e.type !== "node") as ReactFlowEdge[];

    for (const el of elements) {
      if (el.type === "node") {
        (el as ReactFlowNode).draggable = true;
      }
    }

    if (resetLayout.current) {
      setElementsState({ mode: "initialized", elements: []});
      setTimeout(() => {
        setElementsState({ mode: "initialized", elements: elements});
      }, 1);
      resetLayout.current = false;
      ignoreNewPositions.current = null;
    } else {
      setElementsState({ mode: "initialized", elements: elements});
      onVisibleNodesChange?.(visibleNodes ?? null);
    }
    // console.log(`Number of (scoped) visible nodes on-screen is: ${visibleNodes?.length}`);

    if (requiresRefit.current) {
      setInternalRefitKey((prev) => prev + 1);
      requiresRefit.current = false;
    }
  }, []);

  React.useEffect(() => {
    worker.addEventListener("message", workerListener);

    return () => {
      worker.removeEventListener("message", workerListener);
    };
  }, [workerListener]);

  React.useEffect(() => {

    const msg: MsgToWorker = {
      id,
      rawGraph: rawGraph,
      showHidden,
      nodeHeight,
      rankDir,
      restrictedView: restrictedView ? {
        rootPath: restrictedView.rootPath, // safe to force because it's tested in isGraphViewRestricted
        graphDepth: restrictedView.graphDepth ?? 1,
        additionalVisibleNodes: [],
      } : undefined,
    };

    additionalVisibleNodes.current = [];
    if (!restrictedView?.rootPath) {
      const countVisibleNodes = showHidden
        ? (rawGraph.nodes || []).length
        : (rawGraph.nodes || []).filter((n) => !n.value.hidden).length;
      if (countVisibleNodes > resolvedNodeRenderLimit  && (goalNodesOrdered.length > 0)) {
        console.log(`Render limited to ${resolvedNodeRenderLimit} nodes. Preventing full display of ${countVisibleNodes} nodes.`);

        let newAdditionalNodes: string[] = [];
        const maxExpansion = 25;
        for (const gn of goalNodesOrdered) {
          let newExpandedNodes: string[] = [];
          for (let currDepth = 1; currDepth <= maxExpansion; currDepth++) {
            const currPath = gn.path || gn.id;
            const allPredecessors = collectPredecessorsOrSuccessors(parsedGraph!, currPath, currDepth, "predecessors");
            const allSuccessors = collectPredecessorsOrSuccessors(parsedGraph!, currPath, currDepth, "successors");

            const newNodes = [
              currPath,
              ...allPredecessors,
              ...allSuccessors,
            ];
            if (newNodes.length === newExpandedNodes.length || ((newNodes.length + newAdditionalNodes.length) > resolvedNodeRenderLimit)) {
              break;
            }
            newExpandedNodes = newNodes;
          }
          newAdditionalNodes = [...new Set([...newAdditionalNodes, ...newExpandedNodes])]
        }

        msg.restrictedView = {
          rootPath: goalNodesOrdered[0].path || goalNodesOrdered[0].id,
          graphDepth: 1,
          additionalVisibleNodes: newAdditionalNodes,
        };
        additionalVisibleNodes.current = newAdditionalNodes;
    }
      }

    setElementsState((state) => {

      if (state.mode === "initializing" || state.mode === "refresh") {
        return (state);
      }

      return ({ ...state, mode: state.mode === "initialized" ? "refresh" : "initializing" }) as any;
    });


    console.log(`Showing graph ${restrictedView?.rootPath ? "with" : "without"} restrictions`);
    worker.postMessage(msg);
  }, [showHidden, rawGraph, nodeRenderLimit, restrictedView?.rootPath]);

  const relayoutGraph = () => {

    if (elementsState.mode === "empty") {
      return;
    }

    console.log("Re-layout graph");

    const msgToWorker: MsgToWorker = {
      id,
      rawGraph: rawGraph,
      showHidden,
      nodeHeight,
      rankDir,
      restrictedView: restrictedView ? {
        rootPath: restrictedView.rootPath!, // safe to force because it's tested in isGraphViewRestricted
        graphDepth: restrictedView.graphDepth ?? 1,
        additionalVisibleNodes: [
          ...(restrictedView.visibleNodes || []),
        ],
      } : undefined,
    }

    // we might have some unfolds on an unrestricted graph that we need to preserve
    /*if (!restrictedView?.rootPath && additionalVisibleNodes.current.length && goalNodesOrdered?.[0]) {
      msgToWorker.restrictedView = {
        rootPath: goalNodesOrdered[0].path || goalNodesOrdered[0].id,
        graphDepth: 1,
        additionalVisibleNodes: additionalVisibleNodes.current,
      };
    }*/

    setElementsState((state) => ({ ...state, mode: "refresh" } as any));
    requiresRefit.current = false;
    ignoreNewPositions.current = null;
    resetLayout.current = true;
    worker.postMessage(msgToWorker);
  }

  useUpdateEffect(() => {
    requiresRefit.current = true;
  }, [restrictedView?.rootPath]);


  React.useEffect(() => {

    // doing it this way goes back to the worker, and the whole graph gets re-layed out
    // we could append things to the existing elementsState.elements, but we'd have to work out ourselves where to put them
    // and it's nice to have the worker do the layout for us (even if we just tesselate the new nodes in the existing layout)

    if (restrictedView?.nodesToMerge?.length && restrictedView?.rootPath) {
      console.log(`Showing ${(restrictedView?.nodesToMerge || []).length} additional nodes reachable from ${restrictedView?.rootPath || "(Limitation not specified)"}`);

      additionalVisibleNodes.current = [
        ...(restrictedView?.visibleNodes || []),
        ...restrictedView?.nodesToMerge,
      ];
      const msgToWorker: MsgToWorker = {
        id,
        rawGraph: rawGraph,
        showHidden,
        nodeHeight,
        rankDir,
        restrictedView: restrictedView?.rootPath ? {
          rootPath: restrictedView?.rootPath,
          graphDepth: restrictedView?.graphDepth ?? 1,
          additionalVisibleNodes: additionalVisibleNodes.current,
        } : undefined,
      };
      restrictedView.nodesToMerge = null;
      // save old positions
      ignoreNewPositions.current = {
        repositionedNodes: ignoreNewPositions.current?.repositionedNodes || {},
        oldPositions: (elementsState.elements || []).reduce((acc, el: any) => {

          if (el.position) {
            acc[el.id] = { x: el.position.x, y: el.position.y };
          }
          return (acc);
        }, {} as { [key: string]: { x: number, y: number } }),
        // newNodes: restrictedViewNodesToMerge,
      }
      console.log("ignoreNewPositions.current", ignoreNewPositions.current);
      // seems counter intuitive, but we need to blank out the graph for a moment because whilst expanding
      // you can get weird artifacts like edges not drawing, despite the data being absolutely correct
      setElementsState((state) => ({ ...state, mode: "refresh"} as any));
      worker.postMessage(msgToWorker);
    }
  }, [restrictedView?.nodesToMerge]);


  // -- rendering


  const renderRelayoutButton = () => {

    if (!relayoutGraph) {
      return (null);
    }

    return (
      <RelayoutBtnContainer
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          relayoutGraph();
        }}
      >
        <LayoutIcon />
      </RelayoutBtnContainer>
    );
  };

  const ignoreNextClick = React.useRef<boolean>(false);

  const handleNodeClick = React.useCallback<NonNullable<ReactFlowProps["onNodeClick"]>>((_, elementRaw) => {

    if (!isNode(elementRaw)) return;

    const targetId = (_?.target as any).id || "";
    const parentId = (_?.target as any)?.parentElement?.id || "";
    const parentParentId = (_?.target as any)?.parentElement?.parentElement?.id || "";
    const srcDomId = targetId || parentId || parentParentId;

    // catch any clicks that shouldn't have bubble up this far and drop them
    const isClickOnIgnoreEle = srcDomId.includes("graph_ignoreclick_");
    if (isClickOnIgnoreEle) {
      ignoreNextClick.current = true;
      return;
    }
    if (ignoreNextClick.current) {
      ignoreNextClick.current = false;
      return;
    }

    const element = elementRaw as ReactFlowNode;
    const isModKeyPressed = isMac() ? _.metaKey : _.ctrlKey;

    if ( isModKeyPressed && onNodeCtrlClick) {
      onNodeCtrlClick(element.data.node.path || element.data.node.id);
    } else {
      // @ts-ignore
      onNodeClick?.(element.data.node.path || element.data.node.id, element.data.node);
    }
  }, [onNodeClick, onNodeCtrlClick]);

  const [internalRefitKey, setInternalRefitKey] = React.useState<number>(0);

  const [fitted, setFitted] = React.useState<boolean>(false);

  useUpdateEffect(() => {
    if (selectedNode) {
      setInternalRefitKey((prev) => prev + 1);
    }
  }, [selectedNode]);

  if (elementsState.mode === "empty") {
    return null;
  }

  return (
    <Wrap>
      <ReactFlowProvider>
        <GraphRefit
          onFit={() => setFitted(true)}
          refitKey={`${refitKey}_${internalRefitKey}`}
          elementsState={elementsState}
          centerElementId={selectedNode}
        />
        {
            elementsState.elements
            ?
            <ReactFlow
              style={{width: "100%"}}
              minZoom={0.01}
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodeClick={handleNodeClick}
              snapToGrid={false}
              onNodeDragStop={onRepositionNode ? (e, p) => {
                if (p && p.position && p.id) {
                  repositionNode(p.id, p.position.x, p.position.y);
                }
              } : undefined}
              // nodesConnectable={false}
              nodesDraggable={true}
              draggable={true}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              {...flowGraphProps}
            >
              <StyledControls />
              {children}
              {renderRelayoutButton()}
            </ReactFlow>
            :
            null
        }
        {
          (loading || !fitted || elementsState.mode === "refresh" || elementsState.mode === "initializing")
            ?
            <Box
              position={"absolute"}
              style={{ background: `rgba(255,255,255,${!fitted ? 1 : 0.5})` }}
              top={0}
              left={0}
              right={0}
              bottom={0}
              zIndex={100}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {LoadingDotsJSX}
            </Box>
            :
            null
        }
      </ReactFlowProvider>
    </Wrap>
  )
}

export default GraphView;