import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import find  from 'lodash/find';

import Typography from '@material-ui/core/Typography';
import { showModal, getAuthHook } from '@imminently/imminently_platform';
import { ModalActions } from './components';
import { Dropdown } from '@components';
import { storeScope } from '../redux/scope';

const Actions = styled(ModalActions)`
  margin-top: 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-top: 2rem;
`;

const Tenancy = ({ close }) => {
  const { user } = getAuthHook();
  const history = useHistory();
  const dispatch = useDispatch();

  let tenantId = localStorage.getItem('decisively.tenant');
  //let currentRegion = localStorage.getItem('decisively.region');

  const [tenant, setTenant] = React.useState(tenantId);
  //const [region, setRegion] = React.useState(currentRegion);

  let tenancies = user.groups;
  let currentTenancy = find(tenancies, {id: tenantId}) || tenancies[0];
  //let regions = Object.keys(currentTenancy.regions);

  const tenantMap = v => ({ key: v.name, value: v.id });
  const changeTenancy = (e, v) => {
    console.log('tenancy', v.value, v.props.value)
    setTenant(v.props.value);
  };
  /*const changeRegion = (e, v) => {
    setRegion(v.props.value);
  };*/

  const navigate = url => {
    history.push(url);
  };

  const actions = {
    primary: {
      name: 'Change',
      onClick: () => {
        console.log('set', tenant, region);
        localStorage.setItem('decisively.tenant', tenant);
        //localStorage.setItem('decisively.region', region);
        storeScope({});
        // clear resources?
        // dispatch(setScope(null));
        // close();
        // navigate('/projects');
        // hard refresh
        location.reload(true);
      }
    }
  };

  return (
    <>
      <Typography variant='body1' paragraph={false}>
        Select the desired tenancy.
      </Typography>
      <Content>
        <Dropdown label='Tenancy' value={tenant} onChange={changeTenancy} items={tenancies} map={tenantMap} />
        {/*<Dropdown label='Region' value={region} onChange={changeRegion} items={regions} />*/}
      </Content>
      <Actions {...actions} />
    </>
  );
};


export const showTenancy = () => {
  return showModal(
    {
      open: true,
      maxWidth: 'xs',
      title: 'Tenancy',
    },
    ({ close }) => <Tenancy close={close} />,
  );
};
