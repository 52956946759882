import { lazy } from "react";
import styled from "styled-components";

import ReleaseWrapper from "../pages/models/release/ReleaseWrapper";
import * as ScopeComponentNS from "../pages/decisions/DecisionDashboard/ScopeComponent";
import { Roles } from "auth/permissions";
import { CitizenshipIcon, TestingIcon, UsersIcon } from "@icons";
import { Sessions } from "@pages/sessions";
import { Contacts } from "@pages/contacts";

const DecisionDashboard = lazy(() => import("../pages/decisions/DecisionDashboard"));
const StandAlone = lazy(() => import("../pages/decisions/DecisionDashboard/Reports/Report/StandAlone"));

const DecisionDashboardWrap = styled.div`
  height: 100% ;
  display: flex ;
  flex-direction: column ;
  overflow: auto;

  .common-page-header >div >div {
    width: 100% !important;

    >.MuiGrid-item {
      flex-grow: 1;
    }
  }
`;

// export const monitor = [
//   {
//     path: "/monitor",
//     component: () => (
//       <DecisionDashboardWrap>
//         <ReleaseWrapper title='Monitor' scopeComponent={ScopeComponentNS._}>
//           <DecisionDashboard />
//         </ReleaseWrapper>
//       </DecisionDashboardWrap>
//     ),
//     layout: "decisively",
//     role: Roles.ReportViewer,
//     exact: true,
//   },
//   {
//     path: "/monitor/:reportId",
//     layout: "async",
//     component: StandAlone,
//     role: Roles.ReportViewer,
//     exact: true,
//   },
// ];

export const monitor = [
  {
    path: "/monitor",
    layout: "decisively",
    role: Roles.ReportViewer,
    children: [
      {
        path: "/monitor/overview",
        exact: true,
        role: Roles.ReportViewer,
        component: () => (
          <ReleaseWrapper title='Monitor'>
            <Sessions />
          </ReleaseWrapper>
        ),
        sidebar: {
          name: "Monitor",
          icon: <CitizenshipIcon />,
        }
      },
      {
        // TODO for some reason crashes if this url changes
        path: "/monitor",
        exact: true,
        role: Roles.ReportViewer,
        sidebar: { name: "Reports", icon: <TestingIcon /> },
        component: () => (
          <DecisionDashboardWrap>
            <ReleaseWrapper title='Reports' scopeComponent={ScopeComponentNS._}>
              <DecisionDashboard />
            </ReleaseWrapper>
          </DecisionDashboardWrap>
        ),
      },
      {
        path: "/monitor/contacts",
        exact: true,
        role: [Roles.ContactsReadAll, Roles.ContactsWriter],
        component: () => (
          <ReleaseWrapper title='Contacts'>
            <Contacts />
          </ReleaseWrapper>
        ),
        sidebar: {
          name: "Contacts",
          icon: <UsersIcon />,
        }
      },
    ]
  },
  {
    path: "/monitor/:reportId",
    layout: "async",
    component: StandAlone,
    role: Roles.ReportViewer,
    exact: true,
  },
];
