import { EditorState } from "@common/editor/RuleAuthor/multiEditor";
import { ELEMENT_SECTION } from "@common/editor/components";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { ContextMenuItem, MenuC as Menu, Tooltip, useContextMenu } from "@components";
import { AutogenIcon, MoreVertIcon, SmallAdd } from "@icons";
import { IconButton } from "@material-ui/core";
import { ELEMENT_PARAGRAPH, exitBreak } from "@udecode/plate";
import { isEditorReadOnly, replaceNodeChildren, type TElement, usePlateEditorRef } from "@udecode/plate-core";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { ReactEditor } from "slate-react";
import styled from "styled-components";
import { useSuggestModal } from "../suggest/SuggestModal";
import { useOpenSuggestRulesModal } from "../suggest/SuggestRulesModal";
import { useSection } from "./SectionProvider";
import { useDeleteSectionModal, useRenameSectionModal } from "./sectionModals";
import React from "react";
import RuleEditorDecisionFlowGraph from "@components/flow/RuleEditorDecisionFlowGraph";
import { ENABLE_DECISION_FLOWS } from "../../../../PortalConstants";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem !important;
  position: relative;
  border-radius: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;

  .controls {
    display: none;
  }

  &:hover {
    > .controls {
      display: flex;
    }
  }

  > .label {
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-right: 2px solid #0a0a0a;
    /* background-color: rgba(255, 0, 0, 0.5); */
    position: relative;

    > .spacer {
      flex: 1;
      min-height: 0.5rem;
    }

    > .name {
      position: sticky;
      top: 0.5rem;
      writing-mode: vertical-rl;
      text-orientation: sideways;
      text-align: start;
      transform: rotate(180deg);
      // font styling
      font-family: 'Montserrat', sans-serif;
      /* text-transform: uppercase; */
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;

      &[data-status="verified"] {
        ::after {
          content: " ";
          display: block;
          width: 8px;
          height: 8px;
          background-color: green;
          border-radius: 50%;
        }
      }
    }

    > svg {
      transform: rotate(-90deg);
      height: 1rem;
      width: 1rem;
    }

  }

  &[data-selected="true"] {
    background-color: #f9f9f9;
    border: 1px solid #e8e8e8;
  }

  > .content {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    margin-left: 1.5rem;
  }
`;

const Controls = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  gap: 0;
  padding: 0.25rem;

  > button {
    border-radius: 0.5rem;
  }
`;

const InsertAction = styled(IconButton)`
  position: absolute;
  left: 2rem;
  z-index: 6;
  cursor: pointer;
  margin: 0 !important;
`;

export type SectionProps = any;

export const Section = ({ className, attributes, children, element, editor }: SectionProps) => {
  const { id } = element;
  const plugin = editor.pluginsByKey[ELEMENT_SECTION];
  const { disableSuggestModals } = plugin.options;
  const path = ReactEditor.findPath(editor, element);
  const { sectionId, getSection, setSection } = useSection();
  const release = useFullRelease();
  const readOnly = isEditorReadOnly(editor);
  const { onContextMenu, isOpen, props, close } = useContextMenu();
  const openRenameSection = useRenameSectionModal();
  const openDeleteSection = useDeleteSectionModal();
  const openSuggestModal = useSuggestModal();
  const section = getSection(id);
  const openSuggestRulesModal = useOpenSuggestRulesModal();
  const { name, status } = section ?? { name: "", status: "" };
  const selected = sectionId === id;
  const isSource = editor.id === EditorState.source;
  const sourceEditorRef = usePlateEditorRef(EditorState.source);
  const { t } = useTranslation();

  const insert = (before = false) => {
    console.log("clicked insert", before);
    exitBreak(editor, { level: 0, defaultType: ELEMENT_PARAGRAPH, before });
  };

  const onInsertSuggestion = (content: TElement | TElement[]) => {
    // console.log("inserting", content);
    // replace the current section with the suggestion
    // specifically the children of the section
    replaceNodeChildren(editor, { at: path, nodes: content });
  };

  const openSuggestion = (e) => {
    e.preventDefault();
    console.log("clicked suggest", element, release, isSource);
    if (!release) return;

    const releaseId = release.id;
    if (isSource) {
      openSuggestModal({ source: element, releaseId, onInsert: onInsertSuggestion });
    } else {
      const maybeSectionInSource = sourceEditorRef.children.find((it) => it.id === id);
      if (!maybeSectionInSource) return;

      openSuggestRulesModal({
        releaseId,
        model: release.model,
        onInsert: (_, rules) => {
          onInsertSuggestion(rules);
        },
        sections: [
          {
            ...(section as any),
            source: maybeSectionInSource,
          },
        ],
      });
    }
  };

  return (
    <>
      <Container
        key={id}
        className={clsx(className)}
        {...attributes}
        data-selected={selected}
      >
        <div
          className="label"
          contentEditable={false}
          onContextMenu={onContextMenu}
        >
          {/* <div className="spacer" contentEditable={false}></div> */}
          <span
            className="name"
            data-status={status}
            contentEditable={false}
          >
            {name}
          </span>
        </div>
        <div className="content">
          {children}
          {isSource && ENABLE_DECISION_FLOWS ? (
            <RuleEditorDecisionFlowGraph
              sectionId={id}
              noDataStyle={{
                display: "none",
              }}
              style={{
                minHeight: 320,
                border: "1px solid gray",
                borderRadius: "0.5rem",
              }}
            />
          ) : null}
        </div>
        {selected && !readOnly ? (
          <>
            <InsertAction
              contentEditable={false}
              tabIndex={-1}
              className="action"
              style={{ top: "calc(-0.5rem - 1px)" }}
              onClick={() => insert(true)}
            >
              <SmallAdd />
            </InsertAction>
            <InsertAction
              contentEditable={false}
              tabIndex={-1}
              className="action"
              style={{ bottom: "-0.5rem" }}
              onClick={() => insert()}
            >
              <SmallAdd />
            </InsertAction>
          </>
        ) : null}
        {readOnly ? null : (
          <Controls
            className="controls"
            contentEditable={false}
          >
            {disableSuggestModals ? null : (
              <Tooltip title={t("ai.suggestion_tooltip")}>
                <IconButton
                  onClick={openSuggestion}
                  contentEditable={false}
                >
                  <AutogenIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton
              onClick={onContextMenu}
              contentEditable={false}
            >
              <MoreVertIcon />
            </IconButton>
          </Controls>
        )}
      </Container>

      {isOpen && (
        <Menu {...props}>
          <ContextMenuItem
            button
            onClick={() => {
              openRenameSection(id);
              close();
            }}
          >
            <Trans i18nKey="documents.rename" />
          </ContextMenuItem>
          <ContextMenuItem
            button
            className="error"
            onClick={() => {
              openDeleteSection(id);
              close();
            }}
            disabled={!isSource}
          >
            <Trans i18nKey="delete_label" />
          </ContextMenuItem>
        </Menu>
      )}
    </>
  );
};
