import React from "react";
import {styled, withStyles} from "../../common/Styling";
import {Grid, GridProps, Typography} from "@mui/material";
import {useToggle} from "../../common/hooks/HooksGeneral";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.33rem 0.5rem;
  border-radius: 1.5rem;
  font-size: 0.75rem;
  width: fit-content;
  cursor: default;

  background-color: purple; // should never be visible, is just a working guide

  &[data-decisive="true"][data-required="true"] {
    background-color: var(--color-decisive-required);
  }
  &[data-decisive="true"][data-required="false"] {
    background-color: var(--color-decisive-not-required);
  }
  &[data-decisive="false"][data-required="true"] {
    background-color:var(--color-not-decisive-required);
  }
  &[data-decisive="false"][data-required="false"] {
    background-color: var(--color-not-decisive-not-required);
  }

  &&[data-complex="true"] {
    background-color: #D8E0FD;
  }

    &[data-complex="true"] .arrow {
display: none;
    }

  .value-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    cursor: pointer;
  }
`;

const Expandable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  // padding: 0.5rem;
  border-radius: 0.25rem;
  // background-color: #f8f8f8;
  background-color: white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  transition: max-height 0.5s ease-in-out, height 0.5s ease-in-out;
  overflow: hidden;
  z-index: 10;

  top: 1.5rem;

  position: absolute;
  width: 250px;
  margin-left: -125px; // left of center under the <Container> element

  &[data-expanded="true"] {
    max-height: 30vh;
    height: auto;
    transition: max-height 0.5s ease-in-out, height 0.5s ease-in-out;
  }
  &[data-expanded="false"] {
    max-height: 0;
    height: 0;
    transition: max-height 0.5s ease-in-out, height 0.5s ease-in-out;
  }

  .inner {
    padding: 0.5rem;
  }
`;

const IconDecisiveRequired = (props: { fill?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7"
      cy="7"
      r="6.5"
      fill={props.fill || "none"}
      stroke="#0A0A0A"
    />
    <path
      d="M10.5 5.64641L7.67544 7.1547V6.8453L10.5 8.35359L10.0292 9.16575L7.24561 7.63812L7.47076 7.54144V10.5H6.52924L6.54971 7.54144L6.75439 7.63812L3.99123 9.16575L3.5 8.35359L6.34503 6.8453V7.1547L3.5 5.64641L3.99123 4.83425L6.75439 6.36188L6.54971 6.45856L6.52924 3.5H7.47076V6.45856L7.24561 6.36188L10.0292 4.83425L10.5 5.64641Z"
      fill="#0A0A0A"
    />
  </svg>
);

const IconDecisiveNotRequired = (props: { fill?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7"
      cy="7"
      r="6.5"
      fill={props.fill || "none"}
      stroke="#0A0A0A"
    />
    <path
      d="M11.6668 11.6665L2.3335 2.9165"
      stroke="#0A0A0A"
    />
    <path
      d="M2.33317 11.9582L11.6665 2.9165"
      stroke="#0A0A0A"
    />
  </svg>
);

const IconNonDecisiveRequired = (props: { fill?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7"
      cy="7"
      r="6.5"
      fill={props.fill || "none"}
      stroke="#0A0A0A"
    />
    <path
      d="M8.75 6.36188L7.67544 7.1547V6.8453L10.5 8.35359L10.0292 9.16575L7.24561 7.63812L7.47076 7.54144V8.75H6.52924L6.54971 7.54144L6.75439 7.63812L3.99123 9.16575L3.5 8.35359L6.34503 6.8453V7.1547L4.98793 6.36188L5.52474 5.54972L6.75439 6.36188L6.54971 6.45856L6.52924 3.5H7.47076V6.45856L7.24561 6.36188L8.27924 5.54972L8.75 6.36188Z"
      fill="#0A0A0A"
    />
  </svg>
);

const IconNonDecisiveNotRequired = (props: { fill?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7"
      cy="7"
      r="6.5"
      fill={props.fill || "none"}
      stroke="#0A0A0A"
    />
    <path
      d="M11.6668 11.6665L2.3335 2.9165"
      stroke="#0A0A0A"
    />
  </svg>
);

const ExpandArrow = (props: any) => (
  <svg
    width="6"
    height="4"
    viewBox="0 0 6 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.87543 0.500105C4.7764 0.502443 4.68231 0.54387 4.61372 0.615339L2.87885 2.3502L1.14399 0.615339C1.10903 0.579342 1.06721 0.550726 1.021 0.531184C0.974786 0.511642 0.925121 0.501572 0.874946 0.50157C0.800316 0.501588 0.727388 0.523871 0.665493 0.56557C0.603598 0.607268 0.555552 0.666484 0.5275 0.735642C0.499449 0.8048 0.492669 0.880755 0.508028 0.953788C0.523386 1.02682 0.560185 1.09361 0.613716 1.14561L2.61372 3.14561C2.68404 3.21591 2.77941 3.2554 2.87885 3.2554C2.97829 3.2554 3.07366 3.21591 3.14399 3.14561L5.14399 1.14561C5.19852 1.09327 5.23597 1.02568 5.25141 0.951687C5.26686 0.877697 5.25959 0.800765 5.23056 0.730978C5.20152 0.661191 5.15208 0.601806 5.08871 0.560607C5.02534 0.519409 4.951 0.498319 4.87543 0.500105Z"
      fill="#1A1F34"
    />
  </svg>
);

const gridCellProps = {
  item: true,
  xs: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
} as GridProps<any>;

const gridCellPropsSelectable = (readOnly: boolean | undefined, selected: boolean | undefined) => ({
  ...gridCellProps,
  cursor: readOnly ? "inherit" : "pointer",
  sx: (theme) => ({
    cursor: readOnly ? "inherit" : "pointer",
    backgroundColor: selected ? theme.palette.core.prayingMantis10 : "inherit",
    "&:hover": {
      backgroundColor: readOnly ? undefined : theme.palette.action.hover,
    },
  }),
});

export interface RequiredDecisiveValue {
  decisive: boolean | undefined;
  required: boolean | undefined;
}

interface SelectDecisiveProps {
  value: RequiredDecisiveValue;
  onChange?: (value: RequiredDecisiveValue) => void;
  complex?: boolean;
}

/**
 * This is a very specific control to trigger nodes, to apply whether they are:
 * - decisive (boolean)
 * - required (boolean)
 * This is controlled input that you can wrap with formik etc. later on
 * @param props
 * @returns
 */
const SelectDecisive = (props: SelectDecisiveProps & { classes?: any }) => {
  const { classes, value, onChange, complex } = props;
  const readOnly = !onChange || complex; // basically if there is no onChange, then it is read-only
  const expanded = useToggle(false);

  const selectValue = (decisive: boolean, required: boolean) => () => {
    if (onChange) {
      onChange({ decisive, required });
      expanded.forceFalse();
    }
  };

  // -- rendering

  const renderValueIcon = () => {
    if (value.decisive) {
      if (value.required) {
        return <IconDecisiveRequired />;
      }
      return <IconDecisiveNotRequired />;
    }

    if (value.required) {
      return <IconNonDecisiveRequired />;
    }
    return <IconNonDecisiveNotRequired />;
  };

  return (
    <>
      <Container
        className={classes?.root}
        data-decisive={Boolean(value.decisive)}
        data-required={Boolean(value.required)}
        data-complex={Boolean(complex)}
        onClick={readOnly ? undefined : expanded.toggle}
        onMouseEnter={readOnly ? expanded.forceTrue : undefined}
        onMouseLeave={readOnly ? expanded.forceFalse : undefined}
      >
        <div className="value-row">
          {renderValueIcon()}
          <Typography
            variant="caption"
            fontSize="0.6rem"
          >
            {complex ? "Complex" : value.required ? "Required" : "Not Required"}
          </Typography>
          <ExpandArrow className={"arrow"} />
        </div>

        <Expandable data-expanded={Boolean(expanded.value && !complex)}>
          <div className="inner">
            <Grid
              container
              spacing={1}
            >
              {/* row 0 */}
              <Grid {...gridCellProps} />
              <Grid {...gridCellProps}>
                <Typography
                  variant="caption"
                  fontSize="0.6rem"
                >
                  Required
                </Typography>
              </Grid>
              <Grid {...gridCellProps}>
                <Typography
                  variant="caption"
                  fontSize="0.6rem"
                >
                  Not Required
                </Typography>
              </Grid>
              {/* row 1 */}
              <Grid
                {...gridCellProps}
                justifyContent="flex-end"
              >
                <Typography
                  variant="caption"
                  fontSize="0.6rem"
                >
                  Decisive
                </Typography>
              </Grid>
              <Grid
                {...gridCellPropsSelectable(readOnly, value.decisive && value.required)}
                onClick={selectValue(true, true)}
              >
                <IconDecisiveRequired fill="#e9bfcc" />
              </Grid>
              <Grid
                {...gridCellPropsSelectable(readOnly, value.decisive && !value.required)}
                onClick={selectValue(true, false)}
              >
                <IconDecisiveNotRequired fill="#f8f1d7" />
              </Grid>
              {/* row 1 */}
              <Grid
                {...gridCellProps}
                justifyContent="flex-end"
              >
                <Typography
                  variant="caption"
                  fontSize="0.6rem"
                >
                  Non-Decisive
                </Typography>
              </Grid>
              <Grid
                {...gridCellPropsSelectable(readOnly, !value.decisive && value.required)}
                onClick={selectValue(false, true)}
              >
                <IconNonDecisiveRequired fill="#d0c0d8" />
              </Grid>
              <Grid
                {...gridCellPropsSelectable(readOnly, !value.decisive && !value.required)}
                onClick={selectValue(false, false)}
              >
                <IconNonDecisiveNotRequired fill="#dbe0fd" />
              </Grid>
            </Grid>
          </div>
        </Expandable>
      </Container>
      {/* <ClickAwayListener
      mouseEvent={expanded.value ? "onClick" : false}
      touchEvent={expanded.value ? "onTouchStart" : false}
      onClickAway={expanded.value ? expanded.forceFalse : () => {}}
    > */}

      {/* </ClickAwayListener> */}
    </>
  );
};

export default withStyles((theme) => ({
  root: {
    "--color-decisive-required": "#CBFED0",
    "--color-decisive-not-required": "#FDE0D8",
    "--color-not-decisive-required": "#CBFED0",
    "--color-not-decisive-not-required": "#FDE0D8",
  },
}))(SelectDecisive);
