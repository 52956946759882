import React, { useState } from 'react';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import { Switch } from '@components/Switch';

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--minWidth), 1fr));
  gap: ${ props => props.gap };
  @supports not (display: grid) {
    display: flex;
    flex-flow: row wrap;

    // 3 per row with total gap of 4rem spread across all 3 elements
    // min size is 20rem => 320px
    @media only screen and (min-width: 1120px) {
      --size: max(20rem, calc((100% / 3) - (4rem / 3)));
    }
    @media only screen and (max-width: 1119px) {
      --size: max(20rem, calc((100% / 2) - (2rem / 2)));
    }
    @media only screen and (max-width: 768px) {
      --size: 100%;
    }
  }
`;

/**
  @typedef IProps
  @type {{
    title: string;
    toggle?: null | boolean;
    items: any[],
    renderItem: (item: any, index: number) => React.ReactNode;
    renderSkeleton: (index: number) => React.ReactNode,
    minWidth?: string,
    gap?: string,
    className?: string;
    isLoaded?: boolean;
    noItemsJSX?: React.ReactNode
    isLoading?: boolean
  }}
 */

/** @type { React.FC< IProps > } */
export const GridList = React.forwardRef(({
  title,
  toggle = null,
  items,
  renderItem,
  renderSkeleton,
  minWidth = '30rem',
  gap = '1rem',
  className = undefined,
  isLoaded = undefined,
  noItemsJSX = undefined,
  isLoading = false,
  ...props
}, ref) => {
  const [show, setShow] = useState(toggle ?? true);

  const toggleShow = e => {
    setShow(e.target.checked);
  };

  return (
    <div className={className} ref={ref} {...props}>
      <Header>
        <Typography variant='h6'>{title}</Typography>
        {toggle !== null && <Switch checked={show} onChange={toggleShow} />}
      </Header>
      {show && (
        <Content style={{ '--minWidth': minWidth }} gap={gap}>
          {
            (isLoaded || items?.length > 0)
              ? (noItemsJSX !== undefined && items.length === 0)
                ? noItemsJSX
                : (
                  <>
                    {items.map(renderItem)}
                    {isLoading && [1, 2, 3].map(renderSkeleton)}
                  </>
                )
              : [1, 2, 3].map(renderSkeleton)
          }
        </Content>
      )}
    </div>
  );
});
