import type { GraphNode, ParsedRuleGraph } from "@packages/commons";
import { graphlib, type Node as NodeFromLib } from "dagre";
import produce from "immer";
import { cloneDeep, set } from "lodash";
import uniq from "lodash/uniq";
import { useMemo } from "react";
import pako from 'pako';
import { eigenvectorCentrality } from "./math";

/**
 * TODO want a better name for this.
 * George suggested AttributeType, but attribute.type is the ValueType, which is confusing.
 * Any suggestion would be appreciated.
 * Also suggested AttributeCategory, but that conflicts with attribute.category
 */
export enum GraphNodeType {
  GOAL = "goal",
  INPUT = "input",
  DERIVED = "derived",
  IDENTIFIER = "identifier",
  MISSING = "waiting_for_save",
};

export type GraphLibNode = NodeFromLib<GraphNode>;
export interface GraphNodeWithType extends GraphNode { nodeType: GraphNodeType };

export type NodeGroups = {
  /**
   * - It has no parents (predecessors)
   * - It is not an identifier (identifier: true in node)
   * - It is not a foreign key (fk: true in node)
   * - It can be on any entity
   */
  goals: GraphLibNode[];
  /**
   * - has no successors
   * - has no "condtions", nor "rows"
   */
  inputs: GraphLibNode[];
  /** node is an "identifier node" if it has "identifier: true" */
  identifiers: GraphLibNode[];
  /** neither a "goal", nor an "input" */
  derived: GraphLibNode[];
}

const defaultNodeGroups: NodeGroups = { goals: [], inputs: [], derived: [], identifiers: [] };

export const getNodeType = (node: GraphNode | null | undefined) => {
  // if(!node) throw "Cannot get node type! Node not found in graph.";
  // does not exist yet, so return null
  if (!node) return GraphNodeType.MISSING;

  if (node.goal && !node.fk && !node.identifier) {
    return GraphNodeType.GOAL;
  }

  if (node.identifier) {
    return GraphNodeType.IDENTIFIER;
  }

  if (!node.definedIn) {
    return GraphNodeType.INPUT;
  }

  return GraphNodeType.DERIVED;
};

export type GraphCounts = {
  total: number;
  input: number;
  derived: number;
  goals: number;
  identifiers: number;
};

export const groupGraphNodes = (g: graphlib.Graph<GraphNode>, entity?: string): NodeGroups => (
  produce(defaultNodeGroups, draft => {
    g.nodes().forEach(n => {
      const node = g.node(n);
      if (entity !== undefined && node.entity !== entity) return void 1;
      if (node.fk) return void 1;
      const predcessors = g.predecessors(n) || [];

      if (predcessors.length === 0 && !node.fk && !node.identifier) {
        return void draft.goals.push(node);
      }

      if (node.identifier) {
        return void draft.identifiers.push(node);
      }

      const successors = g.successors(n) || [];
      if (successors.length === 0 && !node.conditions && !node.rows) {
        return void draft.inputs.push(node);
      }

      return void draft.derived.push(node);
    });
  })
);

export const nodeGroupCount = (groups: NodeGroups): GraphCounts => ({
  total: groups.goals.length + groups.inputs.length + groups.derived.length + groups.identifiers.length,
  goals: groups.goals.length,
  input: groups.inputs.length,
  derived: groups.derived.length,
  identifiers: groups.identifiers.length,
});

// ===================================================================================

export const gatherInputNodesByGoalId = (graph: string | Record<string, any> | graphlib.Graph<GraphNode> | null, goalId: string): string[] => {

  if (graph === null) {
    return [];
  }
  // if graph has own property _nodes
  const isGraph = (g: any): g is graphlib.Graph<GraphNode> => g._nodes === undefined;

  const g = isGraph(graph) ? graph : graphlib.json.read(
    typeof graph === 'string' ? JSON.parse(graph) : graph,
  ) as graphlib.Graph<GraphNode>;

  const { inputs } = groupGraphNodes(g);
  const inputsHash = inputs.reduce<Record<string, true>>(
    (a, i) => ({ ...a, [i.id]: true }),
    {},
  );

  const inputNodes = (function gatherSuccessors(nId: string): string[] {
    const acc = inputsHash[nId] ? [nId] : [];

    const outEdges = g.outEdges(nId);
    if (!Array.isArray(outEdges)) return acc;

    const children = outEdges.map(it => it.w);
    return acc.concat(children.reduce<string[]>(
      (a, it) => a.concat(gatherSuccessors(it)),
      [],
    ));
  }(goalId));

  const uniqInputNodes = uniq(inputNodes);

  return uniqInputNodes;
};

// Graph category code ported from decisively audit (utils/GraphFunc.ts)

const getGoal = (goal: string | GraphNode, graph: graphlib.Graph<GraphNode> | ParsedRuleGraph) => {
  let goalNode;
  if (typeof goal === "string") {
    // Find the node
    goalNode = graph.node(goal); // TODO: handle description and public name
  } else goalNode = goal;
  return goalNode;
};

/**
 *
 *
 const exampleData = {
    "123": {
      "456": {}
    },
    "789": {},
    "111": {}
  }
 *
 * given data structure like example data, write a function that converts it to an array of objects with children
 */
const convertData = (data: Record<string, any>, transform: (key: string) => any): any[] => {
  const result: any[] = [];
  for (const key in data) {
    const obj = { id: key, ...transform(key), children: convertData(data[key], transform) };
    result.push(obj);
  }
  return result;
};

export const getCategories = (goal: string, graph: graphlib.Graph<GraphNode> | ParsedRuleGraph) => {
  // Traverse from the goal down and find all the categories.
  // Return a structure that matches this
  const goalNode = getGoal(goal, graph);
  const categories: Record<string, any> = {};
  let seen = {};
  // Returns the category structure in categories
  const traverse = (node: GraphNode | null, path?: string) => {
    if (!node) return;
    if (seen[node.path || node.id]) return;
    seen[node.path || node.id] = true;

    let newPath = path;
    if (node.category) {
      newPath = path ? `${path}.${node.path || node.id}` : (node.path || node.id);
      set(categories, newPath, {});
    }
    const children = graph.successors(node.path || node.id);
    if (!children || children.length === 0) return;
    children.forEach(c => {
      traverse(graph.node(c), newPath);
    });
  };

  traverse(goalNode);

  // convert to a more usable / parsable format, include the full node data on each one
  const result = convertData(categories, (key: string) => {
    // key is node id
    const node = graph.node(key);
    return node;
  });

  return result;
};

export type GraphNodeWithChildren = GraphNode & { children: GraphNodeWithChildren[] };

export const uncompressGraph = (compressed: any) => {
  try {
    const c = pako.inflate(compressed, { to: 'string' });
    return JSON.parse(c);
  } catch (error) {
    console.log('Failed to uncompress graph', error);
  }
};

/**
 * Combines the minimal decision graph with the full graph
 */
export const buildGraph = (ruleGraph: graphlib.Graph, decisionGraph: graphlib.Graph<GraphNode>) => {
  const graph = new graphlib.Graph();

  decisionGraph.nodes().forEach((id) => {
    let node: any;
    const dNodeValue = decisionGraph.node(id);
    if (!dNodeValue) return;
    if (id.includes("/")) {
      // Part of an entity chain
      const info = id.split("/");
      const refId = info.pop();
      if (!refId) return;
      const rNode = ruleGraph.node(refId);
      node = cloneDeep(rNode);
      if (!node) return;
      node.id = refId;
      node.hidden = false;
      node.index = info.pop();
      node.entity = info.pop();
      node.parent_path = info.length > 0 ? info.join("/") : undefined;
      node.path = id;
      // Check if the hidden exists - as it still needs to be added
      // so that anything that checks hidden (like the AttributeExplanation) still works
      if (!graph.node(refId) && rNode) {
        graph.setNode(refId, cloneDeep(rNode));
      }
    } else {
      node = ruleGraph.node(id);
    }

    if (node) {
      graph.setNode(id, {
        ...node,
        derived: dNodeValue.derived,
        input: dNodeValue.input,
        justification: dNodeValue.justification,
        triggered: dNodeValue.triggered
      });
    }
  });

  // Then do the edges
  decisionGraph.edges().forEach((edge) => {
    if (graph.node(edge.v) && graph.node(edge.w)) {
      graph.setEdge(edge.v, edge.w);
    }
  });

  return graph as graphlib.Graph<GraphNode>;
};

export const useGraphStatistics = (graph: any, debug: boolean = false) => {
  let counts: GraphCounts = {
    total: 0,
    input: 0,
    derived: 0,
    goals: 0,
    identifiers: 0,
  };
  let interestingNodes: any[] = [];

  if (!graph) return { counts, interestingNodes };

  let sinks = graph.sinks();
  let sources = graph.sources();

  graph.nodes().forEach(n => {
    let node = graph.node(n);
    if (node.fk) return;
    if (debug && node.hidden) return;
    if (sinks.indexOf(n) > -1) {
      // Some inputs are actually derived - if they have a value
      if (node.value || node.conditions || node.rows) counts.derived = counts.derived + 1;
      else counts.input = counts.input + 1;
    } else if (sources.indexOf(n) > -1) {
      counts.goals = counts.goals + 1;
    } else {
      counts.derived = counts.derived + 1;
    }
    counts.total = counts.total + 1; // always increase total
  });

  let eigens = eigenvectorCentrality(graph);

  // Sort them
  let data = Array.from(eigens).sort((a, b) => {
    if (a[1] > b[1]) return -1;
    else if (a[1] === b[1]) return 0;
    return 1;
  });

  // Loop through the vectors and return the top 10 inputs
  let count = 0;
  let i = 0;
  while (count < 10 && i < data.length) {
    let info = data[i];
    if (!info) {
      count = 11;
      break;
    }
    if (sinks.indexOf(info[0]) > -1) {
      // it's an input node
      let node = graph.node(info[0]);
      if (node) {
        interestingNodes.push(node);
        count++;
      }
    }
    i++;
  };

  return { counts, interestingNodes };
};