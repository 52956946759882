import { FormikSelect, FormikTextField, FormikCheckbox } from "@components/forms/FormikControls";
import { Stack } from "@components";
import { useFormikContext } from "formik";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { Typography } from "@mui/material";
import { get } from "lodash";

/**
 * Component designed to be injected into the AttributeCommunicationForm when the proxy method is selected.
 * - This component was largely copied from the AttributeIntegrationForm component but with certain features commented out and a Decisively module connection added in.
 * - TODO: Is it worth abstracting the common parts with the AttributeIntegrationForm?
 */
export const AttributeCommunicationProxyForm = ({ scope, readOnly }: { scope: string, readOnly?: boolean }) => {
  const { values } = useFormikContext<any>(); // Get current form values
  const release = useFullReleaseStrict();

  const dcsvlyModules = [{
    key: "EdWard",
    value: "edward"
  }]

  const connections = dcsvlyModules.concat(release.connections?.map(it => ({ key: it.name, value: it.id })) ?? []);
  // TODO: filtler by request or response
  // const requestMapping = release.mapping?.map(it => ({ key: it.name, value: it.id }));
  // const responseMapping = release.mapping?.map(it => ({ key: it.name, value: it.id }));

  const contentTypeOptions = [
    { value: "application/json", key: "JSON" },
    { value: "application/x-www-form-urlencoded", key: "Form URL Encoded" },
  ];
  // Find the selected connection's object
  const selectedConnValue = get(values, `${scope}.connection`);
  const selectedConnection = release.connections?.find(it => it.id === selectedConnValue);
  const isDcsvlyModule = dcsvlyModules.some(it => it.value === selectedConnValue);

  const methodOptions = [
    // { value: "get", key: "GET" },
    { value: "post", key: "POST" },
    { value: "put", key: "PUT" },
    { value: "patch", key: "PATCH" },
    // { value: "delete", key: "DELETE" },
  ];

  const scopedValue = (name: string) => `${scope}.${name}`;

  return (
    <Stack gridGap="1rem">
      {/* <FormikTextField fullWidth label="Title" required name={scopedValue("title")} /> */}
      <FormikSelect label="Connection" required name={scopedValue("connection")} options={connections || []} disabled={readOnly} />
      {
        isDcsvlyModule ? null : (
          <>
            {/* Show Base URL from selected connection */}
            {selectedConnection?.url && (
              <Typography variant="subtitle2" color="textSecondary">
                Base URL: <strong>{selectedConnection.url}</strong>
              </Typography>
            )}
            {/* Path Input Field */}
            <FormikTextField fullWidth required label="Path (relative to Base URL)" name={scopedValue("path")} disabled={readOnly} />
            {/* General API Settings */}
            <FormikSelect label="HTTP Method" required name={scopedValue("restMethod")} options={methodOptions} disabled={readOnly} />
            <FormikSelect label="Content Type" required name={scopedValue("contentType")} options={contentTypeOptions} disabled={readOnly} />
            {/* Query */}
            <FormikTextField fullWidth label="Query Parameters" name={scopedValue("query")} disabled={readOnly} />
          </>
        )
      }



      {/* Query & Mapping */}

      {/* <FormikSelect label="Request Mapping" name={scopedValue("requestMapping")} options={requestMapping || []} />
      <FormikSelect label="Response Mapping" name={scopedValue("responseMapping")} options={responseMapping || []} /> */}

      {/* Retry & Security Settings */}
      <FormikTextField fullWidth type="number" label="Retry Attempts" name={scopedValue("retryAttempts")} disabled={readOnly} />
    </Stack>
  );
};