import React from "react";
import { GraphViewNodeProps } from "@components/GraphView/GraphView";
import { useParsedGraph } from "@pages/models/release/GraphContext";
import GraphNode from "@components/RuleGraph_FullRelease/components/GraphNode";
import TriggerNode from "@components/RuleGraph_FullRelease/components/TriggerNode";

const DecisionFlowNode= (props: GraphViewNodeProps) => {
  const parsedGraph = useParsedGraph();
  const {data} = props;
  const graphNode = parsedGraph.node(data.node.id);

  if (!(graphNode?.id)) {
    // might happen if you're building in the doc view and there's an inline graph on the screen already
    return null;
  }

  if (data.node.type === "attribute") {
    return !parsedGraph ? null : (
      <GraphNode
        {...props}
        horizontal={true}
        graph={parsedGraph as any}
        data={{
          ...data,
          node: graphNode,
        }}
        isDecisionFlow={true}
      />
    );
  }

  return (
    <TriggerNode
      {...props}
    />
  );
}

export default DecisionFlowNode;