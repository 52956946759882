import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { useCurrentRelease } from '@common/hooks';
import { graphlib } from 'dagre';
import { groupGraphNodes } from '@common/graph/utils';


enum Group {
  goal = 'goal',
  derived = 'derived',
  input = 'input'
}

export interface Option {
  label: string;
  value: string;
  group: Group;
}

const nodeToOption = (n: any, g: Group) => ({
  value: n.id,
  label: n.description,
  group: g,
});

export type GoalAutocompleteProps = AutocompleteProps< Option, false, false, false >;

export interface Props {
  autocompleteProps?: Partial< GoalAutocompleteProps >
  textFieldProps?: Partial< TextFieldProps >;
  value: string | null;
  error: string | null;
  onChange: (v:Props[ 'value' ]) => unknown;
  type: 'input' | 'goalAndDerived' | 'goal';
  className?: string;
}

const StyledAutocomplete = (
  withStyles(theme => ({
    groupLabel: {
      color: theme.palette.secondary.main,
      fontSize: '0.875rem',
    },
  }))(Autocomplete) as React.FC< Props[ 'autocompleteProps' ] >
);


const getOptionLabel = (o: Option) => o.label;
const groupBy = (o: Option) => o.group;

/**
 * @deprecated
 */
export const _: React.FC<Props> = p => {
  const {
    autocompleteProps,
    textFieldProps,
    error,
    value,
    onChange,
    type,
    className,
  } = p;

  const renderInput: GoalAutocompleteProps[ 'renderInput' ] = React.useCallback(params => (
    <TextField
      {...params}
      fullWidth
      variant='outlined'
      error={error !== null}
      helperText={error || undefined}
      {...textFieldProps}
    />
  ), [error, textFieldProps]);

  const maybeCurrentRelease = useCurrentRelease();
  const options = React.useMemo< Option[] >(() => {
    if(!maybeCurrentRelease) return [];

    const {
      // eslint-disable-next-line camelcase
      rule_graph = {
        options: {
          compound: false,
          directed: true,
          multigraph: true,
        },
        nodes: [],
        edges: [],
      },
    } = maybeCurrentRelease;

    const g: graphlib.Graph< any > = (graphlib.json.read(rule_graph));
    const { goals, derived, inputs } = groupGraphNodes(g);

    if(type === 'input') {
      return inputs.map(n => nodeToOption(n, Group.input));
    }

    if(type === 'goal') {
      return goals.map(n => nodeToOption(n, Group.goal));
    }

    const allGoals = goals
      .map(n => nodeToOption(n, Group.goal))
      .concat(derived.map(n => nodeToOption(n, Group.derived)));

    return allGoals.sort((a, b) => (
      a.group === Group.goal && b.group === Group.derived
        ? -1
        : 1
    ));
  }, [maybeCurrentRelease, type]);

  const onGoalChange = React.useCallback< NonNullable< GoalAutocompleteProps[ 'onChange' ] > >(
    (_, v) => onChange(v && v.value),
    [onChange],
  );


  return (
    <StyledAutocomplete
      options={options}
      value={options.find(i => i.value === value) || null}
      onChange={onGoalChange}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      fullWidth
      className={className}
      {...autocompleteProps}
    />
  );
};

