import { css } from "styled-components";
import { ELEMENT_CONCLUSION } from "../elements";

const labelStyle = css`
  font-family: 'Montserrat';
  text-transform: capitalize;
  display: inline-block;
  font-size: 0.75rem;
  // want 1.5 + 3px padding, so easier to just set to 24px (1.5rem)
  line-height: 1.5rem;
  font-weight: bold;
  border-radius: 0.5rem;
  padding: 0 0.25rem;
  /* min-width: 1.125rem; */
  text-align: center;
`;

export const tableStyles = css`
  --bcolor: ${(p) => p.theme.palette.background.border};
  counter-reset: rowNumber;
  border: none;
  margin: 0;


  .table-header {
    padding: 0.5rem 1rem;
      /* color: ${(p) => p.theme.palette.secondary.contrastText}; */
    background-color: ${(p) => p.theme.palette.background.border};
    border-color: var(--bcolor);
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 0;
    /* max-height: 2.5rem; */
    display: flex;
    align-items: center;
    position: relative;

    > .slate-rule {
      &[data-expression="${ELEMENT_CONCLUSION}"] {
          /* color: ${(p) => p.theme.palette.secondary.contrastText}; */
        min-width: unset;
      }

      > .body {
        border: none;
        padding: 0;
        background-color: unset !important;
        /* min-height: 2.5rem; */
      }
    }

    /* span[data-slate-length="0"] {
      position: absolute;
      left: 1rem;
      right: 1rem;
      top: 0.5rem;
    } */

    &::before {
      opacity: 1;
      font-weight: bold;
      color: ${(p) => p.theme.palette.secondary.dark};
      position: relative;
      display: inline-block;
    }

    &::after {
      ${labelStyle};
      content: '=';
      margin-left: 0.5rem;
      color: ${(p) => p.theme.palette.background.contrastText};
      background-color: #b6c5fb; // darker blue lavendar (85%)
    }
  }

  .table-row {
    position: relative;

    .action {
      display: none;
    }

    &:hover {
      .action {
        display: block;
      }
    }

    &:not(:first-child) {
      border: 1px solid var(--bcolor);
    }

    &:last-of-type {
      > td {
        /* padding-right: 6.5rem; */

        div[class~="slate-rule"] {
          fieldset {
            max-width: calc(100% - 6.5rem);
          }

          &::after {
            ${labelStyle};
            content: 'Otherwise';
            position: relative;
            top: 0.25rem;
            background-color: ${(p) => p.theme.palette.secondary.light};
          }
        }

        //> div {
        //  display: flex;
        //  flex-direction: row;
        //  align-items: center;
        //  > * {
        //    margin-top: 0 !important;
        //  }
        //}
      }
    }

    &:not(:last-of-type) div[class~="slate-OPM-conclusion"] {
      fieldset {
        max-width: calc(100% - 6.5rem);
      }

      ::after {
        ${labelStyle};
        content: 'if';
        position: relative;
        left: 1rem;
        background-color: ${(p) => p.theme.system.lilacLatte};
      }
    }

    td {
      counter-increment: rowNumber;
      padding: 0 0 0 4rem;
      position: relative;

      &::before {
        content: counter(rowNumber) ".";
        position: absolute;
        top: 1rem;
        left: 1.5rem;
        color: ${(p) => p.theme.palette.secondary.dark};
      }

      > div {
        /* padding: 0.5rem 0; */
        z-index: 0;

        &:first-child {
          z-index: 5;
        }

        > *:not(:first-child) {
          margin-top: 1rem;
        }
      }

      /* fieldset {
        margin: 0;
      } */
    }
  }
`;
