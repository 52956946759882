import { Pane, SplitPane, StyledSplitVertical } from "@common/SplitPane";
import { SourceEditor, createSourcePlugins } from "@common/editor/RuleAuthor/SourceEditor";
import { FixedRichTextToolbar } from "@common/editor/RuleAuthor/toolbar/FixedToolbar";
import { FloatingToolbar } from "@common/editor/RuleAuthor/toolbar/FloatingToolbar";
import { RichTextToollbar } from "@common/editor/RuleAuthor/toolbar/components";
import { createSectionPlugin } from "@common/editor/components/section/createSectionPlugin";
import { scrollableMixin } from "@common/scrollbar";
import { DropdownMenu, Menu } from "@components";
import { PrimaryButton } from "@components/buttons";
import { config } from "@config";
import { type ChatMessage, ChatPanel } from "@decisively-io/interview-react-material";
import { LoadingDotsJSX } from "@icons";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import RulesRefreshIcon from "@material-ui/icons/Loop";
import { PlateProvider, RefreshIcon, type Value, createPlugins } from "@udecode/plate";
import { global } from "global";
import { once } from "lodash";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import type { SplitProps } from "react-split";
import styled from "styled-components";
import { useToggle } from "../../../../hooks/HooksGeneral";
import { SUGGEST_RULES_EDITOR_ID } from "./constants";

// this needs to be lazily initialized, otherwise it will cause a circular dependency
const createSuggestLeftPanePlugins = once(() =>
  createSourcePlugins([
    createSectionPlugin({
      options: { disableSuggestModals: true },
    }),
  ]),
);

//# region CollapsableArea

const StyledButtonBaseForCollapsableArea = styled(ButtonBase)`
  height: ${(props: any) => (props.horizontal ? "35px" : "100%")};
  align-items: ${(props: any) => (props.horizontal ? "center" : "flex-end")};

  if (props.horizontal) {
    display: flex;
    justify-content: center;
  }

  .MuiTypography-root {
    white-space: nowrap;
    transform: ${(props: any) => (props.horizontal ? "none" : "rotateZ( -90deg ) translateX( 50% )")};
  }
` as any;

type CollapsableAreaProps = React.PropsWithChildren<{
  collapsed: boolean;
  title: string;
  onCollapsedClick: () => unknown;
  className?: string;
  horizontal?: boolean;
}>;
const CollapsableArea = React.memo<CollapsableAreaProps>((p) => {
  const { collapsed, children, title, onCollapsedClick, className, horizontal } = p;

  const delayedOnClick = React.useCallback(() => {
    // allow ripple animation to play forward a bit
    setTimeout(onCollapsedClick, 175);
  }, [onCollapsedClick]);

  const collapsedContents = (
    <StyledButtonBaseForCollapsableArea
      horizontal={horizontal ?? false}
      onClick={delayedOnClick}
    >
      <Typography variant="h6">{title}</Typography>
    </StyledButtonBaseForCollapsableArea>
  );

  return <Pane className={className}>{collapsed ? collapsedContents : children}</Pane>;
});
CollapsableArea.displayName = "suggest/SuggestRulesModal/LeftPane/CollapsableArea";

//# endregion

const StyledFixedRichTextToolbar = styled(FixedRichTextToolbar)`
  overflow: visible hidden;

  &::-webkit-scrollbar {
    width: 0.125rem;
    height: 0.125rem;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
  }
`;

const Wrap = styled(Pane)`
  height: 100%;
`;

const PromptCollasableArea = styled(CollapsableArea)`
  &.hidden {
    display: none;
  }
`;
const PromptTextField = styled(TextField)`
  flex-grow: 1;

  .MuiInputBase-root {
    height: 100%;
  }

  .MuiOutlinedInput-input {
    ${scrollableMixin};
    height: 100% !important;
    overflow: auto !important;
  }
`;

export type SuggestRulesLeftPaneProps = {
  sourceLocal: Value;
  setSourceLocal: (v: Value) => unknown;
  prompt: string;
  setPrompt: (v: string) => unknown;
  promptType: string;
  setPromptType: (v: string) => unknown;
  model: string;
  /** The expanded conversation panel */
  showConversationOnPanel?: boolean;
  /** The "split-view" conversation panel below the source editor */
  showConversationWithSource?: boolean;
  setConversation?: (v: string[]) => unknown;
  chatResponses?: string[];
  /** this will also be called on-mount */
  onRegenerate?: () => unknown;
  onMount?: () => unknown;
  disabled?: boolean;
};

export const SuggestRulesLeftPane: React.FC<SuggestRulesLeftPaneProps> = React.memo((props) => {
  const {
    setSourceLocal,
    sourceLocal,
    prompt,
    setPrompt,
    showConversationOnPanel = true,
    showConversationWithSource = false,
    chatResponses = [],
    disabled = false,
    promptType,
    setPromptType,
    onMount,
    model,
  } = props;
  const { t } = useTranslation();
  const [mode, setMode] = React.useState<"source" | "prompt" | "conversation">("source");
  const setModePrompt = React.useCallback(() => setMode("prompt"), []);
  const setModeSource = React.useCallback(() => setMode("source"), []);
  const setModeConversation = React.useCallback(() => setMode("conversation"), []);
  const [conversationHistory, setConversationHistory] = React.useState<ChatMessage[]>([]);
  const conversationModeHorizPanelOpen = useToggle(true);
  const prevChatResponses = React.useRef<string[]>([]);

  const [isSaving, setIsSaving] = React.useState(false);

  const editableProps = React.useMemo(
    () => ({
      autoFocus: true,
      spellCheck: false,
    }),
    [],
  );

  React.useEffect(() => {
    if (chatResponses.length > 0) {
      const lenProcessedErrors = prevChatResponses.current.length;
      const newErrors = chatResponses.slice(lenProcessedErrors);
      const newChatMessages = newErrors.map((newError) => ({
        self: false,
        content: newError,
      }));
      setConversationHistory((prev) => [...prev, ...newChatMessages]);
      prevChatResponses.current = chatResponses;
    }
  }, [chatResponses]);

  React.useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, []);

  // TODO use a proper ENV var when we add one
  // if BACKEND_URL includes dev or staging
  const showPrompt = config.BACKEND_URL.includes("dev") || config.BACKEND_URL.includes("staging");
  const hidePrompt = !showPrompt;

  const paneProps = React.useMemo<SplitProps>(() => {
    const collapsedSize = "40px";
    const dualCollapsedSize = "80px";
    let numberOfPanels = 3;
    if (hidePrompt) {
      numberOfPanels--;
    }
    if (!showConversationOnPanel) {
      numberOfPanels--;
    }

    // const expanded = `calc(100% - ${collapsed})`;

    const sizes = (() => {
      if (numberOfPanels === 1) {
        return [0, 100];
      }

      if (numberOfPanels === 2) {
        if (mode === "prompt") {
          return [`calc(100% - ${collapsedSize})`, collapsedSize];
        }
        if (mode === "source") {
          return [collapsedSize, `calc(100% - ${collapsedSize})`];
        }

        return [collapsedSize, `calc(100% - ${collapsedSize})`];
      }

      // dealing with 3 panels, and the ordering is conversation, prompt, source
      const sizesTriplet = (() => {
        if (mode === "conversation") {
          return [`calc(100% - ${dualCollapsedSize})`, collapsedSize, collapsedSize];
        }
        if (mode === "prompt") {
          return [collapsedSize, `calc(100% - ${dualCollapsedSize})`, collapsedSize];
        }
        if (mode === "source") {
          return [collapsedSize, collapsedSize, `calc(100% - ${dualCollapsedSize})`];
        }
        return [collapsedSize, collapsedSize, `calc(100% - ${dualCollapsedSize})`];
      })();

      return sizesTriplet;
    })();
    // typescript complains, but inline styles are propagated
    // correctly, so this is a forced typecast (I would much
    // rather not do it)
    const sizesTyped = sizes as unknown as number[];

    return {
      sizes: sizesTyped,
      gutterStyle: () => (hidePrompt ? { display: "none" } : { display: "block", width: "8px" }),
      snapOffset: 0,
      style: { height: "100%" },
    };
  }, [mode, hidePrompt]);

  const onSave = React.useCallback(() => {
    setIsSaving(true);
    let url = "/auth/updatePrompt";
    if (promptType === "model") url += `?model=${model}`;
    console.log("SAVING", promptType, url);
    global
      .client(url, {
        method: "PATCH",
        /**
         * it seems that client does stringify internally, but\
         * expects BodyInit type, which is already stringified\
         * so then we stringify twice, and that's not good. Ugly,\
         * but necessary cast
         */
        body: { content: prompt } as unknown as BodyInit,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        void res;
        /**
         * success response returns with { message: 'ok' }, so \
         * not really sure what to do with this
         */
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [prompt, setIsSaving]);

  const renderConversationCollapsible = () => {
    if (!showConversationOnPanel) {
      return null;
    }

    return (
      <CollapsableArea
        collapsed={mode !== "conversation"}
        title={t("ai.conversation-expanded")}
        onCollapsedClick={setModeConversation}
      >
        <Box
          display="flex"
          flexDirection="column"
          padding="0.5rem 1rem"
          gridGap="1rem"
          flex={1}
          width={"100%"}
          maxHeight={"100%"}
        >
          {renderChatPanel()}
        </Box>
      </CollapsableArea>
    );
  };

  const renderSourceEditor = () => {
    return (
      <PlateProvider
        id={SUGGEST_RULES_EDITOR_ID}
        plugins={createSuggestLeftPanePlugins()}
        value={sourceLocal}
        onChange={setSourceLocal}
      >
        <StyledFixedRichTextToolbar />
        <SourceEditor
          id={SUGGEST_RULES_EDITOR_ID}
          editableProps={editableProps}
        >
          <FloatingToolbar>
            <RichTextToollbar />
          </FloatingToolbar>
        </SourceEditor>
      </PlateProvider>
    );
  };

  const renderChatPanel = () => {
    return (
      <ChatPanel
        disabled={disabled}
        messages={conversationHistory}
        compact={true}
        setMessages={(newVal) => {
          setConversationHistory(newVal);
          if (props.setConversation) {
            props.setConversation(newVal.filter((it) => it.self).map((m) => m.content));
          }
          // let's also regenerate the rules on every message change
          if (props.onRegenerate) {
            props.onRegenerate();
          }
        }}
        additionalAction={{
          label: "",
          icon: <RulesRefreshIcon />,
          disabled,
          onClick: () => {
            // don't clear the conversation...
            // setConversationHistory([]);
            // if (props.setConversation) {
            //   props.setConversation([]);
            // }
            if (props.onRegenerate) {
              props.onRegenerate();
            }
          },
        }}
        responding={{
          isResponding: disabled && chatResponses.length > 1,
          content: "•••",
        }}
      />
    );
  };

  const renderSourceCollapsible = () => {
    if (showConversationWithSource) {
      return (
        <CollapsableArea
          collapsed={mode !== "source"}
          title={t("ai.source")}
          onCollapsedClick={setModeSource}
        >
          <StyledSplitVertical
            sizes={conversationModeHorizPanelOpen.value ? [50, 50] : [500, 0]}
            gutterStyle={() => ({ display: "block", width: "8px" })}
            snapOffset={0}
            style={{
              height: "100%",
              width: "100%",
            }}
            direction="vertical"
          >
            <CollapsableArea
              collapsed={false}
              title={t("ai.source")}
              onCollapsedClick={() => {}}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  borderBottom: "0.5px solid lightgray",
                }}
              >
                {renderSourceEditor()}
              </div>
            </CollapsableArea>
            <CollapsableArea
              collapsed={!conversationModeHorizPanelOpen.value}
              title={t("ai.conversation-splitview")}
              onCollapsedClick={conversationModeHorizPanelOpen.forceTrue}
              horizontal={true}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  position: "relative",
                  marginLeft: "1px",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    position: "absolute",
                    top: 0,
                    right: "1.2rem",
                  }}
                >
                  <ButtonBase
                    onClick={conversationModeHorizPanelOpen.forceFalse}
                    style={{
                      paddingTop: "0.5rem",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    <CancelIcon />
                  </ButtonBase>
                </div>
                {renderChatPanel()}
              </div>
            </CollapsableArea>
          </StyledSplitVertical>
        </CollapsableArea>
      );
    }

    return (
      <CollapsableArea
        collapsed={mode !== "source"}
        title={t("ai.source")}
        onCollapsedClick={setModeSource}
      >
        {renderSourceEditor()}
      </CollapsableArea>
    );
  };

  return (
    <Wrap>
      <SplitPane {...paneProps}>
        {renderConversationCollapsible()}

        <PromptCollasableArea
          collapsed={mode !== "prompt"}
          title={t("ai.prompt")}
          onCollapsedClick={setModePrompt}
          className={hidePrompt ? "hidden" : undefined}
        >
          <Box
            display="flex"
            flexDirection="column"
            padding="0.5rem 1rem"
            gridGap="1rem"
            flex={1}
          >
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="h5">
                <Trans i18nKey="ai.prompt" />
              </Typography>
              <DropdownMenu value={promptType === "model" ? "Project" : "System"}>
                {({ close }) => {
                  const onClick = (item) => {
                    close();
                    setPromptType(item.value);
                  };

                  return (
                    <Menu
                      onOptionClick={onClick}
                      items={[
                        {
                          name: "System",
                          value: "system",
                        },
                        {
                          name: "Project",
                          value: "model",
                        },
                      ]}
                    />
                  );
                }}
              </DropdownMenu>
            </Box>
            <PromptTextField
              multiline
              variant="outlined"
              value={prompt}
              onChange={(({ currentTarget: { value } }) => setPrompt(value))}
            />

            <Box
              display="flex"
              justifyContent="space-between"
            >
              <PrimaryButton
                onClick={onSave}
                disabled={isSaving}
              >
                {isSaving ? LoadingDotsJSX : t("save")}
              </PrimaryButton>
            </Box>
          </Box>
        </PromptCollasableArea>

        {renderSourceCollapsible()}
      </SplitPane>
    </Wrap>
  );
});
SuggestRulesLeftPane.displayName = "suggest/SuggestRulesModal/LeftPane";
