import React from "react";
import styled, { css } from "styled-components"
;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;

  > fieldset {
    display: flex;
    border: 1px solid ${ props => props.theme.palette.background.border };
    border-radius: 1.25rem;
    padding: 0;
    margin: 0.5rem 0;
    position: relative;

    > span {
      padding: 0.5rem 1rem;
      margin: 0;
      ${ p => p.css };

      &::before {
        opacity: 1;
        font-weight: bold;
        color: ${ p => p.theme.palette.secondary.dark };
        position: relative;
        display: inline-block;
      }

      > :not([data-slate-node="element"]) {
        span[data-slate-length="0"] {
          position: absolute;
          left: 1rem;
          right: 1rem;
        }
      }
    }
  }
`;

/**
 * @typedef {{
 *  className?: string;
 *  jsonId: string;
 *  attributes: any;
 *  element: any;
 *  nodeProps: any;
 *  children: any;
 * }} IProps
 */

/** @type { React.FC< IProps > } */
export const Bubble = React.memo(({ className, attributes, children, nodeProps = {} }) => {
  const { styles, placeholder, ...rest } = nodeProps;
  return (
    <Container className={className} css={styles?.root?.css} {...attributes} {...rest}>
      <fieldset>
        <span placeholder={placeholder}>
          {children}
        </span>
      </fieldset>
    </Container>
  );
});
Bubble.displayName = "DocumentViewer/Rule/Bubble";

export const ConclusionBubble = styled(Bubble)`
  font-weight: bold;
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const indentLine = (level = 1) => css`
  &::before {
    position: absolute;
    left: -${20 * level}px;
    width: ${20 * level}px;
    height: 100%;
    content: " ";
    background: repeating-linear-gradient(
      to right,
      #e5e5e5,
      #e5e5e5 1px,
      #fff 1px,
      #fff 21px
    );
  }
`;

export const Level1Bubble = styled(Bubble)`
  margin-left: 40px;
  ${indentLine(1)};
`;
export const Level2Bubble = styled(Bubble)`
  margin-left: 60px;
  ${indentLine(2)};
`;
export const Level3Bubble = styled(Bubble)`
  margin-left: 80px;
  ${indentLine(3)};
`;
export const Level4Bubble = styled(Bubble)`
  margin-left: 100px;
  ${indentLine(4)};

`;
export const Level5Bubble = styled(Bubble)`
  margin-left: 120px;
  ${indentLine(5)};
`;
export const Level6Bubble = styled(Bubble)`
  margin-left: 140px;
  ${indentLine(6)};
`;
