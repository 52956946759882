import { useEffect } from 'react';
import { useReactFlow } from 'reactflow';

export const AutoFitGraph = () => {
  const { fitView } = useReactFlow();
  useEffect(() => {
    fitView();
  }, [fitView]);

  return null;
};
