import { PropsWithChildren } from "react";
import { PROJECT_ROLES, Roles, useHasRole, useIsAdmin, useIsOwner, useIsProjectTechnicalWriter, useIsProjectWriter, useIsSuperAdmin } from "./permissions";
import { Protected } from "./protected";

export const SuperAdminGuard: React.FC<PropsWithChildren> = (props) => <Protected useGuard={useIsSuperAdmin} {...props} />;
export const AdminGuard: React.FC<PropsWithChildren> = (props) => <Protected useGuard={useIsAdmin} {...props} />;
export const OwnerGuard: React.FC<PropsWithChildren> = (props) => <Protected useGuard={useIsOwner} {...props} />;
export const ReleaseManagerGuard: React.FC<PropsWithChildren> = (props) => <Protected useGuard={() => useHasRole(Roles.ReleaseManager)} {...props} />;
export const ProjectWriterGuard: React.FC<PropsWithChildren> = (props) => <Protected useGuard={useIsProjectWriter} {...props} />;
export const ProjectTechnicalWriterGuard: React.FC<PropsWithChildren> = (props) => <Protected useGuard={useIsProjectTechnicalWriter} {...props} />;
export const ProjectGuard: React.FC<PropsWithChildren> = (props) => <Protected useGuard={() => useHasRole(PROJECT_ROLES)} {...props} />;
